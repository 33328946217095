import * as types from './actionTypes';
import axios from 'axios';
import { API_URL } from '../config.js';

export function receivePlayers(players) {
	return { type: types.RECEIVE_PLAYERS, players: players };
}

export function fetchPlayers(week) {
	return dispatch => {
    	return axios.get(API_URL + '/playerWeeklyData/' + week).then(response => dispatch(receivePlayers(response.data.data && response.data.data.playersList && response.data.data.playersList[0] && response.data.data.playersList[0].playersData ? response.data.data.playersList[0].playersData : [])));
  	};
}
