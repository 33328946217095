export default {
	subscriptionTypes: [],
	registerForm: {
		subscriptionType: ''
	},
	globalVars: {
		currentWeek: null
	},
	indexes: [],
	players: [],
	teams: [],
	weeks: []
};
