import React, { Component } from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import { isAdmin } from '../helpers/session';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

export default class DepthChartPosition extends Component {
	constructor() {
		super();

		this.state = { 
			expanded: false,
			position: '',
			health: '1',
			explanation: '',
			selectedPosition: '',
			selectedHealth: '1',
			selectedExplanation: ''
		};
	}

	componentDidMount() {
		const { position } = this.props;
		
		if (position) {
			this.setState({
				position: position.position,
				health: position.healthStatus.toString(),
				explanation: position.text ? position.text : '',
				selectedPosition: position.position,
				selectedHealth: position.healthStatus.toString(),
				selectedExplanation: position.text ? position.text : ''
			});
		}
	}

	onOpen = () => {
		this.setState({ expanded: true });
	}

	onClickTooltip = (e) => {
		e.stopPropagation();
	}

	onClose = () => {
		this.setState({ expanded: false });
	}

	onSave = () => {
		const { row, column, team, side } = this.props;
		const { selectedHealth, selectedPosition, selectedExplanation } = this.state;

		this.setState({ 
			expanded: false,
			health: selectedHealth,
			position: selectedPosition,
			explanation: selectedExplanation
		 });
		this.props.onSave(team, side, row, column, selectedPosition, selectedHealth, selectedExplanation);
	}

	render() {
		const { side } = this.props;
		const { expanded, position, health, explanation, selectedPosition, selectedHealth, selectedExplanation } = this.state;
		const positions = ['WR', 'OL', 'C', 'QB', 'RB', 'CB', 'OLB/DE', 'DT', 'DE', 'LB', 'SS', 'FS', 'TE', 'FB'];
		const healthClass = position !== '' ? ('color-' + health) : '';

		return (
			<div className={classNames([ 'depth-chart-position', healthClass, side, expanded ? 'active' : 'inactive', isAdmin() ? '' : 'no-border' ])} onClick={ this.onOpen }>
				<div className="position-name">{ position }</div>
				{
					isAdmin() && expanded
					? <div className={classNames([ 'position-tooltip', expanded ? 'is-active' : 'is-inactive', 'admin-mode' ])} onClick={ this.onClickTooltip }>
						<Box mb={3}>
		              		<NativeSelect value={ selectedPosition } name="position" fullWidth onChange={ e => this.setState({ selectedPosition: e.target.value }) }>
		              			<option value="">Position</option>
		                		{
		                  			positions.map(position => <option key={position} value={position}>{position}</option>)
                				}
		            		</NativeSelect>
		            	</Box>
		            	<Box mb={3}>
        					<InputLabel>Health</InputLabel>
        					<Select value={ selectedHealth } fullWidth name="health" onChange={ e => this.setState({ selectedHealth: e.target.value }) }>
				          		<MenuItem value="1">
				          			<div className="health_box green"></div>
				          		</MenuItem>
				          		<MenuItem value="2">
				          			<div className="health_box green-orange"></div>
				          		</MenuItem>
				          		<MenuItem value="3">
				          			<div className="health_box orange"></div>
				          		</MenuItem>
				          		<MenuItem value="4">
				          			<div className="health_box orange-red"></div>
				          		</MenuItem>
				          		<MenuItem value="5">
				          			<div className="health_box red"></div>
				          		</MenuItem>
				          		<MenuItem value="6">
				          			<div className="health_box red-green"></div>
				          		</MenuItem>
    				  		</Select>
		            	</Box>
		            	<Box mb={0.5}>
		            		<InputLabel>Explanation</InputLabel>
		              		<textarea value={ selectedExplanation } name="explanation" onChange={ e => this.setState({ selectedExplanation: e.target.value }) }></textarea>
		            	</Box>
		            	<Button size="small" color="primary" type="button" onClick={ this.onClose }>Close</Button>
						<Button size="small" color="primary" type="button" onClick={ this.onSave }>Save</Button>
					</div>
					: null
				}
				{
					explanation.trim() !== ''
					? <div className={classNames([ 'position-tooltip', 'user-mode' ])} onClick={ this.onClickTooltip }>
		            	<div className="explanation-readonly" dangerouslySetInnerHTML={{ __html: explanation.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div>
					</div>
					: null
				}
			</div>
		);
  	}
}

DepthChartPosition.defaultProps = {
  side: 'defense',
  team: 'home',
  column: 1,
  row: 1,
};
