import React, { Component } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NativeSelect from '@material-ui/core/NativeSelect';
import GameBox from '../components/GameBox';
import LoadingWheel from '../components/LoadingWheel';
import ErrorSnackbar from '../components/ErrorSnackbar';
import SuccessSnackbar from '../components/SuccessSnackbar';
import UpdatesListing from '../components/UpdatesListing';
import Icon from '@material-ui/core/Icon';
import { getToken, isAdmin, getSettings, getSeason } from '../helpers/session';
import { API_URL } from '../config.js';

export default class GameDayScreen extends Component {
  constructor() {
    super();

    this.state = {
      updates: [],
      selectedGames: [],
      showAddMore: false,
      loading: true,
      newIndexError: '',
      currentGame: '',
      currentWeek: '',
      successMessage: ''
    };
  }

  async componentDidMount() {
    const authToken = getToken();
    const { globalVars } = this.props;

    if (!authToken) {
      this.props.history.push('/login');
    } else {
      if (!getSettings('topNoticeBarClosed', false)) {
        document.querySelector('body').className = document.querySelector('body').className + ' show-top-notice ';
      }

      if (globalVars.currentWeek) {
        await this.props.indexesActions.fetchIndexes(globalVars.currentWeek._id);
        this.setState({ currentWeek: globalVars.currentWeek._id });
      } else if (this.state.currentWeek) {
        await this.props.indexesActions.fetchIndexes(this.state.currentWeek);
      }
      
      axios.get(API_URL + '/user/me').then(response => {
        this.setState({
          loading: false,
          selectedGames: response.data.data.favGames
        })
      });

      window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Page: Injury Index - /game');
      window.fbq('track', 'PageView', { title: 'Injury Index' });
    }
  }

  componentWillUnmount() {
    document.querySelector('body').className = document.querySelector('body').className.replace('show-top-notice');
  }

  onLoadIndexes = (week) => {
    this.setState({ loading: true });
    this.props.indexesActions.fetchIndexes(week).then(() => {
      this.setState({ loading: false });
    });
  }

  onFavorite = (id, track) => {
    this.setState({ loading: true });
    axios.post(API_URL + '/injuryIndex/favorite', { gameScheduleId: id }).then(response => this.setState({ loading: false, selectedGames: response.data.data.favGames }));
    if (track) {
      window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Save Games');
    }
  }

  onDelete = (id) => {
    const { currentWeek } = this.state;

    if (window.confirm('Are you sure?')) {
      axios.delete(API_URL + '/injuryIndex/delete/' + id).then(() => this.onLoadIndexes(currentWeek));
    }
  }

  onMarkAsFeatured = (game, status) => {
    const { currentWeek } = this.state;
    const { onLoadFeaturedGames } = this.props;

    this.setState({ loading: true });

    axios.post(API_URL + '/injuryIndex/featuredGame', {
        gameId: game._id,
        scheduleTimelineId: currentWeek,
      isFeatured: !status
    }).then(response => {
      this.setState({ successMessage: response.data.message });

      if (onLoadFeaturedGames) {
        onLoadFeaturedGames(() => this.setState({ loading: false }));
      }

      setTimeout(() => {
        this.setState({ successMessage: '' });
      }, 2000);
    });
  }

  handleCreateGame = (data) => {
    const { currentWeek } = this.state;

    if (data.teamsData[0].teamId === '' || data.teamsData[1].teamId === '') {
      alert('Please select the teams');
    } else {
      this.setState({ loading: true });

      axios.post(API_URL + '/injuryIndex/create', Object.assign(data, { scheduleTimelineId: currentWeek })).then(response => { 
        if (response.data.status === 'Error') {
          this.setState({
            newIndexError: response.data.message,
            loading: false
          });

          setTimeout(() => this.setState({ newIndexError: '' }), 3000);
        } else {
          this.setState({ showAddMore: false, loading: false });
          this.props.indexesActions.fetchIndexes(currentWeek);
        }
      }).catch(error => {
        this.setState({
          newIndexError: typeof error.response.data.error !== 'undefined' ? error.response.data.error.message : error.response.data.message,
          loading: false
        });

        setTimeout(() => this.setState({ newIndexError: '' }), 3000);
      });
    }
  }

  handleUpdateGame = (id, data) => {
    const { currentWeek } = this.state;

    this.setState({ loading: true });

    axios.put(API_URL + '/injuryIndex/update/' + id, data).then(response => { 
      this.setState({ loading: false });
      this.props.indexesActions.fetchIndexes(currentWeek);
    }).catch(error => {
      this.setState({
        newIndexError: typeof error.response.data.error !== 'undefined' ? error.response.data.error.message : error.response.data.message,
        loading: false
      });

      setTimeout(() => this.setState({ newIndexError: '' }), 3000);
    });
  }

  onImport = gameId => {
    if (window.confirm('Are you sure?')) {
      const { currentWeek } = this.state;

      this.setState({ loading: true });

      axios.get(API_URL + '/injuryIndex/import/' + currentWeek + '?gameId=' + gameId).then(async (response) => {
        this.props.indexesActions.receiveIndexes([]);
        this.forceUpdate();
        await this.props.indexesActions.fetchIndexes(currentWeek);
        this.setState({ loading: false });
      });
    }
  }

  onShare = async (gameId, isEmbedded) => {
    const { currentWeek } = this.state;

    this.setState({ loading: true });

    axios.put(API_URL + '/injuryIndex/updateEmbedded/' + gameId, { isEmbedded }).then(async (response) => {
      this.props.indexesActions.receiveIndexes([]);
      this.forceUpdate();
      await this.props.indexesActions.fetchIndexes(currentWeek);
      this.setState({ loading: false });
    });
  }

  onSwitchSide = (gameId, side) => {
    const { currentWeek } = this.state;
    
    this.setState({ loading: true });

    axios.put(API_URL + '/injuryIndex/sideUpdate/' + gameId + '/' + side).then(async (response) => {
      this.props.indexesActions.receiveIndexes([]);
      this.forceUpdate();
      await this.props.indexesActions.fetchIndexes(currentWeek);
      this.setState({ loading: false });
    });
  }

  onAddMore = () => {
    const { showAddMore } = this.state;

    if (!showAddMore) {
      setTimeout(() => {
        const element = document.querySelector('.main_games_holder_scroller');
        element.scrollTop = element.scrollHeight;
      }, 200);
    }

    this.setState({ showAddMore: !showAddMore, currentGame: '', updates: [] });
  }

  onChangeWeek = (e) => {
    const week = e.target.value; 

    this.setState({ currentWeek: week, currentGame: '', updates: [], showAddMore: false }); 
    this.onLoadIndexes(week);
  }

  onLoadUpdates = () => {
    const { currentGame } = this.state;

    if (currentGame)
      axios.get(API_URL + '/post?gameId=' + currentGame + '&season=' + getSeason()).then(response => this.setState({ loading: false, updates : response.data.data ? response.data.data.postList : [] }));
  }

  onToggle = (game) => {
    const { currentGame } = this.state;

    if (currentGame !== game._id) {
      window.mixpanel.track('Show Updates');
      window.scrollTo(0, 0);
      this.setState({ loading: true, currentGame: game._id, updates: [], showAddMore: false });
      setTimeout(() => this.onLoadUpdates());
    } else {
      this.setState({ updates: [], currentGame: '', showAddMore: false });
    }
  }

  render() {
    const { indexes, weeks, featuredGames } = this.props;
    const { selectedGames, showAddMore, loading, newIndexError, currentWeek, currentGame, updates, successMessage } = this.state;
    const games = typeof indexes[0] !== 'undefined' && typeof indexes[0].games !== 'undefined' ? indexes[0].games : [];

    if (weeks.length === 0 || currentWeek === '') {
      return <LoadingWheel />;
    }

    return (
        <Box className="main main-players main-injury-index">
          <Grid container>
            <Grid item md={9} xs={12} className={ currentGame === '' ? '' : 'mobile-hidden' }>
              <div className="main_games_holder">
                <Box className="game-box game-box-header" mb={2}>
                  <Box className="game-box-row">
                    <Typography component="div" className="game-box-col game-box-col-name game-box-col-select">
                      <NativeSelect value={currentWeek} onChange={ this.onChangeWeek }>
                          {
                            weeks.map((week, i) => {
                              return (<option key={week._id} value={week._id}>{ week.name }</option>)
                            })
                          }
                      </NativeSelect>
                    </Typography>
                    <Typography component="div" className="game-box-col mobile-hidden">Points</Typography>
                    <Typography component="div" className="game-box-col mobile-hidden">Money</Typography>
                    <Typography component="div" className="game-box-col mobile-hidden">O/U</Typography>
                    <Typography component="div" className="game-box-col game-box-col-switcher">Team</Typography>
                    <Typography component="div" className="game-box-col game-box-col-switcher">Offense</Typography>
                    <Typography component="div" className="game-box-col game-box-col-switcher">Defense</Typography>
                    {
                      isAdmin() 
                      ? (<span className="add-more" onClick={ this.onAddMore }>
                        <Icon>add</Icon>
                      </span>)
                      : null
                    }
                  </Box>
                </Box>
                <div className="main_games_holder_scroller">
                  {
                    games.map((game, i) => {
                      if (selectedGames.indexOf(game._id) === -1) {
                        return null;
                      }

                      return (<Grid item xs={12} key={game._id}>
                        <GameBox 
                          game={game} 
                          action="check" 
                          expanded={game._id === currentGame}
                          onAction={ () => this.onFavorite(game._id, false) } 
                          onDelete={ () => this.onDelete(game._id) } 
                          onToggle={ this.onToggle }
                          onImport={ this.onImport }
                          onShare={ this.onShare }
                          onSwitchSide={ this.onSwitchSide }
                          onMarkAsFeatured={ this.onMarkAsFeatured }
                          handleUpdateGame={ data => this.handleUpdateGame(game._id, data) }
                          featuredGames={featuredGames}
                          currentWeek={currentWeek}
                        />
                      </Grid>);
                    })
                  }
                  <div className="games-separator"></div>
                  {
                    games.map((game, i) => {
                      if (selectedGames.indexOf(game._id) !== -1) {
                        return null;
                      }

                      return (<Grid item xs={12} key={game._id}>
                        <GameBox 
                          game={game} 
                          action="add" 
                          expanded={game._id === currentGame}
                          onAction={ () => this.onFavorite(game._id, true) } 
                          onDelete={ () => this.onDelete(game._id) } 
                          onToggle={ this.onToggle }
                          onImport={ this.onImport }
                          onShare={ this.onShare }
                          onSwitchSide={ this.onSwitchSide }
                          onMarkAsFeatured={ this.onMarkAsFeatured }
                          handleUpdateGame={ (data) => this.handleUpdateGame(game._id, data) }
                          featuredGames={featuredGames}
                          currentWeek={currentWeek}
                        />
                      </Grid>);
                    })
                  }
                  {
                    showAddMore
                    ? (<Grid item xs={12}>
                      <GameBox 
                        game={null} 
                        action="add" 
                        expanded={true}
                        formMode={true} 
                        onAction={data => { this.handleCreateGame(data) }} 
                        onMarkAsFeatured={ this.onMarkAsFeatured }
                        onDelete={ () => this.setState({ showAddMore: false }) } 
                        featuredGames={featuredGames}
                        currentWeek={currentWeek}
                      />
                    </Grid>)
                    : null
                  }
                  {
                    indexes.length < 1
                    ? <Typography component="div" variant="body2">Injury Indexes will be updated on at least 24 hours before game time.</Typography>
                    : null
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={3} className={ classNames({ side_posts_holder: true, is_active: currentGame }) }>
              <div className="side-posts no-border">
                <UpdatesListing 
                  updates={updates} 
                  onLoad={this.onLoadUpdates} 
                  titleBarText="Game Updates"
                  displayCloseButton={true} 
                  onClose={ () => this.setState({ currentGame: '' }) }
                />
              </div>
            </Grid>
          </Grid>
          { loading ? <LoadingWheel /> : null }
          { newIndexError ? <ErrorSnackbar message={newIndexError} /> : null }
          { successMessage ? <SuccessSnackbar message={successMessage} /> : null }
        </Box>
      );
  }
}
