import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import LoadingWheel from '../components/LoadingWheel';
import ErrorSnackbar from '../components/ErrorSnackbar';
import { isAdmin } from '../helpers/session';
import { API_URL, DATE_TIME_FORMAT } from '../config.js';

class PlayersScreen extends Component {
	constructor() {
    	super();

	    this.state = {
	    	users: [],
	    	usersCount: 0,
	    	regularUsers: 0,
	    	twitterUsers: 0,
	    	totalUsers: 0,
	    	totalTwitterUsers: 0,
				totalRegularUsers: 0,
	    	roles: [],
	    	search: '',
	    	currentPage: 1,
	    	itemsPerPage: 50,
	    	totalPages: 0,
	    	loading: true,
	    	generalError: '',
	    	sort: 'date-desc',
	    	globalAction: '',
	    	globalActionSelection: [],
	    	dateFrom: '',
	    	dateTo: ''
	    };
  	}

  	componentDidMount() {
		if (!isAdmin()) {
			this.props.history.push('/');
		} else {
			axios.get(API_URL + '/role').then(response => {
				this.setState({ roles: response.data.data.roles });
				this.onLoad();
			});
			window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Page: Users - /users');
			window.fbq('track', 'PageView', { title: 'Users' });
		}
  	}

  	onLoad = () => {
  		const { itemsPerPage, search, currentPage, sort, dateFrom, dateTo } = this.state;
  		let queryString = '?perPage=' + itemsPerPage + '&sort=createdAt&pageNumber=' + currentPage + '&sortDirection=' + (sort === 'date-desc' ? -1 : 1);

  		if (search !== '') {
  			queryString += '&searchByEmail=' + search;
  		}

  		if (dateFrom) {
  			queryString += '&fromDate=' + moment(dateFrom).format('YYYY-MM-DD');
  		}

  		if (dateTo) {
  			queryString += '&toDate=' + moment(dateTo).format('YYYY-MM-DD');
  		}

  		queryString += '&timeZone=' + moment.tz.guess();

		axios.get(API_URL + '/user' + queryString).then(response => {
			this.setState({
				loading: false,
				users: response.data.data.users,
				usersCount: response.data.data.usersCount,
		    	regularUsers: response.data.data.regularUsers,
		    	twitterUsers: response.data.data.twitterUsers,
		    	totalUsers: response.data.data.totalUsers,
		    	totalTwitterUsers: response.data.data.totalTwitterUsers,
				totalRegularUsers: response.data.data.totalRegularUsers,
				globalActionSelection: [],
				totalPages: Math.ceil(response.data.data.usersCount / itemsPerPage)
			})
		});
  	}

  	onUpdateSearch(key, value) {
  		if (key === 'search') {
  			this.setState({ search: value, globalActionSelection: [], loading: true });
  		} else if (key === 'sort') {
  			this.setState({ sort: value, globalActionSelection: [], loading: true, currentPage: 1 });
  		} else if (key === 'page') {
  			this.setState({ currentPage: value, globalActionSelection: [], loading: true });
  		} else if (key === 'dateFrom') {
  			this.setState({ dateFrom: value, globalActionSelection: [], loading: true, currentPage: 1 });
  		} else if (key === 'dateTo') {
  			this.setState({ dateTo: value, globalActionSelection: [], loading: true, currentPage: 1 });
  		}

  		setTimeout(() => this.onLoad());
  	}

  	onPartnerChange = (user, value) => {
  		const { loading } = this.state;

  		if (!loading) {
  			this.setState({ loading: true });
  			axios.put(API_URL + '/user/updatePartner', {
  				userId: user._id,
  				partner: value
  			}).then(response => {
  				const data = response.data;
  				if (data.status === 'error') {
  					this.setState({ 
  						generalError: data.message,
  						loading: false 
					});
					setTimeout(() => this.setState({ generalError: '' }), 3000);
  				} else {
  					this.onLoad();
  				}
  			}).catch(error => {
  				this.setState({
		          generalError: error.response.data.message,
		          loading: false
		        });

		        setTimeout(() => this.setState({ generalError: '' }), 3000);
  			});
  		}
  	}

  	onAccountTypeChange = (user, value) => {
  		const { loading } = this.state;

  		if (!loading) {
  			this.setState({ loading: true });
  			axios.post(API_URL + '/user/updateAccountType', {
  				userId: user._id,
  				accountType: value
  			}).then(response => {
  				const data = response.data;
  				if (data.status === 'error') {
  					this.setState({ 
  						generalError: data.message,
  						loading: false 
					});
					setTimeout(() => this.setState({ generalError: '' }), 3000);
  				} else {
  					this.onLoad();
  				}
  			}).catch(error => {
  				this.setState({
		          generalError: error.response.data.message,
		          loading: false
		        });

		        setTimeout(() => this.setState({ generalError: '' }), 3000);
  			});
  		}
  	}

  	onRoleToggle = (user) => {
  		const { loading, roles } = this.state;

  		if (!loading) {
  			this.setState({ loading: true });
  			axios.post(API_URL + '/user/updateRole', {
  				userId: user._id,
  				roleId: user.roleName === 'Admin' ? roles.find(role => role.name === 'User')._id : roles.find(role => role.name === 'Admin')._id
  			}).then(response => {
  				const data = response.data;
  				if (data.status === 'error') {
  					this.setState({ 
  						generalError: data.message,
  						loading: false 
					});
					setTimeout(() => this.setState({ generalError: '' }), 3000);
  				} else {
  					this.onLoad();
  				}
  			}).catch(error => {
  				this.setState({
		          generalError: error.response.data.message,
		          loading: false
		        });

		        setTimeout(() => this.setState({ generalError: '' }), 3000);
  			});
  		}
  	}

  	handleGlobalAction = async (e) => {
    	if (e.target.value === 'delete') {
    		const { globalActionSelection } = this.state;

    		if (globalActionSelection.length < 1) {
    			alert('Please select at least one user');
    		} else if (window.confirm('Are you sure?')) {
				this.setState({ loading: true });
				await axios.post(API_URL + '/user/bulk-delete', { userIds: globalActionSelection });
  				this.setState({ globalActionSelection: [] });
  				this.onLoad();
    		}
    	}
  	}

  	handleGlobalActionSelection = (id) => {
  		let { globalActionSelection } = this.state;
  		
  		if (globalActionSelection.indexOf(id) === -1) {
  			globalActionSelection = globalActionSelection.concat(id);
		} else {
		 	globalActionSelection.splice(globalActionSelection.indexOf(id), 1);
		}

		this.setState({ globalActionSelection });
  	}

  	getSubscriptionName(id) {
  		const { subscriptionTypes } = this.props;

  		if (!subscriptionTypes) {
  			return null
  		}

  		const subscription = subscriptionTypes.find(s => s._id === id);

  		return subscription ? subscription.title : '-';
  	}

  	render() {
  		const { 
  			users, 
  			usersCount,
	    	regularUsers,
	    	twitterUsers,
	    	totalUsers,
	    	totalTwitterUsers,
			totalRegularUsers,
  			totalPages, 
  			currentPage, 
  			loading, 
  			generalError, 
  			search, 
  			sort, 
  			globalAction, 
  			globalActionSelection,
  			dateFrom,
  			dateTo
  		} = this.state;
  		const pages = [];

  		for (let i=1; i<=totalPages; i++) {
  			if (i < 4 || (i > (currentPage-3) && i < (currentPage+3)) || i > (totalPages-3)) {
  				pages.push(i);
  			}
  		}

  		return (
		    <Grid container className="main main-players">
		      <Box className="game-box game-box-header users-header">
		        <Box className="game-box-row">
		        	<h4 className="simple-title multiple-rows">
		        		Users 
		        		{ 
		        			loading 
		        			? null 
		        			: <span>
		        				&nbsp; - 
		        				Total: { totalUsers }, 
		        				Regular: { totalRegularUsers },
		        				Twitter: { totalTwitterUsers }
		        				<br/>
		        				Selected: { usersCount },
		        				Regular: { regularUsers },
		        				Twitter: { twitterUsers }
	        				</span>
		        		}
	        		</h4>
		          	<Box component="div" className="game-box-col game-box-col-search">
						<InputBase placeholder="Search for a user..." value={search} onChange={event => this.onUpdateSearch('search', event.target.value) } />
      					<IconButton>
        					<Icon>search</Icon>
      					</IconButton>
      				</Box>
      				<div className="date-filters">
	        			<label>From:</label>
	        			<input type="date" value={ dateFrom } onChange={event => this.onUpdateSearch('dateFrom', event.target.value) } />
	        			<label>To:</label>
	        			<input type="date" value={ dateTo } onChange={event => this.onUpdateSearch('dateTo', event.target.value) } />
	        		</div>
		        </Box>
		      </Box>

		      <Box className="players-table">
			      <Table>
			        <TableHead>
			          <TableRow>
			          	<TableCell className="cell-checkbox"></TableCell>
			            <TableCell component="th" className="sortable">
			            	User
		            	</TableCell>
		            	<TableCell component="th">Partner</TableCell>
		            	<TableCell component="th">Type</TableCell>
			            <TableCell component="th">Admin</TableCell>
			            <TableCell component="th" className="sortable mobile-hidden" onClick={ () => this.onUpdateSearch('sort', sort === 'date-desc' ? 'date-asc' : 'date-desc') }>
			            	Registered
			            	<Icon>{ sort === 'date-asc' ? 'expand_less' : 'expand_more' }</Icon>
		            	</TableCell>
			            <TableCell className="action  mobile-hidden">
			            	<NativeSelect value={ globalAction } onChange={ this.handleGlobalAction }>
			            		<option value="">Action</option>
			            		<option value="delete">Delete</option>
				            </NativeSelect>
			            </TableCell>
			          </TableRow>
			        </TableHead>
			        <TableBody>
			          {users.map((user, i) => { 
			          	return (
				            <TableRow key={user._id}>
				            	<TableCell className="cell-checkbox">
				            		<input
				            			type="checkbox" 
				            			checked={ globalActionSelection.indexOf(user._id) !== -1 } 
				            			onChange={ () => this.handleGlobalActionSelection(user._id) } 
			            			/>
				            	</TableCell>
				              	<TableCell>
				                	{user.email}
				              	</TableCell>
				              	<TableCell>
				              		<select value={ user.partner } onChange={e => this.onPartnerChange(user, e.currentTarget.value) }>
				              			<option>Not a partner</option>
				              			<option>Silver Partner</option>
				              			<option>Gold Partner</option>
				              		</select>
				              	</TableCell>
				              	<TableCell>
				              		<select value={ user.accountType } onChange={e => this.onAccountTypeChange(user, e.currentTarget.value) }>
				              			<option value="">Default</option>
				              			<option>VIP</option>
				              			<option>Ambassador</option>
				              			<option>Beat Writer Network</option>
				              		</select>
				              	</TableCell>
				              	<TableCell>
				              		<input type="checkbox" checked={user.roleName === 'Admin'} onChange={ () => this.onRoleToggle(user) } />
			              		</TableCell>
				              	<TableCell className="mobile-hidden">
				              		{moment(user.registrationDate).format(DATE_TIME_FORMAT)}
				              	</TableCell>
				              	<TableCell className="mobile-hidden"></TableCell>
				            </TableRow>
			          	);
			          })}
			        </TableBody>
			      </Table>

			      {
			      	pages.length < 2
			      	? null
			      	: (<div className="global-pagination">
				      	<strong>Page: </strong>
				      	{pages.map((page, i) => { 
				          	return <span key={i}>
				          			{ i > 0 && pages[i] > pages[i-1]*1+1 ? <span>......</span> : null }
			          				<Button onClick={ () => { this.onUpdateSearch('page', page); } } key={page} variant="contained" color={ page === currentPage ? 'primary' : 'default' }>{page}</Button>
			          			</span>;
				        })}
		      	  	</div>)
			      }
		      </Box>
		      { loading ? <LoadingWheel /> : null }
		      { generalError ? <ErrorSnackbar message={generalError} /> : null }
		  	</Grid>
	  	);
  	}
}

function mapStateToProps(state, action) {
  return {
  	subscriptionTypes: state.subscriptionTypes
  }
}
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersScreen);
