import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as weeksActions from '../actions/weeksActions';
import * as indexesActions from '../actions/indexesActions';
import LoadingWheel from '../components/LoadingWheel';
import GameDayScreen from '../screens/GameDayScreen';
import { getToken } from '../helpers/session';
import { API_URL } from '../config.js';
import axios from 'axios';

class GameDayWrapper extends Component {
  constructor() {
    super();

    this.state = {
      featuredGames: []
    }
  }

  componentDidMount() {
    const authToken = getToken();

    if (!authToken) {
      this.props.history.push('/login');
    } else {
      this.onLoadFeaturedGames();
    }
  }

  onLoadFeaturedGames = callback => {
    axios.get(API_URL + '/injuryIndex/getFeaturedGames').then(response => {
      this.setState({ featuredGames: response.data.data.featuredGames });

      if (callback) {
        callback();
      }
    });
  }

  render() {
    const { featuredGames } = this.state;
    const { weeks, globalVars } = this.props;

    if (weeks.length > 0 && globalVars.currentWeek)
      return <GameDayScreen {...this.props} featuredGames={featuredGames} onLoadFeaturedGames={this.onLoadFeaturedGames} />
      
    return <LoadingWheel />
  }
}

function mapStateToProps(state, action) {
  return {
    weeks: state.weeks,
    indexes: state.indexes,
    globalVars: state.globalVars
  }
}
function mapDispatchToProps(dispatch) {
  return {
    weeksActions: bindActionCreators(weeksActions, dispatch),
    indexesActions: bindActionCreators(indexesActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameDayWrapper);
