export const FETCH_SUBSCRIPTION_TYPES = 'FETCH_SUBSCRIPTION_TYPES';
export const RECEIVE_SUBSCRIPTION_TYPES = 'RECEIVE_SUBSCRIPTION_TYPES';

export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const RECEIVE_ARTICLES = 'RECEIVE_ARTICLES';

export const FETCH_TEAMS = 'FETCH_TEAMS';
export const RECEIVE_TEAMS = 'RECEIVE_TEAMS';

export const FETCH_WEEKS = 'FETCH_WEEKS';
export const RECEIVE_WEEKS = 'RECEIVE_WEEKS';

export const FETCH_PLAYERS = 'FETCH_PLAYERS';
export const RECEIVE_PLAYERS = 'RECEIVE_PLAYERS';

export const FETCH_INDEXES = 'FETCH_INDEXES';
export const RECEIVE_INDEXES = 'RECEIVE_INDEXES';

export const RECEIVE_REGISTER_FORM = 'RECEIVE_REGISTER_FORM';
export const RECEIVE_GLOBAL_VARS = 'RECEIVE_GLOBAL_VARS';
export const RECEIVE_SESSION = 'RECEIVE_SESSION';
