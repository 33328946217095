import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import ModalOverlay from '../components/ModalOverlay';
import SubscriptionBox from '../components/SubscriptionBox';
import * as registerFormActions from '../actions/registerFormActions';
import * as subscriptionTypesActions from '../actions/subscriptionTypesActions';

class RegisterSubscriptionScreen extends Component {
	constructor() {
		super();

		this.state = { selection: '' };
	}

	componentWillMount() {
		const { registerForm, subscriptionTypes } = this.props;

		if (subscriptionTypes.length === 0) {
			this.props.history.push('/register');
		} else if (registerForm && registerForm.subscriptionType) {
			this.setState({ selection: registerForm.subscriptionType });
		}
	}

	onSubmit = (e) => {
  		let { selection } = this.state;
  		const { registerForm } = this.props;

  		if (selection !== '') {
  			this.props.registerFormActions.receiveRegisterForm(Object.assign(registerForm, { subscriptionType: selection }));
			this.props.history.push('/register-payment');
  		}
  	}

  	render() {
		const { selection } = this.state;
		const { subscriptionTypes } = this.props;

		return (
		    <Container fixed className="main main-modal">
		    	<ModalOverlay />
		    	<Box>
		    		 <Card className="modal">
				        <CardContent>
				        	<Box mb={6}>
					          	<Typography variant="h6" component="h2" className="modal-title">
					            	Select a subscription
					          	</Typography>
				          	</Box>
				          	{
				          		subscriptionTypes.map((subscriptionType, i) => {
			          				return (<Box mb={1.5} key={subscriptionType.subscriptionId} onClick={() => { this.setState({ selection: subscriptionType.subscriptionId }) }}>
						          		<SubscriptionBox subscriptionType={subscriptionType} selected={selection === subscriptionType.subscriptionId} />
					          		</Box>)
				          		})
				          	}
			        	</CardContent>
				      	<CardActions>
				      		<Box mx="auto" mr={0} mt={2} px={2} py={1}>
					      		<Link to="/register" className="no-underline">
							        <Button size="small" color="primary">
							          	CANCEL
							        </Button>
						        </Link>
						        <Button size="small" color="primary" onClick={this.onSubmit} disabled={ !selection }>
						          	Sign Up
						        </Button>
					        </Box>
				      	</CardActions>
				    </Card>
		    	</Box>
		    </Container>
	  	);
  	}
}

function mapStateToProps(state, action) {
  return {
    subscriptionTypes: state.subscriptionTypes,
    registerForm: state.registerForm
  }
}
function mapDispatchToProps(dispatch) {
  return {
  	registerFormActions: bindActionCreators(registerFormActions, dispatch),
  	subscriptionTypesActions: bindActionCreators(subscriptionTypesActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSubscriptionScreen);
