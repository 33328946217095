import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getToken } from '../helpers/session';
import * as teamsActions from '../actions/teamsActions';
import * as weeksActions from '../actions/weeksActions';
import * as playersActions from  '../actions/playersActions';
import * as globalVarsActions from  '../actions/globalVarsActions';

class GlobalLoader extends Component {
	componentDidMount() {
		this.globalLoading();
	}

	componentDidUpdate() {
		this.globalLoading();
	}

	globalLoading() {
		const authToken = getToken();
		
		if (authToken) {
    		this.props.teamsActions.fetchTeams();
	      	this.props.weeksActions.fetchWeeks().then(payload => {
	        	let currentWeek = payload.weeks.find(week => moment() > moment(week.startDate) && moment() < moment(week.endDate.split('T')[0] + 'T23:59:59.999Z'));
	        	
	        	if (!currentWeek && payload.weeks.length > 0)
        			currentWeek = payload.weeks[0];

	        	this.props.globalVarsActions.receiveGlobalVars({ currentWeek });
	        	if (currentWeek) {
	        		this.props.playersActions.fetchPlayers(currentWeek._id);	
	        	}
	      	});
		}
	}

	render() {
		return <div className="global-loader"></div>
	}
}

function mapStateToProps(state, action) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
  	teamsActions: bindActionCreators(teamsActions, dispatch),
    weeksActions: bindActionCreators(weeksActions, dispatch),
    playersActions: bindActionCreators(playersActions, dispatch),
    globalVarsActions: bindActionCreators(globalVarsActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalLoader);
