import initialState from './initialState';
import { RECEIVE_REGISTER_FORM } from '../actions/actionTypes';

export default function registerForm(state = initialState.registerForm, action) {
  let newState;

  switch (action.type) {
    case RECEIVE_REGISTER_FORM:
      newState = action.registerForm;

      return newState;

    default:
      return state;
  }
}
