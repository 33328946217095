import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

export default class CustomDateTimePicker extends Component {
	render() {
		const { showDatePicker, onClose, onChange, value } = this.props;

		return (
			<div className="datepicker datepicker-input">
    			<MuiPickersUtilsProvider utils={MomentUtils}>
		        	<DateTimePicker
		        		value={value ? value : moment().toDate()} 
		        		open={showDatePicker} 
		        		onClose={onClose} 
		        		onChange={onChange}
	        		/>
				</MuiPickersUtilsProvider>
			</div>
		)
  	}
}

CustomDateTimePicker.propTypes = {
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

CustomDateTimePicker.defaultProps = {
	showDatePicker: false
}
