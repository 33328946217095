import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

function Error404Screen() {
  return (
    <Container fixed className="main">
    	<Box>
    		Error 404
    	</Box>
    </Container>
  );
}

export default Error404Screen;
