import * as types from './actionTypes';
import axios from 'axios';
import { API_URL } from '../config.js';
import { getSeason } from '../helpers/session';

export function receiveTeams(teams) {
	return { type: types.RECEIVE_TEAMS, teams: teams };
}

export function fetchTeams() {
	return dispatch => {
    	return axios.get(API_URL + '/team?season=' + getSeason()).then(response => dispatch(receiveTeams(response.data.data ? response.data.data.teamList : [])));
  	};
}
