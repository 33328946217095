import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class ModalOverlay extends Component {
	constructor() {
		super();

		this.state = { close: false };
	}

	onClose = () => {
		this.setState({ close: true });
	}

	render() {
		const { close } = this.state;

		if (close) {
			return <Redirect to="/" />
		}

		return <div className="modal-overlay" onClick={ this.onClose }></div>
	}
}

export default ModalOverlay;
