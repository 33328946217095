import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

export default class ErrorSnackbar extends Component {
	render() {
		const { message } = this.props;

		return <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={true} className="error" message={ message }></Snackbar>
  	}
}

ErrorSnackbar.propTypes = {
  message: PropTypes.string.isRequired
}
