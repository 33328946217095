import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

export default class SubscriptionBox extends Component {
	render() {
		const { selected, active, subscriptionType, activeSubscription, onNewPass, subscriptionEndDate } = this.props;
		const daysToExpire = subscriptionType.daysToExpire ? subscriptionType.daysToExpire : subscriptionType.numberOfDays;

		return (
		    <Card className={ 'subscription-box' + (selected ? ' selected' : '') }>
		      <CardContent>
		      	<Box mb={0.5}>
			        <Typography className="first-line">
			          <strong>{ subscriptionType.title }</strong>
			        </Typography>	
		        </Box>
		        <Box mb={1.5}>
			        <Typography color="textSecondary" className="second-line">
			          { subscriptionType.description }
			        </Typography>
		        </Box>
		        <Typography variant="body2" component="div" className="third-line">
	          		{
	          			active && activeSubscription
	          			? <strong className="active-label">Active</strong>
	          			: <strong>${ subscriptionType.price }</strong>
	          		}
	          		<span>
	          			{
		          			daysToExpire > 7 
		          			? 'Expires on 29 Feb 2020' 
		          			: ('Expires in ' + (active && activeSubscription ? Math.round(moment(subscriptionEndDate).diff(moment(), 'hours') / 24) : daysToExpire) + ' days') 
	          			}
          			</span>
          			{
          				active && (!activeSubscription || activeSubscription.numberOfDays < 365)
          				? <span className="season-pass" onClick={ onNewPass }>
	          				{ daysToExpire > 7 ? 'Season pass' : 'One week pass' }
	          			</span>
      					: null
          			}
		        </Typography>
		      </CardContent>
		    </Card>
	  	);
	}
}

SubscriptionBox.propTypes = {
  subscriptionType: PropTypes.object.isRequired
};

SubscriptionBox.defaultProps = {
  selected: false,
  active: false
};
