import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import HeaderSearchSuggestions from './HeaderSearchSuggestions';
import SeasonSwitcher from './SeasonSwitcher';
import Tooltip from './Tooltip';
import logo from '../images/logo.png';
import { getToken, forgetToken, forgetRefreshToken, forgetTempToken, setAdmin, isAdmin } from '../helpers/session';

class Header extends Component {
  constructor() {
  	super();

  	this.state = {
  		search: '',
      searchId: '',
  		isSearchActive: false,
  		showAdminNavBar: false,
      // leagueType: localStorage.getItem('pfd_selected_league_2') ? localStorage.getItem('pfd_selected_league_2') : 'nfl'
      leagueType: 'nfl'
  	};
  }

  componentDidMount() {
  	document.body.addEventListener('click', e => {
  		if (e && e.target && e.target.className &&  e.target.className.indexOf && e.target.className.indexOf('prevent-search-close') === -1)
  			this.setState({ isSearchActive: false });
  	});
  }

  onLogout = () => {
  	forgetToken();
  	forgetRefreshToken();
  	forgetTempToken();
  	setAdmin(false);
  	this.onHideAdminNavBar();
  	this.props.onRefreshSession();
  	this.props.history.push('/');
  }

  onToggleAdminNavBar = () => {
  	this.setState({ showAdminNavBar: !this.state.showAdminNavBar });
  }

  onHideAdminNavBar = () => {
  	this.setState({ showAdminNavBar: false });
  }

  onSearchChange = e => {
  	const { value } = e.target;
  	
  	this.setState({ search: value });
  }

  onSearchFinished = () => {
  	const { search, searchId } = this.state;
  	const mainSearch = document.getElementById('main-search');
  	
  	if (mainSearch) {
  		mainSearch.parentNode.setAttribute('data-state', parseInt(mainSearch.parentNode.attributes['data-state'].value) + 1);
  		mainSearch.parentNode.setAttribute('data-value', search);
      mainSearch.parentNode.setAttribute('data-value-id', searchId);
  	}

  	this.setState({
  		isSearchActive: false,
      searchId: '',
  		search: ''
  	});
  	this.props.history.push('/research?search=' + search + '&searchId=' + searchId);
  }

  onSearchToggle = e => {
  	e.preventDefault();
  	const { isSearchActive, search } = this.state;
  	
  	if (!isSearchActive) {
  		this.setState({ isSearchActive: !isSearchActive });
  		setTimeout(() => document.getElementById('header-search').focus());
  	} else if (search.trim() !== '') {
  		this.onSearchFinished();
  	}
  }

  onSearchSubmit = e => {
  	const { search } = this.state;
  	
  	if (e.keyCode === 13 && search.trim() !== '')
  		this.onSearchFinished();
  	else if (e.keyCode === 27)
  		this.setState({ isSearchActive: false });
  }

  render() {
  	const { showAdminNavBar, search, isSearchActive } = this.state;
  	const pathname = this.props.history.location.pathname.replace('/', '');
  	const isLoggedIn = getToken() !== null;
  	const subNavDisabled = ['register-subscription', 'register-payment'].indexOf(pathname) !== -1;

  	return (
	    <div className="header">
	      <div className="top-bar">
	        <Container fixed>
	          <Grid container>
	            <Grid item xs={4} className="logo">
	              <a href="https://profootballdoc.com">
	                <img src={ logo } alt="PFD logo" />
	              </a>
	            </Grid>
	            <Grid item xs={8} className="top-action">
	            	{
	            		isLoggedIn && !subNavDisabled
	            		? (<div className="account-icons">
  	            			<div className={ 'search-box ' + (isSearchActive ? 'is-active' : '') }>
  	            				<input id="header-search" 
              					 	className="prevent-search-close"
  	            					type="text" 
  	            					name="search" 
  	            					autoComplete="off" 
  	            					value={search} 
  	            					onChange={ this.onSearchChange } 
  	            					onKeyUp={ this.onSearchSubmit } 
              					/>
  	            				<IconButton className="prevent-search-close" onClick={ this.onSearchToggle }>
  	            					<Icon className="prevent-search-close">search</Icon>
              					</IconButton>
              					{ 
              						isSearchActive 
              						? <HeaderSearchSuggestions 
              							search={ search }
              							onSelect={ player => {
              								this.setState({ search: player.name, searchId: player._id });
              								setTimeout(() => this.onSearchFinished());
          							} } /> 
              						: null 
              					}
  	            			</div>
	            			  <Link to="/account" onClick={ this.onHideAdminNavBar }>
			              		<Icon>account_circle</Icon>
			              		<Tooltip className="bottom" text="Edit your account" />
              	  		</Link>
              	  		<Button onClick={ this.onLogout }>
              	  			<Icon>exit_to_app</Icon>
              	  			<Tooltip className="bottom" text="Logout" />
              	  		</Button>
            	  		</div>) : (<div>
        				    <Link to="/login">
			                <Button>Login</Button>
			              </Link>
			              <Link to="/register" className="btn-register">
			                <Button variant="contained">
			                  Sign up
			                </Button>
			              </Link>
            			</div>)
	            	}
	            </Grid>
	          </Grid>
	        </Container>
	      </div>
	      {
	      	isLoggedIn && !subNavDisabled
	      	? (<div className="nav-bar">
  		        <Container fixed> 
  		          <Grid container>
  		            <Grid item xs={6} className={classNames({ admin_nav_bar: isAdmin(), show_admin_nav_bar: showAdminNavBar })}>
  	            	  <span className="mobile-menu-trigger" onClick={ this.onToggleAdminNavBar }>
  	            	  	<span></span>
  	            	  	<span></span>
  	            	  	<span></span>
  	            	  </span>
  		              <div className="mobile-menu-wrapper">
              		    <span className="close-btn" onClick={ this.onHideAdminNavBar }>
  			              	<Icon>close</Icon>
  			              </span>
  			              <Link onClick={ this.onHideAdminNavBar } to="/live" className={ pathname === 'live' ? 'active' : '' }>Doc Live</Link>
  			              <Link onClick={ this.onHideAdminNavBar } to="/game" className={ pathname === 'game' ? 'active' : '' }>Injury Index</Link>
  			              { false ? <Link onClick={ this.onHideAdminNavBar } to="/players" className={ pathname === 'players' ? 'active' : '' }>Players</Link> : null }
  			              { false ? <Link onClick={ this.onHideAdminNavBar } to="/teams" className={ pathname === 'teams' ? 'active' : '' }>Teams</Link> : null }
  			              <Link onClick={ this.onHideAdminNavBar } to="/research" className={ pathname === 'research' ? 'active' : '' }>Players/Teams</Link>
                      <Link onClick={ this.onHideAdminNavBar } to="/teams" className={ pathname === 'teams' ? 'active' : '' }>My Teams</Link>
                      <Link onClick={ this.onHideAdminNavBar } to="/quick-hits" className={ pathname === 'quick-hits' ? 'active' : '' }>Quick Hits</Link>
                      <Link onClick={ this.onHideAdminNavBar } to="/early-line-alerts" className={ pathname === 'early-line-alerts' ? 'active' : '' }>Early Line Alerts</Link>
                      <Link onClick={ this.onHideAdminNavBar } to="/fantasy-values" className={ pathname === 'fantasy-values' ? 'active' : '' }>Fantasy Values</Link>
  			              { isAdmin() ? <Link onClick={ this.onHideAdminNavBar } to="/users" className={ pathname === 'users' ? 'active' : '' }>Users</Link> : null }
  		              </div>
  		            </Grid>
  		            <Grid item xs={6} align="right">
                    { /* 
                    XFL
                    <Switch
                      checked={leagueType === 'nfl'}
                      onClick={event => {
                        const newLeagueType = leagueType === 'nfl' ? 'xfl' : 'nfl'
                        localStorage.setItem('pfd_league_2', newLeagueType);
                        this.setState({ leagueType: newLeagueType });
                        setTimeout(() => window.location.reload(), 500);
                      }}
                      color="default"
                    />
                    NFL | 
                    */ }
                    <SeasonSwitcher />
  		            </Grid>
  		          </Grid>
  		        </Container>
  		      </div>)
	      	: null
	      }
	    </div>
	  );
  }
}

export default withRouter(Header);
