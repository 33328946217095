export function getEmailValidationRegex() {
	return /\S+@\S+\.\S+/;
}

export function getTeamShortName(name) {
	if (typeof name === 'undefined')
		return '';

	const pieces = name.split(' ');
	
	return pieces[pieces.length - 1];
}

export function getVimeoId(embed) {
	// let id = embed.substring(embed.indexOf('vimeo.com/video/') + 16);
	// id = id.substring(0, id.indexOf('?'))
	let id = embed.substring(embed.indexOf('/videos/') + 8);
	id = id.substring(0, id.indexOf('.m3u8'));

	return id;
}

export function getVimeoEmbed(embed) {
	const pieces = embed.split('src="');
	const innerPieces = pieces[1].split('"');
	innerPieces[0] = innerPieces[0] + '&autoplay=1';
	const newEmbed = pieces[0] + 'src="' + innerPieces.join('"');

	return newEmbed;
}

export function getEmbedCode(id) {
	return '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + id + '?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

	// url = url.replace('https://livestreamapis.com/v3/accounts/', '');
	// url = url.substring(0, url.indexOf('.m3u8?'));
	// url = url.split('/');

	// if (url[2] && url[4])
	// 	return '<iframe src="https://livestream.com/accounts/28790112/events/' + url[2] + '/videos/' + url[4] + '/player?width=640&height=360&enableInfo=true&defaultDrawer=&autoPlay=true&mute=false" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen></iframe>';
	// else if (url[2])
	// 	return '<iframe src="https://livestream.com/accounts/28790112/events/' + url[2] + '/player?width=640&height=360&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen></iframe>';

	// return '<div></div>';
}

export function prepareEmbeds(body) {
	return  body.replace('<oembed url', '<iframe src').replace('</oembed>', '</iframe>')
				.replace('<oembed url', '<iframe src').replace('</oembed>', '</iframe>')
				.replace('<oembed url', '<iframe src').replace('</oembed>', '</iframe>')
				.replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
				.replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
				.replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
				.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/')
				.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/')
				.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/')
	;
}

export function resizeIframe(iframe) {
	if (iframe.width)
		iframe.style.height =  Math.round(iframe.height * iframe.offsetWidth / iframe.width) + 'px';
}
