import * as types from './actionTypes';
import axios from 'axios';
import { API_URL } from '../config.js';
import { getSeason } from '../helpers/session';

export function receiveWeeks(weeks) {
	return { type: types.RECEIVE_WEEKS, weeks: weeks };
}

export function fetchWeeks() {
	return dispatch => {
    	return axios.get(API_URL + '/scheduleTimeline/season/' + getSeason()).then(response => dispatch(receiveWeeks(response.data.data ? response.data.data.scheduleTimelines : [])));
  	};
}
