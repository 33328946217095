import React, { Component } from 'react';
import axios from 'axios';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import LoadingWheel from './LoadingWheel';
import { setToken, getToken, getTempToken, forgetTempToken } from '../helpers/session';
import { API_URL } from '../config';

class CheckoutForm extends Component {
	constructor() {
		super();

		this.state = {
			form: {
				name: '',
				zip: '',
				couponCode: '',
				remember: true
			},
			errors: {
				name: false,
				zip: false,
				couponCode: false
			},
			loading: false,
			stripeError: '',
		};
	}

	updateFormModel = (e) => {
	    const { form, errors } = this.state;
	    const { target } = e;
	    const { name, value, checked } = target;

	    form[name] = name === 'remember' ? checked : value;
	    errors[name] = false;

	    this.setState({
	        form: form,
	        errors: errors
	    });
  	}

	 onSubmit = async (e) => {
	 	const { form, errors } = this.state;
	 	const { subscriptionType } = this.props;
	 	let hasErrors = false;

	 	if (form.name === '') {
	 		errors.name = true;
	 		hasErrors = true;
	 	} 
	 	if (form.zip === '') {
	 		errors.zip = true;
	 		hasErrors = true;
	 	}

	 	if (hasErrors) {
			this.setState({ errors });
	 	} else {
	 		this.setState({
				loading: true,
				stripeError: ''
			});

			const { token, error } = await this.props.stripe.createToken({ name: form.name });

	  		if (typeof error !== 'undefined') {
	  			this.setState({ 
	  				stripeError: error.message, 
	  				loading: false 
	  			});
	  		} else {
	  			axios.post(API_URL + '/subscription/user/subscribe', {
	  				stripeTokenId: token.id,
	  				subscriptionId: subscriptionType.subscriptionId,
	  				couponCode: form.couponCode
	  			}).then(() => {
	  				const existingToken = getToken();
	  				this.setState({ loading: false });
	  				setToken(getTempToken());
	  				forgetTempToken();
	  				
	  				if (existingToken)
	  					this.props.onSuccess();
  					else
	  					this.props.history.push('/live?register=true');
	  			}).catch(error => {
	  				this.setState({
	  					loading: false,
	  					stripeError: error.response.data.message
	  				});
	  			});
	  		}
	 	}
	}

	render() {
		const { form, errors, loading, stripeError } = this.state;

		return (
      		<Box className="checkout-form">
        		<CardContent>
        			{ loading ? <LoadingWheel /> : null }
	  				<Box mb={1.5}>
	  					<FormControl fullWidth={true}>
	    					<InputLabel>Name</InputLabel>
	    					<Input type="text" name="name" value={form.name} error={errors.name} onChange={this.updateFormModel} />
	  					</FormControl>
					</Box>
					<Box mb={1.5} pt={1}>
	  					<FormControl fullWidth={true} className="card-number">
	    					<InputLabel>Card</InputLabel>
	    					<CardNumberElement />
	  					</FormControl>
					</Box>
					<Box mb={1} pt={1}>
	  					<FormControl className="right-separator card-date">
	    					<InputLabel>Expiry</InputLabel>
	    					<CardExpiryElement />
	  					</FormControl>
	  					<FormControl className="card-cvc">
	    					<InputLabel>CVC</InputLabel>
	    					<CardCvcElement />
	  					</FormControl>
					</Box>
					<Box mb={1.5}>
	  					<FormControl fullWidth={true}>
	    					<InputLabel>Zip</InputLabel>
	    					<Input type="text" name="zip" value={form.zip} error={errors.zip} onChange={this.updateFormModel} />
	  					</FormControl>
					</Box>
					<Box mb={1.5}>
	  					<FormControl fullWidth={true}>
	    					<InputLabel>Coupon code</InputLabel>
	    					<Input type="text" name="couponCode" value={form.couponCode} error={errors.couponCode} onChange={this.updateFormModel} />
	  					</FormControl>
					</Box>
		          	<Box>
						<Typography variant="body2" color="textSecondary" component="span">
	          				Remember me
	      				</Typography>
	  					<Switch checked={form.remember} color="primary" name="remember" onChange={this.updateFormModel} />
					</Box>
					<div className="global-error">{ stripeError }</div>
				</CardContent>
	      		<CardActions>
	      			<Box mx="auto" mr={0} px={2} py={1}>
			        	<Button size="small" color="primary" onClick={() => { getToken() ? this.props.onCancel() : this.props.history.push('/register-subscription') }}>
			          		CANCEL
			        	</Button>
			        	<Button size="small" color="primary" onClick={ this.onSubmit }>
			          		Submit
			        	</Button>
		        	</Box>
	      		</CardActions>
      		</Box>
		)
  	}
}

export default injectStripe(CheckoutForm);
