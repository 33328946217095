import * as types from './actionTypes';
import axios from 'axios';
import { API_URL } from '../config.js';

export function receiveSubscriptionTypes(data) {
	for (let i in data)
		if (typeof data[i].subscriptionId === 'undefined')
			data[i].subscriptionId = data[i]._id;

  	return { type: types.RECEIVE_SUBSCRIPTION_TYPES, subscriptionTypes: data };
}

export function fetchSubscriptionTypes() {
	return dispatch => {
    	return axios.get(API_URL + '/subscription').then(response => dispatch(receiveSubscriptionTypes(response.data.data.subscriptions)));
  	};
}
