import { SESSION_KEY, SESSION_REFRESH_KEY, TEMP_SESSION_KEY, SESSION_TYPE_KEY, SESSION_SETTINGS_KEY } from '../config.js';

export function setToken(token) {
	localStorage.setItem(SESSION_KEY, token);
}

export function getToken() {
	return localStorage.getItem(SESSION_KEY);
}

export function forgetToken() {
	localStorage.removeItem(SESSION_KEY);
}

export function setRefreshToken(token) {
	localStorage.setItem(SESSION_REFRESH_KEY, token);
}

export function getRefreshToken() {
	return localStorage.getItem(SESSION_REFRESH_KEY);
}

export function forgetRefreshToken() {
	localStorage.removeItem(SESSION_REFRESH_KEY);
}

export function setTempToken(token) {
	localStorage.setItem(TEMP_SESSION_KEY, token);
}

export function getTempToken() {
	return localStorage.getItem(TEMP_SESSION_KEY);
}

export function forgetTempToken() {
	localStorage.removeItem(TEMP_SESSION_KEY);
}

export function setAdmin(flag) {
	localStorage.setItem(SESSION_TYPE_KEY, flag ? 'admin' : 'user');
}

export function isAdmin() {
	return localStorage.getItem(SESSION_TYPE_KEY) === 'admin';
}

export function setSettings(key, value) {
	const settings = getSettings();
	settings[key] = value;

	localStorage.setItem(SESSION_SETTINGS_KEY, JSON.stringify(settings));
}

export function getSettings(key, defaultValue) {
	let settings = localStorage.getItem(SESSION_SETTINGS_KEY);
	settings = settings ? JSON.parse(settings) : {};

	return key ? (settings[key] ? settings[key] : defaultValue) : settings;
}

export function setSeason(season) {
	localStorage.setItem('pfd_selected_season_2', season);
}

export function getSeason() {
	return localStorage.getItem('pfd_selected_season_2') ? localStorage.getItem('pfd_selected_season_2') : 2021;
}
