import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DateTimePicker from './DateTimePicker';
import LoadingWheel from './LoadingWheel';
import { API_URL, APP_URL } from '../config.js';

export default class NotifyControl extends Component {
	constructor() {
		super();

		this.state = { 
			channels: {
				email: true,
				text: true
			},
			message: '',
			scheduled: false,
			showDatePicker: false,
			date: moment(),
			loading: false,
			recipients: {
				emailNotificationsCount: 0, 
				phoneNotificationsCount: 0
			}
		};
	}

	componentDidMount() {
		const { type, object } = this.props;
		const data = {};

		if (type === 'post') {
			data.postId = object._id;
		} else if (type === 'injuryIndex') {
			data.gameScheduleId = object._id;
		} else if (type === 'video') {
			data.videoId = object._id;
		}

		axios.post(API_URL + '/sendNotification/userNotificationCount', data).then(({data}) => { 
			this.setState({ recipients: data.data });
      	});

		this.setState({ message: this.getNotificationText() });
	}

	onClose = () => {
		const { onClose } = this.props;

		if (onClose) {
			onClose();
		}
	}

	onSubmit = () => {
		const { scheduled, message, channels, date } = this.state;
		const { type, object } = this.props;

		this.setState({ loading: true });

		const data = {
			notificationText: message,
			notificationType: scheduled ? 'Scheduled' : 'Immediately',
			textNotification: channels.text,
			emailNotification: channels.email,
			scheduleTime: date,
		};

		if (type === 'post') {
			data.postId = object._id;
		} else if (type === 'injuryIndex') {
			data.GameScheduleId = object._id;
		} else if (type === 'video') {
			data.videoId = object._id;
		}

		axios.post(API_URL + '/sendNotification/' + type, data).then(({data}) => { 
			if (data.message) {
				alert(data.message);
			} else {
				alert('The notification was successfully ' + (scheduled ? 'scheduled' : 'sent'));	
			}
        	this.onClose();
      	}).catch(error => {
        	this.setState({ loading: false });
        	setTimeout(() => this.setState({ newArticleError: '' }), 3000);
      	});
	}

	getNotificationText = () => {
		const { type, object } = this.props;

		if (type === 'post') {
			return object.title + '\nSee full update: ' + APP_URL + (object.type === 'teamUpdate' ? '/#/docs-insights' : '');
		} else if (type === 'injuryIndex') {
			return object.homeTeamDetails.name + ' vs. ' + object.visitingTeamDetails.name + ' SIC Score/Field View Now Live!\nSee full game: ' + APP_URL + '/#/games';
		} else if (type === 'video') {
			return object.title + '\nSee full video: ' + APP_URL + '/#/videos';
		}

		return '';
	}

	render() {
		const { channels, message, scheduled, showDatePicker, date, loading, recipients } = this.state;

		return <div className="notify-control">
			<div className="notify-control-inner">
				<div className="notify-control-header">
					<div className="notify-control-header-text">Send a Notification</div>
					<IconButton className="close-button" onClick={ this.onClose }>
  						<Icon>close</Icon>
					</IconButton>
				</div>
				<div className="notify-control-content">
					<div className="notify-control-formfield">
						<div className="notify-control-label">Notification text</div>
						<textarea value={ message } onChange={ e => this.setState({ message: e.target.value }) }></textarea>
					</div>
				
					<div className="notify-control-columns">
						<div className="notify-control-formfield">
							<div className="notify-control-label">Send to</div>
							<ul className="multiple-selectors">
	              				<li>
	                				<span>Email</span>
	                				<Switch 
	                					checked={channels.email} 
	                					color="primary" 
	                					name="updates" 
	                					onChange={ e => this.setState({ channels: Object.assign(channels, { email: e.target.checked }) }) } 
                					/>
	              				</li>
	              				<li>
	                				<span>Text</span>
	                				<Switch 
	                					checked={channels.text} 
	                					color="primary" 
	                					name="updates" 
	                					onChange={ e => this.setState({ channels: Object.assign(channels, { text: e.target.checked }) }) } 
                					/>
	              				</li>
	            			</ul>
						</div>
					
						<div className="notify-control-formfield">
							<div className="notify-control-label">Delivery time</div>
							<div className="notify-control-time">
								<div className="notify-control-time-text">{ scheduled ? 'Scheduled' : 'Immediately' }</div>
								<IconButton className="close-button" onClick={ () => this.setState({ showDatePicker: true }) }>
			  						<Icon>calendar_today</Icon>
								</IconButton>
								<DateTimePicker
									value={date} 
									showDatePicker={showDatePicker} 
                                  	onClose={() => this.setState({ showDatePicker: false, scheduled: false }) }
                                  	onChange={date => this.setState({ showDatePicker: false, scheduled: true, date: date }) }
                              	/>
							</div>
						</div>
					</div>

					<div className="notify-control-footer">
						<Typography variant="body2" component="div">
							Recipients: { recipients.emailNotificationsCount } (email) { recipients.phoneNotificationsCount } (text)
						</Typography>
						<div>
							<Button variant="outlined" onClick={ this.onClose }>Cancel</Button>
							<Button className="send-btn" color="primary" variant="contained" onClick={ this.onSubmit }>
								{ scheduled ? 'Schedule Notification' : 'Send Now' }
							</Button>
						</div>
					</div>
				</div>
			</div>

			{ loading ? <LoadingWheel /> : null }
		</div>
	}
}
