export const APP_VERSION = '5.2.6';

export const API_URL = 'https://admin-api.profootballdoc.com:4433';
export const APP_URL = 'https://profootballdoc.com';

export const MIN_PASSWORD_LENGTH = 6;
export const DATE_TIME_FORMAT = 'M/D/YY @ h:mma';

export const SESSION_KEY = 'pfd_auth_token';
export const SESSION_REFRESH_KEY = 'pfd_refresh_token';
export const TEMP_SESSION_KEY = 'pfd_auth_temp_token';
export const SESSION_TYPE_KEY = 'pfd_auth_type_token';
export const SESSION_SETTINGS_KEY = 'pfd_settings_token';

export const STRIPE_API_KEY = 'pk_test_wbCwrGXXQ2fabqgFL2afSxsG00t69vUh7V';

export const RECAPTCHA_KEY = '6LeKNr8UAAAAALr5ZCMKZukxpEMM786yPLfgOWUq';

// 1c235ae0-dba4-11ea-a259-1babcd5a5046
