import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SuccessSnackbar from './SuccessSnackbar';
import { APP_URL } from '../config.js';

function getIframeEmbed(type, display, width, height) {
	return '<script type="text/javascript">document.write(\'<iframe src="' + getIframeUrl(type, display) + '" style="width: ' + width + 'px; height: ' + height + 'px; border: 0px none;"></iframe>\');</script>';
}

function getWordPressEmbed(type, display, width, height) {
	return '[pfd-embed-widget url="' + APP_URL + '" type="' + type + '" display="' + display + '" width="' + width + '" height="' + height + '"]';
}

function getIframeUrl(type, display) {
	if (type === 'games') {
		return APP_URL + '/#/game/' + display + '?domain=localhost';		
	}

	return APP_URL + '/#/embed?type=' + type + '&display=' + display + '&domain=localhost';
}

function Label({ children, className }) {
	return <div className={ 'embed-configurator__label ' + className }>
		{ children }
	</div>
}

export default function EmbedConfigurator({ games, onHide }) {
	const [type, setType] = useState('docLiveUpdate');
	const [display, setDisplay] = useState('posts-3');
	const [width, setWidth] = useState(320);
	const [height, setHeight] = useState(320);
	const [message, setMessage] = useState('');
	const [iframeKey, setIframeKey] = useState(1);
	const iframeEmbed = getIframeEmbed(type, display, width, height);
	const wordPressEmbed = getWordPressEmbed(type, display, width, height);

  	return <>
  		<div className="embed-configurator__overlay"></div>
	  	<div className="embed-configurator__modal">
	  		<div className="embed-configurator__row">
	  			<div className="embed-configurator__col embed-configurator__title">Embed Widget Builder</div>
				<div>
		  			<IconButton className="close-button" onClick={() => onHide()}>
  						<Icon>close</Icon>
					</IconButton>
				</div>
	  		</div>
	  		<div className="embed-configurator__row">
		  		<div className="embed-configurator__col">
			  		<Label>Content Type</Label>
			  		<select value={type} onChange={event => {
			  			const newType = event.target.value;
			  			setType(newType);
			  			setDisplay(newType === 'videos' ? 'videos-1' : (newType === 'games' ? games[0]._id : 'posts-3'));
			  			setIframeKey(iframeKey * 2);
			  		}}>
			  			<option value="docLiveUpdate">Doc Live</option>
						<option value="games">Injury Index</option>
						<option value="videos">Doc's Videos</option>
						<option value="teamUpdate">Doc's Insights</option>
						<option value="fantasyValues">Fantasy Values</option>
						<option value="earlyLineAlerts">Early Line Alerts</option>
			  		</select>

			  		<Label>Display</Label>
			  		{
			  			type === 'games'
			  			? <select value={display} onChange={event => {
				  			setDisplay(event.target.value);
				  			setIframeKey(iframeKey * 2);
				  		}}>
				  			{
				  				games.map(game => {
                            		return <option key={game._id} value={game._id}>
                            			{ game.homeTeamDetails.name + ' vs. ' + game.visitingTeamDetails.name + ' (' + game.weekDetails.name + ')' }
                        			</option>
                          		})
				  			}
				  		</select>
			  			: <select value={display} onChange={event => {
				  			setDisplay(event.target.value);
				  			setIframeKey(iframeKey * 2);
				  		}}>
							{ type === 'videos' ? null : <option value="posts-3">3 recent posts</option> }
							{ type === 'videos' ? null : <option value="posts-5">5 recent posts</option> }
							{ type === 'videos' ? null : <option value="posts-7">7 recent posts</option> }
							{ type === 'videos' ? <option value="videos-1">1 most recent video</option> : null }
							{ type === 'videos' ? <option value="videos-3">3 recent videos</option> : null }
				  		</select>
			  		}

			  		<div className="embed-configurator__inline-fields">
				  		<Label className="mb0">Width</Label>
				  		<input type="number" value={width} onChange={event => setWidth(event.target.value)} />
				  		<span>px</span>
				  		<Label className="mb0">Height</Label>
				  		<input type="number" value={height} onChange={event => setHeight(event.target.value)} />
				  		<span>px</span>
			  		</div>

			  		<Label>Embed Code</Label>
			  		<textarea readOnly={true} value={ iframeEmbed }></textarea>
			  		<div className="embed-configurator__copy-embed">
			  			<span onClick={() => {
			  				const element = document.createElement('textarea');
					  		element.value = iframeEmbed;
							document.body.appendChild(element);
					  		element.select();
					  		document.execCommand('copy');
					  		document.body.removeChild(element);

			  				setMessage('Embed code copied to clipboard');
			  				setTimeout(() => setMessage(''), 3000);
			  			}}>Copy Embed Code</span>
		  			</div>

			  		<Label>WordPress Shortcode</Label>
			  		<input value={ wordPressEmbed } readOnly={true} />
			  		<div className="embed-configurator__copy-embed">
			  			<span onClick={() => {
					  		const element = document.createElement('textarea');
					  		element.value = wordPressEmbed;
							document.body.appendChild(element);
					  		element.select();
					  		document.execCommand('copy');
					  		document.body.removeChild(element);

			  				setMessage('Embed code copied to clipboard');
			  				setTimeout(() => setMessage(''), 3000);
			  			}}>Copy Embed Code</span>
		  			</div>
		  		</div>
		  		<div className="embed-configurator__col">
		  			<Label>Widget Preview</Label>
		  			<iframe 
		  				key={ iframeKey }
		  				src={ getIframeUrl(type, display) }
		  				style={{ width: width + 'px', height: height + 'px' }}
	  				></iframe>
	  			</div>
	  		</div>
		</div>

		{ message ? <SuccessSnackbar message={message} /> : null }
	</>
}
