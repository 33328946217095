import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as weeksActions from '../actions/weeksActions';
import LoadingWheel from '../components/LoadingWheel';
import QuickHitsScreen from '../screens/QuickHitsScreen';
import { getToken } from '../helpers/session';

class QuickHitsWrapper extends Component {
  render() {
    const { weeks, globalVars } = this.props;

    if (weeks.length > 0 && globalVars.currentWeek)
      return <QuickHitsScreen {...this.props} />
      
    return <LoadingWheel />
  }

  componentDidMount() {
    const authToken = getToken();

    if (!authToken) {
      this.props.history.push('/login');
    }
  }
}

function mapStateToProps(state, action) {
  return {
    weeks: state.weeks,
    globalVars: state.globalVars
  }
}
function mapDispatchToProps(dispatch) {
  return {
    weeksActions: bindActionCreators(weeksActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickHitsWrapper);
