import initialState from './initialState';
import { FETCH_SUBSCRIPTION_TYPES, RECEIVE_SUBSCRIPTION_TYPES } from '../actions/actionTypes';

export default function subscriptionTypes(state = initialState.subscriptionTypes, action) {
  let newState;

  switch (action.type) {
  	case FETCH_SUBSCRIPTION_TYPES:
      return action;
    
    case RECEIVE_SUBSCRIPTION_TYPES:
      newState = action.subscriptionTypes;

      return newState;

    default:
      return state;
  }
}
