import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import { APP_URL } from '../config.js';

export default class EmbedControl extends Component {
	constructor() {
		super();

		this.state = { 
			active: false 
		};
	}

	onToggle = () => {
		const { onAlert } = this.props;

		// this.setState({ active: !this.state.active });
		const embedCode = this.getEmbedCode();
  		const element = document.createElement('textarea');
  		element.value = embedCode;
		document.body.appendChild(element);
  		element.select();
  		document.execCommand('copy');
  		document.body.removeChild(element);
  		onAlert('Embed code copied to clipboard');
	}

	onCopyId = () => {
		const { type, id, onAlert } = this.props;
  		const element = document.createElement('textarea');
  		element.value = id;
		document.body.appendChild(element);
  		element.select();
  		document.execCommand('copy');
  		document.body.removeChild(element);
  		onAlert(type.charAt(0).toUpperCase() + type.slice(1) + ' ID copied to clipboard');
	}

	getEmbedCode = () => {
		const { type, id } = this.props;

		return '<script type="text/javascript">'
			+ 'document.write(\'<iframe id="' + type + '_iframe_' + id + '" src="' + APP_URL + '/#/' + type + '/' + id + '?domain=\' + window.location.hostname + \'" style="width: 600px; height: ' + (type === 'post' ? 360 : 780) + 'px; border: 0px none; max-width: 100%;"></iframe>\');' 
			+ 'window.onmessage=function(e){if (e&&e.data){for(var i in e.data){if (i.toString().indexOf("iframe_height")!==-1){document.getElementById(i.toString().replace("_height","")).style.height=(e.data[i]+50)+"px";}}}};'
			+ '</script>'
		;
	}

	render() {
		const { showEmbed } = this.props;
		const { active } = this.state;

		if (showEmbed) {
			return <div className="embed-control">
				<div className="trigger" onClick={ this.onCopyId }>
					<Icon>assignment</Icon>
				</div>
				<div className="trigger" onClick={ this.onToggle }>
					<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="code" className="svg-inline--fa fa-code fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M228.5 511.8l-25-7.1c-3.2-.9-5-4.2-4.1-7.4L340.1 4.4c.9-3.2 4.2-5 7.4-4.1l25 7.1c3.2.9 5 4.2 4.1 7.4L235.9 507.6c-.9 3.2-4.3 5.1-7.4 4.2zm-75.6-125.3l18.5-20.9c1.9-2.1 1.6-5.3-.5-7.1L49.9 256l121-102.5c2.1-1.8 2.4-5 .5-7.1l-18.5-20.9c-1.8-2.1-5-2.3-7.1-.4L1.7 252.3c-2.3 2-2.3 5.5 0 7.5L145.8 387c2.1 1.8 5.3 1.6 7.1-.5zm277.3.4l144.1-127.2c2.3-2 2.3-5.5 0-7.5L430.2 125.1c-2.1-1.8-5.2-1.6-7.1.4l-18.5 20.9c-1.9 2.1-1.6 5.3.5 7.1l121 102.5-121 102.5c-2.1 1.8-2.4 5-.5 7.1l18.5 20.9c1.8 2.1 5 2.3 7.1.4z"></path></svg>
				</div>
				{
					active
					? <div className="code-source">
						<textarea className="code-source-input"></textarea>
					</div>
					: null
				}
			</div>
		}

		return null;
	}
}

EmbedControl.defaultProps = {
  showEmbed: false
};
