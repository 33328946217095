import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as weeksActions from '../actions/weeksActions';
import * as playersActions from '../actions/playersActions';
import LoadingWheel from '../components/LoadingWheel';
import ResearchScreen from '../screens/ResearchScreen';
import { getToken } from '../helpers/session';

class ResearchWrapper extends Component {
  	componentDidMount() {
      const authToken = getToken();

      if (!authToken) {
        this.props.history.push('/login');
      }
    }
    
    render() {
	    const { weeks, globalVars } = this.props;

	    if (weeks.length > 0 && globalVars.currentWeek)
	      return <ResearchScreen {...this.props} />
      
      return <LoadingWheel />
  	}
}

function mapStateToProps(state, action) {
  return {
    teams: state.teams,
    weeks: state.weeks,
    players: state.players,
    globalVars: state.globalVars
  }
}

function mapDispatchToProps(dispatch) {
  return {
  	weeksActions: bindActionCreators(weeksActions, dispatch),
  	playersActions: bindActionCreators(playersActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResearchWrapper);
