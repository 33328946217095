import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class LoadingWheel extends Component {
	render() {
		return 	<div className="loading-wheel">
					<CircularProgress size={40} />
				</div>
  	}
}
