import * as types from './actionTypes';
import axios from 'axios';
import { API_URL } from '../config.js';

export function receiveIndexes(indexes) {
	return { type: types.RECEIVE_INDEXES, indexes: indexes };
}

export function fetchIndexes(scheduleTimelineId) {
	return dispatch => {
    	return axios.post(API_URL + '/injuryIndex', { scheduleTimelineId }).then(response => dispatch(receiveIndexes(response.data.data.injuryIndexList)));
  	};
}
