import initialState from './initialState';
import { FETCH_WEEKS, RECEIVE_WEEKS } from '../actions/actionTypes';

export default function weeks(state = initialState.weeks, action) {
  let newState;

  switch (action.type) {
  	case FETCH_WEEKS:
      return action;
    
    case RECEIVE_WEEKS:
      newState = action.weeks;

      return newState;

    default:
      return state;
  }
}
