import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ModalOverlay from '../components/ModalOverlay';
import LoadingWheel from '../components/LoadingWheel';
import ErrorSnackbar from '../components/ErrorSnackbar';
import SuccessSnackbar from '../components/SuccessSnackbar';
import { getEmailValidationRegex } from '../helpers/global';
import { API_URL, MIN_PASSWORD_LENGTH } from '../config.js';

class ForgotPasswordScreen extends Component {
	constructor() {
		super();

		this.state = {
			form: {
				email: '',
				password: '',
				cpassword: ''
			},
			errors: {
				email: false,
				password: false,
				cpassword: false

			},
			token: '',
			loading: false,
			initialStep: true,
			successMessage: ''
		};
	}

	componentDidMount() {
		if (this.props.location.search.indexOf('?token') !== -1) {
			this.setState({
				initialStep: false,
				token: this.props.location.search.replace('?token=', '')
			});
			window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Page: Reset Password - /forgot');
			window.fbq('track', 'PageView', { title: 'Reset Password' });
		} else {
			window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Page: Forgot Password - /forgot');
			window.fbq('track', 'PageView', { title: 'Forgot Password' });
		}
	}

	updateFormModel = (e) => {
	    const { form, errors } = this.state;
	    const { target } = e;
	    const { name, value } = target;

	    form[name] = value;
	    errors[name] = false;

	    this.setState({
	        form: form,
	        errors: errors
	    });
  	}

  	onQuickSubmit = (e) => {
  		if (e.keyCode === 13) {
  			this.onSubmit();
  		}
  	}

  	onSubmit = (e) => {
  		const { form, errors, initialStep, token } = this.state;

  		if (initialStep && !getEmailValidationRegex().test(form.email)) {
  			this.setState({
  				errors: Object.assign(errors, { email: true })
  			});
		} else if (!initialStep && form.password.length < MIN_PASSWORD_LENGTH) {
			this.setState({
  				errors: Object.assign(errors, { password: true })
  			});
		} else if (!initialStep && form.password !== form.cpassword) {
			this.setState({
  				errors: Object.assign(errors, { cpassword: true })
  			});
  		} else {
  			this.setState({ loading: true });

  			if (initialStep) {
  				axios.post(API_URL + '/user/resetPasswordByEmail', form).then((response) => {
					if (response.data.status === 'error') {
						this.setState({
				  			errors: Object.assign(errors, { email: response.data.message }),
				  			loading: false
				  		});	
					} else {
						this.setState({ 
							loading: false,
							successMessage: 'For continuing resetting your password, please check your email address'
						});
					}
				}).catch((error) => {
					this.setState({
			  			errors: Object.assign(errors, { email: error.response.data.message }),
			  			loading: false
			  		});
				});
  			} else {
  				axios.post(API_URL + '/user/resetPassword/' + token, form).then((response) => {
					if (response.data.status === 'error') {
						this.setState({
				  			errors: Object.assign(errors, { email: response.data.message }),
				  			loading: false
				  		});	
					} else {
						this.setState({
							loading: false,
							form: {
								email: '',
								password: '',
								cpassword: ''
							}
						});

						this.props.history.push('/login?reset=true');
					}
				}).catch((error) => {
					this.setState({
			  			errors: Object.assign(errors, { email: error.response.data.message }),
			  			loading: false
			  		});
				});
  			}
  		}
  	}

	render() {
		const { form, errors, loading, initialStep, successMessage } = this.state;
		
		return (
		    <Container fixed className="main main-modal">
		    	{ loading ? <LoadingWheel /> : null }
		    	<ModalOverlay />
		    	<Box>
		    		 <Card className="modal">
				        <CardContent>
				          	<Typography gutterBottom variant="h6" component="h2" className="modal-title">
				            	Reset your password
				          	</Typography>
				          	<Typography variant="body2" color="textSecondary" component="p">
				          		Need an account? <Link to="/register">
				          			<Typography variant="body2" color="textSecondary" component="span">
				          				<strong>Register</strong>
			          				</Typography>
			          			</Link>
				          	</Typography>

				          	{
				          		initialStep
				          		? (<Box my={5}>
			  						<FormControl fullWidth={true}>
			    						<InputLabel>Email</InputLabel>
			    						<Input 
			    							type="email" 
			    							name="email" 
			    							value={form.email} 
			    							error={errors.email ? true : false} 
			    							onChange={this.updateFormModel} 
			    							onKeyUp={this.onQuickSubmit}
		    							/>
			  						</FormControl>
		  						</Box>)
				          		: (<Box my={5}>
			  						<Box mb={1}>
				  						<FormControl fullWidth={true}>
				    						<InputLabel>Enter New Password</InputLabel>
				    						<Input 
				    							type="password" 
				    							name="password" 
				    							value={form.password} 
				    							error={errors.password} 
				    							onChange={this.updateFormModel} 
				    							onKeyUp={this.onQuickSubmit}
			    							/>
				  						</FormControl>
			  						</Box>
			  						<Box>
				  						<FormControl fullWidth={true}>
				    						<InputLabel>Confirm New Password</InputLabel>
				    						<Input 
				    							type="password" 
				    							name="cpassword" 
				    							value={form.cpassword} 
				    							error={errors.cpassword} 
				    							onChange={this.updateFormModel} 
				    							onKeyUp={this.onQuickSubmit}
			    							/>
				  						</FormControl>
			  						</Box>
		  						</Box>)
				          	}

				          	<Link to="/login" className="no-underline">
				          		<Typography variant="body2" color="textSecondary" component="span">
				          			Back to login
			          			</Typography>
		          			</Link>
			        	</CardContent>
				      	<CardActions>
				      		<Box mx="auto" mr={0} px={2} py={1}>
						        <Button size="small" color="primary" onClick={this.onSubmit} disabled={loading}>
						          	Submit
						        </Button>
					        </Box>
				      	</CardActions>
				    </Card>
		    	</Box>

		    	{ errors.email && typeof errors.email === 'string' ? <ErrorSnackbar message={errors.email} /> : null }
		    	{ successMessage ? <SuccessSnackbar message={successMessage} /> : null }
		    </Container>
	  	);
	}
}

export default ForgotPasswordScreen;
