import React, { Component } from 'react';
import classNames from 'classnames';

export default class Tooltip extends Component {
	render() {
		const { text, className } = this.props;

		return 	<div className={classNames([ 'tooltip-holder', className ])}>
					<div className="tooltip">{ text }</div>
				</div>
  	}
}

Tooltip.defaultProps = {
  text: '',
  className: ''
};
