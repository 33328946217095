import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import CardActions from '@material-ui/core/CardActions';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import TwitterLogin from 'react-twitter-auth';
import ModalOverlay from '../components/ModalOverlay';
import LoadingWheel from '../components/LoadingWheel';
import ErrorSnackbar from '../components/ErrorSnackbar';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { getEmailValidationRegex } from '../helpers/global';
import { setToken } from '../helpers/session';
import { API_URL, MIN_PASSWORD_LENGTH, RECAPTCHA_KEY } from '../config.js';

class RegisterScreen extends Component {
	constructor() {
		super();

		this.state = {
			form: {
				email: '',
				password: '',
				cpassword: '',
				recaptchaToken: '',
				terms: false
			},
			errors: {
				email: false,
				password: false,
				cpassword: false,
				terms: false,
			},
			loading: false
		};
	}

	componentDidMount() {
		window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Page: Register - /register');
		window.fbq('track', 'PageView', { title: 'Register' });
		loadReCaptcha(RECAPTCHA_KEY);
		document.getElementsByTagName('body')[0].className = 'show-recaptcha';
	}

	componentWillUnmount() {
		const body = document.getElementsByTagName('body')[0];
		body.className = body.className.replace('show-recaptcha', '');
	}

	updateFormModel = (e) => {
	    const { form, errors } = this.state;
	    const { target } = e;
	    const { name, value, checked } = target;

	    form[name] = name === 'terms' ? checked : value;
	    errors[name] = false;

	    this.setState({
	        form: form,
	        errors: errors
	    });
  	}

  	onQuickSubmit = (e) => {
  		if (e.keyCode === 13) {
  			this.onSubmit();
  		}
  	}

  	onSubmit = async (e) => {
  		const { form, errors } = this.state;
  		let hasErrors = false;

  		for (let i in errors) {
  			errors[i] = false;
  		}

  		if (!getEmailValidationRegex().test(form.email)) {
  			errors.email = true;
  			hasErrors = true;
  		}
  		if (form.password.length < MIN_PASSWORD_LENGTH) {
  			errors.password = true;
  			hasErrors = true;	
  		}
  		if (form.password !== form.cpassword) {
  			errors.cpassword = true;
  			hasErrors = true;
  		}
  		if (!form.terms) {
  			errors.terms = true;
  			hasErrors = true;
  		}

  		if (hasErrors) {
  			this.setState({ errors });
  		} else {
  			this.setState({ loading: true });

			axios.post(API_URL + '/user/register', form).then(async (response) => {
				if (response.data.status === 'error') {
					errors.email = response.data.message;
					this.setState({ errors: errors, loading: false });
				} else {
					window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Register - Regular');
					window.fbq('track', 'CompleteRegistration');
					window.gtag('event', 'sign_up');
					setToken(response.data.token);
					this.props.onRefreshSession();
  					this.setState({ loading: false });
  					setTimeout(() => this.props.history.push('/live'), 2000);
				}
			}).catch((error) => {
				this.setState({
		  			errors: {
		  				email: error.response.data.message,
		  				password: false
		  			},
		  			loading: false
		  		});
			});
  		}
  	}

  	twitterOnFailure = (error) => {}

  	twitterOnSuccess = async (response) => {
  		const { errors } = this.state;

  		if (response.status !== 200) {
			errors.email = response.statusText;
			this.setState({ errors: errors });
		} else {
		    response.json().then(async (user) => {
		      if (user.token) {
		      	window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Register - Twitter');
		      	window.fbq('track', 'CompleteRegistration');
		        setToken(user.token);
		        this.setState({ loading: true });
		        this.props.onRefreshSession();
				this.setState({ loading: false });
				window.gtag('event', 'sign_up');
				setTimeout(() => this.props.history.push('/live'), 2000);
		      } else {
		      	alert('Token is missing');
		      }
		    });
		}
  	}

  	verifyCallback = (recaptchaToken) => {
		const { form } = this.state;
		form.recaptchaToken = recaptchaToken;

  		this.setState({ form });
  	}

  	render() {
		const { form, errors, loading } = this.state;

		return (
		    <Container fixed className="main main-modal">
		    	{ loading ? <LoadingWheel /> : null }
		    	<ModalOverlay />
		    	<Box>
		    		 <Card className="modal">
				        <CardContent>
				          	<Typography gutterBottom variant="h6" component="h2" className="modal-title">
				            	Sign up for Pro Football Doc
				          	</Typography>
				          	<Typography variant="body2" color="textSecondary" component="p">
				          		Already have an account? <Link to="/login">
				          			<Typography variant="body2" color="textSecondary" component="span">
				          				<strong>Log in</strong>
			          				</Typography>
			          			</Link>
				          	</Typography>
				          	<Box mx="auto" mt={6} mb={1.5} align="center">
          						<TwitterLogin 
          							loginUrl={ API_URL + '/auth/register/twitter' } 
          							className="twitter-button"
			                      	onFailure={this.twitterOnFailure} 
			                      	onSuccess={this.twitterOnSuccess}
			                        showIcon={false}
			                        text="Log in with Twitter"
			                      	requestTokenUrl={ API_URL + '/auth/twitter/reverse' }
		                      	/>
			          		</Box>
			          		<Box mb={1}>
			  					<FormControl fullWidth={true}>
			    					<InputLabel>Email</InputLabel>
			    					<Input 
			    						type="email" 
			    						name="email" 
			    						value={form.email} 
			    						error={errors.email ? true : false} 
			    						onChange={this.updateFormModel} 
			    						onKeyUp={this.onQuickSubmit}
		    						/>
			  					</FormControl>
		  					</Box>
		  					<Box mb={1}>
			  					<FormControl fullWidth={true}>
			    					<InputLabel>Password</InputLabel>
			    					<Input 
			    						type="password" 
			    						name="password" 
			    						value={form.password} 
			    						error={errors.password} 
			    						onChange={this.updateFormModel} 
			    						onKeyUp={this.onQuickSubmit}
		    						/>
			  					</FormControl>
		  					</Box>
		  					<Box mb={3}>
			  					<FormControl fullWidth={true}>
			    					<InputLabel>Confirm Password</InputLabel>
			    					<Input 
			    						type="password" 
			    						name="cpassword" 
			    						value={form.cpassword} 
			    						error={errors.cpassword} 
			    						onChange={this.updateFormModel} 
			    						onKeyUp={this.onQuickSubmit}
		    						/>
			  					</FormControl>
		  					</Box>
		  					<Box mb={3}>
		  						<ReCaptcha sitekey={ RECAPTCHA_KEY } action='action_name' verifyCallback={this.verifyCallback} />
		  					</Box>
							<FormGroup row className="terms-row">
		      					<FormControl error={errors.terms} component="fieldset">
		          					<Checkbox checked={form.terms} color="primary" name="terms" onChange={this.updateFormModel} />
					        		{
					        			errors.terms
					        			? <FormHelperText className="error-message">You must accept our terms of service</FormHelperText>
					        			: null
				        			}
						      	</FormControl>
		      					<InputLabel>
		      						<Typography variant="body2" color="textSecondary" component="span">
			      						I have read and agree to Pro Football Doc’s <a href="https://www.profootballdoc.com/terms-of-service" rel="noopener noreferrer" target="_blank">
			      							<Typography variant="body2" color="textSecondary" component="span">
			      								Terms of Service
		      								</Typography>
		      							</a> and <a href="https://www.profootballdoc.com/privacy" rel="noopener noreferrer" target="_blank">
		      								<Typography variant="body2" color="textSecondary" component="span">
		      									Privacy Policy
		  									</Typography>
		  								</a>
		      						</Typography>
		      					</InputLabel>
		    				</FormGroup>
			        	</CardContent>
				      	<CardActions className="submit-row">
				      		<Box mx="auto" mr={0} py={1}>
					      		<Link to="/" className="no-underline">
							        <Button size="small" color="primary">
							          	CANCEL
							        </Button>
						        </Link>
						        <Button size="small" color="primary" onClick={this.onSubmit} disabled={loading}>
						          	SIGN UP
						        </Button>
					        </Box>
				      	</CardActions>
				    </Card>
		    	</Box>

		    	{ errors.email && typeof errors.email === 'string' ? <ErrorSnackbar message={errors.email} /> : null }
		    </Container>
	  	);
  	}
}

function mapStateToProps(state, action) {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterScreen);
