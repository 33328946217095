import initialState from './initialState';
import { FETCH_TEAMS, RECEIVE_TEAMS } from '../actions/actionTypes';

export default function teams(state = initialState.teams, action) {
  let newState;

  switch (action.type) {
  	case FETCH_TEAMS:
      return action;

    case RECEIVE_TEAMS:
      newState = action.teams;

      return newState;

    default:
      return state;
  }
}
