import initialState from './initialState';
import { FETCH_INDEXES, RECEIVE_INDEXES } from '../actions/actionTypes';

export default function indexes(state = initialState.indexes, action) {
  let newState;

  switch (action.type) {
  	case FETCH_INDEXES:
	  return action;

    case RECEIVE_INDEXES:
      newState = action.indexes;

      return newState;

    default:
      return state;
  }
}
