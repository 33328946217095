import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Switch from '@material-ui/core/Switch';
import ModalOverlay from '../components/ModalOverlay';
import LoadingWheel from '../components/LoadingWheel';
import ErrorSnackbar from '../components/ErrorSnackbar';
import SuccessSnackbar from '../components/SuccessSnackbar';
import TwitterLogin from 'react-twitter-auth';
import { getEmailValidationRegex } from '../helpers/global';
import { setToken, setRefreshToken, setAdmin } from '../helpers/session';
import { API_URL } from '../config.js';

class LoginScreen extends Component {
	constructor() {
		super();

		this.state = {
			form: {
				email: '',
				password: '',
				remember: true
			},
			errors: {
				email: false,
				password: false
			},
			loading: false,
			successMessage: false
		};
	}

	componentDidMount() {
		if (this.props.location.search === '?reset=true') {
			this.setState({ successMessage: 'Password is updated successfully.' });
			setTimeout(() => this.setState({ successMessage: '' }), 3000);
		}

		window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Page: Login - /login');
		window.fbq('track', 'PageView', { title: 'Login' });
	}

	updateFormModel = (e) => {
	    const { form, errors } = this.state;
	    const { target } = e;
	    const { name, value, checked } = target;

	    form[name] = name === 'remember' ? checked : value;
	    errors[name] = false;

	    this.setState({
	        form: form,
	        errors: errors
	    });
  	}

  	onSubmit = (e) => {
  		e.preventDefault();
  		const { form } = this.state;

  		if (!getEmailValidationRegex().test(form.email)) {
	        this.setState({
	  			errors: {
	  				email: true,
	  				password: false
	  			}
	  		});
	    } else {
	    	this.setState({
	  			errors: {
	  				email: false,
	  				password: false
	  			},
	  			loading: true
	  		});

			axios.post(API_URL + '/auth/authenticate', form).then((response) => {
				if (response.data.status === 'error') {
					this.setState({
			  			errors: {
			  				email: response.data.message,
			  				password: false
			  			},
			  			loading: false
			  		});	
				} else {
					window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Login - Regular');
					setToken(response.data.token);
					setRefreshToken(form.remember ? response.data.refreshToken : null);
					setAdmin(response.data.data.user.roleName === 'Admin');
					this.props.onRefreshSession();
					setTimeout(() => this.props.history.push('/live'), 2000);
				}
			}).catch((error) => {
				console.log('this is the error body', JSON.stringify(error) + ' - end');

				this.setState({
		  			errors: {
		  				email: error.response ? error.response.data.message : 'Login failed. Please reset your password.',
		  				password: false
		  			},
		  			loading: false
		  		});
			});
	    }
  	}

  	twitterOnFailure = (error) => {
  		console.log('There was an error: ' + JSON.stringify(error) + ' - end');

  		this.setState({
  			errors: {
  				email: error.response ? error.response.data.message : 'Login failed. Please reset your password.',
  				password: false
  			},
  			loading: false
  		});
  	}

  	twitterOnSuccess = async (response) => {
  		const { form, errors } = this.state;

  		if (response.status !== 200) {
			errors.email = response.statusText;
			this.setState({ errors: errors });
		} else {
		    response.json().then(async (user) => {
		      	if (user.token) {
		      		window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Login - Twitter');
		        	setToken(user.token);
		        	setRefreshToken(form.remember ? user.refreshToken : null);
		        	setAdmin(user.data.user.roleName === 'Admin');
		        	this.props.onRefreshSession();
					setTimeout(() => this.props.history.push('/live'), 2000);
		      	} else {
		      		alert('An error occurred while authenticating');
		      	}
		    });
		}
  	}

	render() {
		const { form, errors, loading, successMessage } = this.state;

		return (
		    <Container fixed className="main main-modal">
		    	{ loading ? <LoadingWheel /> : null }
		    	<ModalOverlay />
		    	<Box>
		    		 <Card className="modal">
				        <CardContent>
				          	<Typography gutterBottom variant="h6" component="h2" className="modal-title">
				            	Log in to Pro Football Doc
				          	</Typography>
				          	<Typography variant="body2" color="textSecondary" component="p">
				          		Need an account? <Link to="/register">
				          			<Typography variant="body2" color="textSecondary" component="span">
				          				<strong>Register</strong>
			          				</Typography>
			          			</Link>
				          	</Typography>
				          	<Box mx="auto" mt={6} mb={1.5} align="center">
				          		<TwitterLogin 
				          			loginUrl={ API_URL + '/auth/authenticate/twitter' } 
				          			className="twitter-button"
                      				onFailure={this.twitterOnFailure} 
                      				onSuccess={this.twitterOnSuccess}
                      				showIcon={false}
			                        text="Log in with Twitter"
                      				requestTokenUrl={ API_URL + '/auth/twitter/reverse' }
                  				/>
			          		</Box>
		  					<form onSubmit={this.onSubmit}>
			  					<Box mb={1}>
				  					<FormControl fullWidth={true}>
				    					<InputLabel>Email</InputLabel>
				    					<Input 
				    						type="email" 
				    						name="email" 
				    						value={form.email} 
				    						error={errors.email ? true : false} 
				    						onChange={this.updateFormModel} 
			    						/>
				  					</FormControl>
			  					</Box>
			  					<FormControl fullWidth={true}>
			    					<InputLabel>Password</InputLabel>
			    					<Input 
			    						type="password" 
			    						name="password" 
			    						value={form.password} 
			    						error={errors.password} 
			    						onChange={this.updateFormModel} 
		    						/>
			  					</FormControl>
								<Box mt={2.5} mb={4}>
									<Typography variant="body2" color="textSecondary" component="span">
				          				Remember me
			          				</Typography>
			      					<Switch checked={form.remember} color="primary" name="remember" onChange={this.updateFormModel} />
			    				</Box>
				          		<Link to="/forgot" className="no-underline">
					          		<Typography variant="body2" color="textSecondary" component="span" className="forgot-password">
					          			Forgot password?
				          			</Typography>
			          			</Link>
			          			<Box className="submit-row" align="right" mx="auto" mr={0} py={1}>
							        <Button size="small" color="primary" type="submit" disabled={loading}>
							          	Log In
							        </Button>
						        </Box>
					        </form>
			        	</CardContent>
				    </Card>
		    	</Box>

      			{ errors.email && typeof errors.email === 'string' ? <ErrorSnackbar message={errors.email} /> : null }
      			{ successMessage ? <SuccessSnackbar message={successMessage} /> : null }
		    </Container>
	  	);
	}
}

export default LoginScreen;
