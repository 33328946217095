import { combineReducers } from 'redux';
import subscriptionTypes from './subscriptionTypesReducer';
import registerForm from './registerFormReducer';
import globalVars from './globalVarsReducer';
import indexes from './indexesReducer';
import players from './playersReducer';
import teams from './teamsReducer';
import weeks from './weeksReducer';

const rootReducer = combineReducers({
	subscriptionTypes,
	registerForm,
	globalVars,
	indexes,
	players,
	teams,
	weeks
});

export default rootReducer;
