import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

export default class SuccessSnackbar extends Component {
	render() {
		const { message } = this.props;

		return<Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={true} className="success" message={ message }></Snackbar>
  	}
}

SuccessSnackbar.propTypes = {
  message: PropTypes.string.isRequired
}
