import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LoadingWheel from '../components/LoadingWheel';
import SuccessSnackbar from '../components/SuccessSnackbar';
import UpdatesListing from '../components/UpdatesListing';
import { getToken, getSeason } from '../helpers/session';
import { resizeIframe } from '../helpers/global';
import { API_URL } from '../config.js';

class FantasyValuesScreen extends Component {
  constructor() {
    super();

    this.state = {
      updates: [],
      loading: true,
      successMessage: false,
      updatesPerPage: 10,
      currentUpdatesPage: 1,
      uploadsLoadedEntirely: false
    };
  }

  componentDidMount() {
    const { currentUpdatesPage } = this.state;
    const authToken = getToken();

    if (!authToken) {
      	this.props.history.push('/login');
    } else {
    	this.loadUpdates(currentUpdatesPage, true);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshIntervalId);
    window.removeEventListener('resize', this.handleIframesResize);
  }

  handleIframesResize() {
    const column = document.querySelector('.vimeo_column');
    if (column) {
      const iframes = column.getElementsByTagName('iframe');
      for (let i in iframes) {
        resizeIframe(iframes[i]);
      }
    }
  }

  loadUpdates = (page, initialLoad) => {
    const { currentUpdatesPage, updatesPerPage } = this.state;

    if (initialLoad) {
      this.setState({ updates: [] });
    }

    axios.get(API_URL + '/post?type=fantasyValues&perPage=' + updatesPerPage + '&pageNumber=' + currentUpdatesPage + '&season=' + getSeason()).then(response => {
      const { updates } = this.state;
      const newUpdates = response.data.data ? response.data.data.postList : [];

      this.setState({
        uploadsLoadedEntirely: newUpdates.length < updatesPerPage,
        updates: updates.concat(newUpdates),
        loading: false
      });
      
      if (initialLoad) {
        const holder = document.querySelector('.col-scroller-wrapper-updates');
        const wrapper = document.querySelector('.col-scroller-inner-updates');
        
        // for laptops
        holder.onscroll = () => {
          const { loading, currentUpdatesPage, uploadsLoadedEntirely } = this.state;

          if (!loading && !uploadsLoadedEntirely) {
            const scrollTop = holder.scrollTop;
            const outerHeight = holder.offsetHeight;
            const innerHeight = wrapper.offsetHeight;

            if (scrollTop + outerHeight > innerHeight - 150) {
              this.setState({ loading: true, currentUpdatesPage: currentUpdatesPage + 1 });
              this.loadUpdates(currentUpdatesPage + 1, false);
            }
          }
        }

        // for mobiles
        window.onscroll = () => {
          const { loading, currentUpdatesPage, uploadsLoadedEntirely } = this.state;
          
          if (!loading && !uploadsLoadedEntirely) {
            const scrollTop = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
            const outerHeight = window.innerHeight;
            const innerHeight = Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );

            if (scrollTop + outerHeight > innerHeight - 150) {
              console.log('load mobile');
              this.setState({ loading: true, currentUpdatesPage: currentUpdatesPage + 1 });
              this.loadUpdates(currentUpdatesPage + 1, false);
            }
          }
        }
      }
    });
  }

  onReload = () => {
    this.setState({ currentUpdatesPage: 1, updates: [], loading: true });
    this.loadUpdates(1, true);
  }

  render() {
    const { 
      updates,
      loading, 
      successMessage
    } = this.state;
    
    return (
      <Box>
        <Grid container className="main">

          <Grid container className="injury-index-header">
              <div>
                <h2 className="screen-title">FANTASY ALERTS</h2>
                <br/>
              </div>
            </Grid>

          <Grid item xs={12} className="updates_column">
            <UpdatesListing 
              updates={updates} 
              onLoad={this.onReload} 
              stickyTitle={false} 
              titleBarText="&nbsp;" 
            />
          </Grid>
      	</Grid>

        { loading ? <LoadingWheel /> : null }
        { successMessage ? <SuccessSnackbar message={successMessage} /> : null }
      </Box>
    );
  }
}

function mapStateToProps(state, action) {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FantasyValuesScreen);
