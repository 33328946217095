import React, { Component } from 'react';
import DepthChartPosition from './DepthChartPosition';

export default class GamePositions extends Component {
	renderDepthChartPosition(row, column) {
		const { side, team, positions } = this.props;
		const position = positions.find(pos => pos.slot === (row + ',' + column));

		return <DepthChartPosition row={row} column={column} position={position} side={side} team={team} onSave={ this.props.onSave }></DepthChartPosition>
	}

	render() {
		const slotsRows = [0,1,2];
		const slotsColumns = [0,1,2,3,4,5,6,7,8];
		const { state } = this.props;

		return 	<div className="game-team-positions">
      				{
      					slotsRows.map(row => {
	          				return <div key={ row + '_' + state } className="slots-row clearfix">
	          					{
		          					slotsColumns.map(column => {
				          				return <div key={ column + '_' + state } className={ 'slots-column slots-column-' + column }>
				          					{ 
				          						this.renderDepthChartPosition(row, column)
				          					}
				          				</div>
					          		})
		          				}
	          				</div>
		          		})	
      				}
          		</div>
	}
}

GamePositions.defaultProps = {
	positions: [],
  	side: 'defense',
  	team: 'home',
  	state: 0
};
