import React, { Component } from 'react';
import { connect } from 'react-redux';

class HeaderSearchSuggestions extends Component {
  	render() {
	    const { search } = this.props;
      const searchPieces = search.split(' ');
      const allPlayers = this.props.players;
      let players = [];

      for (let i in allPlayers) {
        const player = allPlayers[i];
        let isMatched = true;

        if (player.Player.name) {
          for (let j in searchPieces) {
            if (typeof searchPieces[j] === 'string' && searchPieces[j].trim() !== '' && player.Player.name.toLowerCase().indexOf(searchPieces[j].toLowerCase()) === -1) {
              isMatched = false;
            }
          }
        } else {
          isMatched = false;
        }

        if (isMatched)
          players.push(player);
      }

      if (players.length > 100)
        players = players.slice(0,100);

	    return (
        <div className="search-suggestions">
          {players.map(player => {
            return <div className="prevent-search-close" onClick={ () => this.props.onSelect(player.Player) } key={ player._id }>{ player.Player.name }</div>
          })}
        </div>
      );
  	}
}

function mapStateToProps(state, action) {
  return {
    players: state.players
  }
}
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderSearchSuggestions);
