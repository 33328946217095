import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import NativeSelect from '@material-ui/core/NativeSelect';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GamePositions from '../components/GamePositions';
import DateTimePicker from '../components/DateTimePicker';
import LoadingWheel from '../components/LoadingWheel';
import ErrorSnackbar from '../components/ErrorSnackbar';
import SuccessSnackbar from '../components/SuccessSnackbar';
import EmbedControl from './EmbedControl';
import NotifyControl from './NotifyControl';
import * as playersActions from  '../actions/playersActions';
import { isAdmin } from '../helpers/session';
import { prepareEmbeds } from '../helpers/global';
import { DATE_TIME_FORMAT, API_URL } from '../config.js';

class UpdatesListing extends Component {
  constructor() {
    super();

    this.state = {
      games: [],
      showArticleDialog: false,
      showArticleDialogShare: false,
      showDatePicker: false,
      showFeatureDurationDatePicker: false,
      newArticle: {
        title: '',
        body: '',
        type: 'docLiveUpdate',
        season: 2021,
        scheduleTimelineId: '',
        teams: [],
        players: [],
        games: [],
        date: moment(),
        fieldView: '',
        imageId: null,
        imageSource: null,
        showFieldView: 1,
        status: 'draft',
        accessibility: 'public',
        isFeatured: 0,
        featuredDuration: moment(),
        video: '',
        showFieldViewGameId: ''
      },
      newArticleErrors: {
        title: false,
        body: false,
        scheduleTimelineId: false
      },
      newArticleSearch: '',
      newArticleSearchExpanded: false,
      newArticleError: '',
      expandedArticle: '',
      editMode: false,
      loading: false,
      successMessage: false,
      sides: [],
      weeks: []
    };
  }

  componentDidMount() {
    if (isAdmin()) {
      axios.get(API_URL + '/injuryIndex/getGames').then(response => this.setState({ games: response.data.data.gamesList }));
      axios.get(API_URL + '/playerSide').then(response => this.setState({sides: response.data.data.PlayerSides}));
    }
  }

  loadWeeks = season => {
    this.setState({ weeks: [] });

    axios.get(API_URL + '/scheduleTimeline/season/' + season).then(response => this.setState({weeks: response.data.data.scheduleTimelines}));    
  }

  updateFormModel = (e, editor) => {
      const { newArticle, newArticleErrors } = this.state;
      const name = typeof e.target !== 'undefined' ? e.target.name : 'body';
      const value = typeof e.target !== 'undefined' ? e.target.value : editor.getData();

      newArticle[name] = value;
      newArticleErrors[name] = false;

      if (name === 'season') {
        this.loadWeeks(value);
        newArticle.scheduleTimelineId = '';
      }

      this.setState({ 
        newArticle: newArticle,
        newArticleErrors: newArticleErrors 
      });
    }

  handleClose = () => {
    this.setState({ showArticleDialog: false });
  }

  handleShareArticle = (id, isEmbedded) => {
    axios.put(API_URL + '/post/updateEmbedded/' + id, { isEmbedded }).then(response => {
      this.props.onLoad();
    });
  }

  handleCreateArticle = () => {
    const { newArticle, newArticleErrors, showArticleDialogShare } = this.state;
    const { globalVars } = this.props;
    let hasErrors = false;

    for (let i in newArticleErrors) {
      newArticleErrors[i] = false;
    }

    if (newArticle.title.trim() === '') {
      newArticleErrors.title = true;
      hasErrors = true;
    }

    if (newArticle.body.trim() === '') {
      newArticleErrors.body = true;
      hasErrors = true;
    }

    if (newArticle.scheduleTimelineId.trim() === '') {
      newArticleErrors.scheduleTimelineId = true;
      hasErrors = true;
    }

    if (hasErrors) {
      this.setState({ newArticleErrors });
    } else {
      this.setState({ loading: true });

      const data = {
        title: newArticle.title,
        description: newArticle.body,
        datetime: newArticle.date.format(),
        teams: newArticle.teams,
        players: newArticle.players,
        games: newArticle.games,
        type: newArticle.type,
        season: newArticle.season,
        scheduleTimelineId: newArticle.scheduleTimelineId,
        isFeatured: newArticle.isFeatured ? 'Yes' : 'No' ? 1 : 0,
        featuredDuration: newArticle.featuredDuration.format(),
        video: newArticle.video,
        // showFieldView: {
        //   gameId: newArticle.showFieldViewGameId,
        //   isFieldView: newArticle.showFieldView
        // },
        showFieldView: newArticle.showFieldView,
        status: newArticle.status,
        accessibility: newArticle.accessibility
      };

      if (newArticle.imageId) {
        data.postImageId = newArticle.imageId
      }

      if (newArticle.fieldView) {
        data.teamPost = {
          teamId: newArticle.fieldView.split('_')[0],
          side: newArticle.fieldView.split('_')[1]
        }
      }
    
      axios.post(API_URL + '/post/create', data).then(response => { 
        this.setState({
          showArticleDialog: false,
          newArticle: {
            title: '',
            type: 'docLiveUpdate',
            body: '',
            teams: [],
            players: [],
            games: [],
            date: moment(),
            fieldView: '',
            imageId: null,
            imageSource: null,
            season: 2021,
            scheduleTimelineId: globalVars.currentWeek ? globalVars.currentWeek._id : '',
            isFeatured: 0,
            featuredDuration: moment(),
            video: ''
          },
          loading: false
        });

        this.handleShareArticle(response.data.data._id, showArticleDialogShare);
      }).catch(error => {
        this.setState({
          newArticleError: error.response.data.message,
          loading: false
        });

        setTimeout(() => this.setState({ newArticleError: '' }), 3000);
      });
    }
  }

  handleReadArticle = article => {
    const season = article.season ? article.season : 2021;
    this.loadWeeks(season);

    this.setState({
      showArticleDialog: true,
      showArticleDialogShare: article.isEmbedded,
      editMode: article._id,
      newArticleSearchExpanded: false,
      newArticle: {
        title: article.title,
        type: article.type,
        season: season,
        scheduleTimelineId: article.scheduleTimelineId ? article.scheduleTimelineId : '',
        body: article.description,
        teams: article.teams,
        players: article.players,
        games: article.games ? article.games : [],
        date: moment(article.datetime),
        fieldView: article.teamPost ? (article.teamPost.teamId.id + '_' + article.teamPost.side._id) : '',
        imageId: article.postImageId ? article.postImageId : null,
        imageSource: article.postImage && article.postImage.small ? article.postImage.small : null,
        showFieldView: article.showFieldView ? 1 : 0,
        status: article.status,
        accessibility: article.accessibility,
        isFeatured: article.isFeatured === 'Yes' ? 1 : 0,
        featuredDuration: article.featuredDuration ? moment(article.featuredDuration) : moment(),
        video: article.video
      }
    });

    setTimeout(() => this.onUpdateNewArticleSearch());
  }

  onSelectImage = async event => {
    const { target } = event;

    if (target.value && target.files.length > 0) {
      const image = target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ newArticle: Object.assign(this.state.newArticle, { imageSource: reader.result }) });
      };
      reader.readAsDataURL(image);

      this.setState({ loading: true });

      const formData = new FormData();
      formData.append('postImage', image);

      const { data } = await axios({
        method: 'post',
        url: API_URL + '/post/imageUpload?type=POST',
        data: formData,
        config: { 
          headers: {
            'Content-Type': 'multipart/form-data', 
            'Accept':'application/json'
          } 
        }
      });

      this.setState({ 
        newArticle: Object.assign(this.state.newArticle, { imageId: data.data }),
        loading: false
      });
      target.value = '';
    }
  }

  handleUpdateArticle = () => {
    const { newArticle, newArticleErrors, editMode, showArticleDialogShare } = this.state;
    let hasErrors = false;

    for (let i in newArticleErrors) {
      newArticleErrors[i] = false;
    }

    if (newArticle.title.trim() === '') {
      newArticleErrors.title = true;
      hasErrors = true;
    }

    if (newArticle.body.trim() === '') {
      newArticleErrors.body = true;
      hasErrors = true;
    }

    if (newArticle.scheduleTimelineId.trim() === '') {
      newArticleErrors.scheduleTimelineId = true;
      hasErrors = true;
    }

    if (hasErrors) {
      this.setState({ newArticleErrors });
    } else {
      this.setState({ loading: true });

      const data = {
        title: newArticle.title,
        description: newArticle.body,
        datetime: newArticle.date.format(),
        teams: newArticle.teams,
        players: newArticle.players,
        games: newArticle.games,
        type: newArticle.type,
        season: newArticle.season,
        scheduleTimelineId: newArticle.scheduleTimelineId,
        showFieldView: newArticle.showFieldView > 0,
        status: newArticle.status,
        accessibility: newArticle.accessibility,
        isFeatured: newArticle.isFeatured ? 'Yes' : 'No',
        featuredDuration: newArticle.featuredDuration.format(),
        video: newArticle.video,
        // showFieldView: {
        //   gameId: newArticle.showFieldViewGameId,
        //   isFieldView: newArticle.showFieldView
        // }
      };

      if (newArticle.imageId) {
        data.postImageId = newArticle.imageId
      }

      if (newArticle.fieldView) {
        data.teamPost = {
          teamId: newArticle.fieldView.split('_')[0],
          side: newArticle.fieldView.split('_')[1]
        }
      }
    
      axios.put(API_URL + '/post/update/' + editMode, data).then(response => { 
        this.props.onLoad();
        this.setState({
          showArticleDialog: false,
          newArticle: {
            title: '',
            body: '',
            teams: [],
            players: [],
            games: [],
            date: moment(),
            fieldView: '',
            imageId: null,
            imageSource: null,
            showFieldView: 1,
            status: 'draft',
            accessibility: 'public',
            isFeatured: 0,
            featuredDuration: moment(),
            video: ''
          },
          loading: false
        });

        this.handleShareArticle(response.data.data._id, showArticleDialogShare);
      }).catch(error => {
        this.setState({
          newArticleError: error.response.data.message,
          loading: false
        });

        setTimeout(() => this.setState({ newArticleError: '' }), 3000);
      });
    }
  }

  handleDeleteArticle = (id) => {
    if (window.confirm('Are you sure?')) {
      this.setState({ loading: true });
      axios.delete(API_URL + '/post/delete/' + id).then(r => {
      	this.setState({ loading: false });
      	this.props.onLoad();
      });
    }
  }

  onUpdateNewArticleSearch = e => {
  	const value = e ? e.target.value : '';
    this.setState({newArticleSearch: value});
  }

  onUpdatePlayerHealth = (playerWeekDataId, health) => {
    const { players, globalVars } = this.props;
    const playerWeekData = players.find(player => player._id === playerWeekDataId);

    this.setState({ loading: true });
    if (playerWeekData) {
      axios.put(API_URL + '/playerWeeklyData/update/' + playerWeekDataId, { 
        health: health,
        playerName: playerWeekData.Player.name,
        teamId: playerWeekData.Team._id,
        side: playerWeekData.Side._id,
        position: playerWeekData.Position._id,
        status: playerWeekData.status,
        scheduleTimelineId: globalVars.currentWeek._id
       }).then(response => { 
        this.props.playersActions.fetchPlayers(globalVars.currentWeek._id).then(() => this.setState({ loading: false }));
      });
    }
  }

  render() {
    const { 
      showDatePicker, 
      showArticleDialog, 
      showArticleDialogShare,
      showFeatureDurationDatePicker,
      newArticle, 
      newArticleSearch,
      newArticleSearchExpanded,
      newArticleErrors, 
      newArticleError, 
      loading, 
      editMode,
      successMessage,
      expandedArticle,
      games,
      sides,
      notificationObject,
      weeks
    } = this.state;
    
    const {
      teams,
      updates, 
      onClose,
      hideTitleBar, 
      titleBarText, 
      stickyTitle,
      allowFieldView,
      displayCloseButton,
      globalVars
    } = this.props;
    
    const tagsForDisplay = [];
    const alreadyDisplayedUpdates = [];
    const searchPieces = newArticleSearch.split(' ');
    const allPlayers = this.props.players;
    const { hash } = window.location;
    let players = [];

    for (let i in allPlayers) {
      const player = allPlayers[i];
      let isMatched = true;

      if (player.Player && player.Player.name) {
        for (let j in searchPieces) {
          if (typeof searchPieces[j] === 'string' && searchPieces[j].trim() !== '' && player.Player.name.toLowerCase().indexOf(searchPieces[j].toLowerCase()) === -1) {
            isMatched = false;
          }
        }
      } else {
        isMatched = false;
      }

      if (isMatched) {
        players.push(player);
      }
    }

    if (players.length > 50) {
      players = players.slice(0,50);
    }

    for (let i in newArticle.players) {
      const selectedPlayerId = newArticle.players[i];
      if (!(players.find(player => player.Player._id === selectedPlayerId))) {
        players = players.concat(allPlayers.filter(player => player.Player && player.Player._id === selectedPlayerId));
      }
    }
    
    if (teams.length > 0) {
      for (let i in newArticle.teams) {
        const matchedTeam = teams.find(team => team._id === newArticle.teams[i]);
        if (matchedTeam) {
          tagsForDisplay.push(matchedTeam.name);
        }
      }
    }
    if (players.length > 0) {
      for (let i in newArticle.players) {
        const matchedPlayer = players.find(player => player._id === newArticle.players[i]);
        if (matchedPlayer) {
          tagsForDisplay.push(matchedPlayer.name);
        }
      }
    }
    if (games.length > 0) {
      for (let i in newArticle.games) {
        const matchedGame = games.find(game => game._id === newArticle.games[i]);
        if (matchedGame) {
          tagsForDisplay.push(matchedGame.formattedValue);
        }
      }
    }

    return (
      <Box className="updates_listing">
      	{
      		hideTitleBar 
      		? null
      		: (<Box className={classNames(['col-title', stickyTitle ? 'is-sticky' : ''])}>
	          <Typography component="h4" gutterBottom>
	            <strong>{ titleBarText }</strong>
	            {
	              isAdmin()
	              ? <span className="add-more" onClick={ () => {
                    this.loadWeeks(2021);
                    this.setState({ 
                      newArticleSearchExpanded: false, 
                      showArticleDialogShare: false, 
                      showArticleDialog: !showArticleDialog, 
                      editMode: false, 
                      newArticle: { 
                        title: '', 
                        body: '', 
                        type: hash.indexOf('quick-hits') !== -1 ? 'teamUpdate' : (hash.indexOf('early-line-alerts') !== -1 ? 'earlyLineAlerts' : (hash.indexOf('fantasy-values') !== -1 ? 'fantasyValues' : 'docLiveUpdate')), 
                        season: 2021, 
                        scheduleTimelineId: globalVars.currentWeek ? globalVars.currentWeek._id : '', 
                        date: moment(), 
                        teams: [], 
                        players: [], 
                        games: [], 
                        fieldView: '', 
                        showFieldView: 1, 
                        status: 'draft',
                        accessibility: 'public',
                        isFeatured: 0, 
                        featuredDuration: moment(),
                        video: ''
                      }}); 
                      setTimeout(() => this.onUpdateNewArticleSearch()); 
                }}>
	                <Icon>{ showArticleDialog ? 'close' : 'add' }</Icon>
	              </span>
	              : null
	            }
              {
                displayCloseButton
                ? <IconButton onClick={ onClose } className="close-button">
                  <Icon>close</Icon>
                </IconButton>
                : null
              }
	          </Typography>
	        </Box>)
      	}
        {
          showArticleDialog
          ? (<Box className="update-box update-box-editable">
            <Card>
              <CardContent>
                <Typography component="h4" gutterBottom>
                  <strong>{ editMode ? 'Edit Update' : 'New Update' }</strong>
                </Typography>
                <Box mb={2.5}>
                  <FormControl fullWidth={true}>
                    <InputLabel>Title</InputLabel>
                    <Input 
                      type="text" 
                      name="title" 
                      value={newArticle.title} 
                      error={newArticleErrors.title} 
                      onChange={this.updateFormModel} 
                      autoComplete="off"
                    />
                  </FormControl>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Box>
                      <InputLabel>Type</InputLabel>
                      <FormControl fullWidth={true}>
                        <NativeSelect value={ newArticle.type } name="type" fullWidth onChange={this.updateFormModel}>
                            <option value="docLiveUpdate">Doc Live Update</option>
                            <option value="teamUpdate">Team Update</option>
                            <option value="fantasyValues">Fantasy Values</option>
                            <option value="earlyLineAlerts">Early Line Alerts</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>
                  {/*
                  <Grid item xs={4}>
                    <Box>
                      <InputLabel>Show Field View</InputLabel>
                      <FormControl fullWidth={true}>
                        <NativeSelect value={ newArticle.showFieldView } name="showFieldView" fullWidth onChange={this.updateFormModel}>
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>
                  */}
                  <Grid item xs={4}>
                    <Box>
                      <InputLabel>Status</InputLabel>
                      <FormControl fullWidth={true}>
                        <NativeSelect value={ newArticle.status } name="status" fullWidth onChange={this.updateFormModel}>
                            <option value="draft">Draft</option>
                            <option value="published">Published</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <InputLabel>Accessibility</InputLabel>
                      <FormControl fullWidth={true}>
                        <NativeSelect value={ newArticle.accessibility } name="accessibility" fullWidth onChange={this.updateFormModel}>
                            <option value="public">Public</option>
                            <option value="private">Private</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={ newArticle.isFeatured ? 6 : 12 }>
                    <Box mb={2.5}>
                      <InputLabel>Feature Post</InputLabel>
                      <FormControl fullWidth={true}>
                        <NativeSelect value={ newArticle.isFeatured } name="isFeatured" fullWidth onChange={this.updateFormModel}>
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>
                  {
                    newArticle.isFeatured
                    ? <Grid item xs={6}>
                      <Box mb={2.5}>
                        <InputLabel>Feature Duration</InputLabel>
                        <Box className="datepicker-display" onClick={ () => { this.setState({ showFeatureDurationDatePicker: true }); } }>
                          { newArticle.featuredDuration.format(DATE_TIME_FORMAT) }
                        </Box>
                        <div className="datepicker-input">
                          <DateTimePicker 
                            showDatePicker={showFeatureDurationDatePicker} 
                            onClose={() => { this.setState({ showFeatureDurationDatePicker: false }) }} 
                            onChange={featuredDuration => { this.setState({ showFeatureDurationDatePicker: false, newArticle: Object.assign(newArticle, { featuredDuration }) }) }} 
                          />
                        </div>
                      </Box>
                    </Grid>
                    : null
                  }
                </Grid>
                <Box mb={2.5} className="rich-search">
                  <div className="rich-search-toggle" onClick={ () => this.setState({ newArticleSearchExpanded: !newArticleSearchExpanded }) }>
                    <Typography variant="body2" component="span">
                      { tagsForDisplay.length > 0 ? tagsForDisplay.join(', ') : 'Add Teams, Players, or Games' }
                    </Typography>
                    <Icon>arrow_drop_down</Icon>
                    {
                      tagsForDisplay.length > 0
                      ? <IconButton onClick={ e => {
                        e.stopPropagation();
                        this.setState({
                          newArticle: Object.assign(newArticle, { teams: [], players: [], games: [] })
                        });
                      } }>
                        <Icon>clear</Icon>
                      </IconButton>
                      : null
                    }
                  </div>
                  <div className={classNames({ rich_search_controls: true, expanded: newArticleSearchExpanded })}>
                    <Input 
                      type="text" 
                      name="search" 
                      className="rich-search-field" 
                      value={newArticleSearch} 
                      onChange={this.onUpdateNewArticleSearch} 
                      autoComplete="off"
                      placeholder="Search"
                    />
                    <div className="rich-search-suggestions">
                      <div className="rich-search-column">
                        <Typography variant="body2" component="div" className="rich-search-headline">
                          <small>Teams</small>
                        </Typography>
                        <div className="rich-search-options">
                          {teams.map(team => {
                            if (newArticleSearch !== '' && team.name.toLowerCase().indexOf(newArticleSearch.toLowerCase()) === -1) {
                              return null;
                            }

                            return <div key={team._id} className="rich-search-option">
                              <label>
                                <input 
                                  type="checkbox" 
                                  checked={newArticle.teams.indexOf(team._id) !== -1} 
                                  onChange={() => {
                                    if (newArticle.teams.indexOf(team._id) !== -1)
                                      newArticle.teams.splice(newArticle.teams.indexOf(team._id), 1);
                                    else
                                      newArticle.teams.push(team._id);
                                    this.setState({ newArticle });
                                  }}
                                />
                                <Typography key={ team._id } variant="body2" component="span">{ team.name }</Typography>
                              </label>
                              <select name="fieldView" className="field-attachment" value={newArticle.fieldView} onChange={this.updateFormModel} onClick={ e => e.preventDefault() }>
                                <option value="">-</option>
                                {sides.map(side => <option key={ side._id } value={ team._id + '_' + side._id }>{ side.name }</option>)}
                              </select>
                            </div>
                          })}
                        </div>
                      </div>
                      <div className="rich-search-column">
                        <Typography variant="body2" component="div" className="rich-search-headline">
                          <small>Players</small>
                        </Typography>
                        <div className="rich-search-options">
                          {players.map(player => {
                            return <div key={player.Player._id} className="rich-search-option">
                              <label>
                                <input 
                                  type="checkbox"
                                  checked={newArticle.players.indexOf(player.Player._id) !== -1} 
                                  onChange={() => {
                                    if (newArticle.players.indexOf(player.Player._id) !== -1)
                                      newArticle.players.splice(newArticle.players.indexOf(player.Player._id), 1);
                                    else
                                      newArticle.players.push(player.Player._id);
                                    this.setState({ newArticle });
                                  }}
                                />
                                <Typography key={ player.Player._id } variant="body2" component="span">{ player.Player.name }</Typography>
                              </label>
                              <Select value={player.health} onChange={ e => this.onUpdatePlayerHealth(player._id, e.target.value) } name="health">
                                <MenuItem value="1">
                                  <div className="health_box green"></div>
                                </MenuItem>
                                <MenuItem value="2">
                                  <div className="health_box orange"></div>
                                </MenuItem>
                                <MenuItem value="3">
                                  <div className="health_box red"></div>
                                </MenuItem>
                                <MenuItem value="4">
                                  <div className="health_box no_border white"></div>
                                </MenuItem>
                              </Select>
                            </div>
                          })}
                        </div>
                      </div>
                      <div className="rich-search-column">
                        <Typography variant="body2" component="div" className="rich-search-headline">
                          <small>Games</small>
                        </Typography>
                        <div className="rich-search-options">
                          {games.map(game => {
                            if (newArticleSearch !== '' && game.formattedValue.toLowerCase().indexOf(newArticleSearch.toLowerCase()) === -1) {
                              return null;
                            }

                            return <label key={game._id} className="rich-search-option">
                              <label>
                                <input 
                                  type="checkbox"
                                  checked={newArticle.games.indexOf(game._id) !== -1} 
                                  onChange={() => {
                                    if (newArticle.games.indexOf(game._id) !== -1)
                                      newArticle.games.splice(newArticle.games.indexOf(game._id), 1);
                                    else
                                      newArticle.games.push(game._id);
                                    this.setState({ newArticle });
                                  }}
                                />
                                <Typography key={ game._id } variant="body2" component="span">{ game.formattedValue }</Typography>
                              </label>
                              <select name="showFieldViewGameId" className="field-attachment" value={newArticle.showFieldViewGameId} onChange={this.updateFormModel} onClick={ e => e.preventDefault() }>
                                <option value="">-</option>
                                <option value={ game._id }>Show field view</option>
                              </select>
                            </label>
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                <Box mb={1.5}>
                  <Typography variant="body2" component="span">Video</Typography>
                  <textarea className="textarea" value={ newArticle.video } name="video" onChange={this.updateFormModel}></textarea>
                </Box>
                <Box mb={1.5} className="ckeditor-wrapper">
                  <CKEditor 
                      onInit={editor => editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement()) }
                      config={{ toolbar: [ 'bold', 'italic', 'underline', 'link', 'NumberedList', 'BulletedList', 'alignment', 'blockquote', 'mediaEmbed' ] }}
                      editor={ DecoupledEditor } 
                      data={newArticle.body} 
                      onChange={this.updateFormModel}
                  />
                  {
                    newArticleErrors.body
                    ? <FormHelperText className="error-message">Please enter the update body</FormHelperText>
                    : null
                  }
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box mb={2.5}>
                      <InputLabel>Season</InputLabel>
                      <FormControl fullWidth={true}>
                        <NativeSelect value={ newArticle.season } name="season" fullWidth onChange={this.updateFormModel}>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box mb={2.5}>
                      <InputLabel>Week</InputLabel>
                      <FormControl fullWidth={true}>
                        <NativeSelect value={ newArticle.scheduleTimelineId } name="scheduleTimelineId" fullWidth onChange={this.updateFormModel} error={newArticleErrors.scheduleTimelineId}>
                            <option value="">-</option>
                            {
                              weeks.map((week, i) => {
                                return (<option key={week._id} value={week._id}>{ week.name }</option>)
                              })
                            }
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                
                <Box className="datepicker-display" onClick={ () => { this.setState({ showDatePicker: true }); } }>
                  { newArticle.date.format(DATE_TIME_FORMAT) }
                </Box>
                <div className="datepicker-input">
                  <DateTimePicker showDatePicker={showDatePicker} 
                                  onClose={() => { this.setState({ showDatePicker: false }); }} 
                                  onChange={(date) => { this.setState({ showDatePicker: false, newArticle: Object.assign(newArticle, { date }) }); }} />
                </div>
                <div className="image-uploader" onClick={() => document.getElementById('image-uploader-input').click()}>
                  <Icon>camera_alt</Icon>

                  {
                    newArticle.imageSource
                    ? <figure>
                      <img src={ newArticle.imageSource } alt="Article poster" />
                    </figure>
                    : null
                  }

                  <input type="file" id="image-uploader-input" onChange={ this.onSelectImage } />
                </div>
                <Box className="space-between">
                  <div>&nbsp;</div>
                  {
                    /*
                    <label className="timestamp-checkbox">
                      <input type="checkbox" checked={ showArticleDialogShare } onChange={ () => this.setState({ showArticleDialogShare: !showArticleDialogShare }) } /> 
                      <Typography variant="body2" component="span">Share with partners</Typography>
                    </label>
                    */
                  }
                  
                  <div>
                    <Button onClick={this.handleClose} color="primary">Cancel</Button>
                    {
                      editMode 
                      ? <Button onClick={this.handleUpdateArticle} color="primary">Update</Button>
                      : <Button onClick={this.handleCreateArticle} color="primary">Create</Button>
                    }
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Box>)
          : null
        }

        <div className="col-scroller col-scroller-updates">
          <div className="col-scroller-wrapper col-scroller-wrapper-updates">
            <div className="col-scroller-inner col-scroller-inner-updates">
              {
                updates.map((article, i) => {
                  if (alreadyDisplayedUpdates.indexOf(article._id) !== -1) {
                    return null;
                  }

                  alreadyDisplayedUpdates.push(article._id);

                  return (<Box mb={2} className="update-box" key={article._id}>
                    <EmbedControl type="post" showEmbed={ article.isEmbedded } id={ article._id } onAlert={successMessage => {
                      this.setState({ successMessage });

                      setTimeout(() => this.setState({ successMessage: false }), 3000);
                    }} />
                    <Card className="overflow-visible">
                      <CardContent>
                        <Grid container>
                          {
                            article.video
                            ? <div className="video-embed">
                              <iframe src={ article.video }></iframe>
                            </div>
                            : null
                          }
                          {
                            article.postImageId
                            ? <img src={ article.postImage.thumb } alt="Article poster" />
                            : null  
                          }
                          <Grid className={article.teamPost && allowFieldView ? 'padded-right' : ''} item md={article.teamPost && allowFieldView ? 6 : 12} sm={12}>
                            <Typography component="h4" gutterBottom>
                              <strong>{ article.title }</strong>
                            </Typography>
                            {
                              article.description.length > 280
                              ? (<div>
                                <Typography variant="body2" component="div" className="ckeditor-body" dangerouslySetInnerHTML={{ __html: prepareEmbeds(article.description.substring(0,280).trim()) + '...' }}></Typography>
                                <Typography variant="body2" component="div" className="ckeditor-body">
                                  <span className="fake-link" onClick={ () => { this.setState({ expandedArticle: article._id }); window.mixpanel.track('Read More'); }}>Read more</span>
                                </Typography>
                              </div>)
                              : <Typography variant="body2" component="div" className="ckeditor-body" dangerouslySetInnerHTML={{ __html: prepareEmbeds(article.description) }}></Typography>
                            }
                            <Typography color="textSecondary" className="timestamp">
                              { moment(article.datetime).fromNow() }
                              {
                                isAdmin()
                                ? (<span className="buttons">
                                  <Button onClick={ () => this.setState({ notificationObject: article }) }>
                                    <Icon>send</Icon>
                                  </Button>
                                  <Button onClick={ () => this.handleReadArticle(article) }>
                                    <Icon>edit</Icon>
                                  </Button>
                                  <Button onClick={ () => this.handleDeleteArticle(article._id) }>
                                    <Icon>delete_outline</Icon>
                                  </Button>
                                </span>) : null
                              }
                            </Typography>
                          </Grid>
                          {
                            article.teamPost && allowFieldView && article.showFieldView
                            ? <Grid item md={6} sm={12}>
                                <div className={classNames(['depth-chart', 'is-active'])}>
                                  { <div className="game-team-name">{ article.teamPost.teamId.name } - { article.teamPost.side.name }</div> }
                                  {
                                    article.teamPost.teamWeeklyId
                                    ? <GamePositions positions={ article.teamPost.side.name === 'Defense' ? article.teamPost.teamWeeklyId.defense_score : article.teamPost.teamWeeklyId.offense_score } state={1} team="visiting" side={ article.teamPost.side.name.toLowerCase() } /> 
                                    : null
                                  }
                                </div>
                              </Grid>
                            : null
                          }
                        </Grid>
                      </CardContent>    

                      {
                        expandedArticle === article._id
                        ? (<Dialog className="expanded-article" open={true} onClick={ () => this.setState({ expandedArticle: '' }) }>
                            <DialogTitle className="dialog-title">{ article.title }</DialogTitle>
                            <DialogContent>
                              <Typography variant="body2" component="div" className="ckeditor-body" dangerouslySetInnerHTML={{ __html: prepareEmbeds(article.description) }}></Typography>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={ () => this.setState({ expandedArticle: '' }) } color="primary">Close</Button>
                            </DialogActions>
                          </Dialog>)
                        : null
                      }
                    </Card>
                  </Box>)
                })
              }
            </div>
          </div>
        </div>

        { loading ? <LoadingWheel /> : null }
        { newArticleError ? <ErrorSnackbar message={newArticleError} /> : null }
        { successMessage ? <SuccessSnackbar message={successMessage} /> : null }
        { notificationObject ? <NotifyControl type="post" object={ notificationObject } onClose={ () => this.setState({ notificationObject: null }) } /> : null }
      </Box>
    );
  }
}

function mapStateToProps(state, action) {
  return {
    teams: state.teams,
    weeks: state.weeks,
    players: state.players,
    globalVars: state.globalVars
  }
}
function mapDispatchToProps(dispatch) {
  return {
    playersActions: bindActionCreators(playersActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatesListing);

UpdatesListing.defaultProps = {
  hideTitleBar: false,
  titleBarText: 'Subscriber Updates',
  displayCloseButton: false,
  stickyTitle: true,
  allowFieldView: false,
  onClose: () => {}
};
