import initialState from './initialState';
import { FETCH_PLAYERS, RECEIVE_PLAYERS } from '../actions/actionTypes';

export default function players(state = initialState.players, action) {
  let newState;

  switch (action.type) {
  	case FETCH_PLAYERS:
      return action;
    
    case RECEIVE_PLAYERS:
      newState = action.players;

      return newState;

    default:
      return state;
  }
}
