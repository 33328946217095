import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import EmbedConfigurator from '../components/EmbedConfigurator';
import { getSeason } from '../helpers/session';
import { API_URL } from '../config.js';
import axios from 'axios';

class EmbedConfiguratorWrapper extends Component {
  constructor() {
    super();

    this.state = {
      games: [],
      active: false
    }
  }

  componentDidMount() {
    axios.get(API_URL + '/injuryIndex/allGames?season=' + getSeason()).then(({data}) => {
      this.setState({ games: data.data.injuryIndexList });
    });
  }

  onShow = () => {
  	this.setState({ active: true });
  }

  onHide = () => {
  	this.setState({ active: false });
  }

  render() {
    const { games, active } = this.state;

    return <div className="embed-configurator">
	  <Button className="create-button" variant="contained" color="primary" onClick={this.onShow}>
		Create Embed Widget
	  </Button>
		{
    		active
    		? <EmbedConfigurator games={games} onHide={this.onHide} />
    		: null
    	}
	  </div>
  }
}

export default EmbedConfiguratorWrapper;
