import React, { Component } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tooltip from '../components/Tooltip';
import LoadingWheel from '../components/LoadingWheel';
import ErrorSnackbar from '../components/ErrorSnackbar';
import UpdatesListing from '../components/UpdatesListing';
import { API_URL } from '../config.js';
import { getToken, getSeason } from '../helpers/session';

export default class TeamsScreen extends Component {
	constructor(props) {
    	super(props);

	    this.state = {
	    	updates: [],
	    	teams: [],
	    	loading: true,
	    	editMode: false,
	    	showDialog: false,
	    	currentTeam: '',
	    	currentPlayer: '',
	    	sort: 'player-asc',
	    	newTeamError: '',
	    	form: { name: '' },
	    	errors: { name: false }
    	};
  	}

  	componentDidMount() {
  		const authToken = getToken();

  		if (!authToken) {
  			this.props.history.push('/login');
  		} else {
        	window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Page: My Teams - /teams');
        	window.fbq('track', 'PageView', { title: 'My Teams' });
        	document.getElementsByTagName('body')[0].className = 'research';
        	this.loadTeams();
	  	}
  	}

  	componentWillUnmount() {
  		document.getElementsByTagName('body')[0].className = '';
  	}

  	loadTeams = (teamId) => {
  		axios.get(API_URL + '/userTeams').then(response => {
    		const teams = response.data.data.team;

    		if (teams.length > 0) {
    			this.setState({ teams, currentTeam: teamId ? teamId : teams[0]._id, loading: false });
    		} else {
    			this.setState({ teams, loading: false });
    		}
    	});
  	}

  	loadUpdates = (teamId, playerId) => {
  		if (teamId) {
  			axios.get(API_URL + '/post?type=docLiveUpdate&teamId=' + teamId + '&season=' + getSeason()).then(response => this.setState({ updates : response.data.data ? response.data.data.postList : [] }));
  		} else if (playerId) {
  			axios.get(API_URL + '/post?type=docLiveUpdate&playerId=' + playerId + '&season=' + getSeason()).then(response => this.setState({ updates : response.data.data ? response.data.data.postList : [] }));
  		} else {
  			this.setState({ updates: [] });
  		}
  	}

  	onLoadUpdates = () => {
  		const { currentTeam, currentPlayer } = this.state;

  		if (currentPlayer)
  			this.loadUpdates(false, currentPlayer);
  		else
  			this.loadUpdates(currentTeam, false);
  	}

  	onSelectPlayer = player => {
      const { currentPlayer } = this.state;
      
      if (currentPlayer === player._id) {
        this.onDeselectPlayer();
      } else {
      	window.mixpanel.track('Show Updates');
        this.setState({ 
          currentPlayer: player._id,
          updates: []
        });
        this.loadUpdates(false, player._id);
        window.scrollTo(0, 0);
      }
    }

    onDeselectPlayer = () => {
      const { currentTeam } = this.state;
      this.setState({ currentPlayer: '' });
      this.loadUpdates(currentTeam, false);
    }

  	updateFormModel = (e) => {
	    const { form, errors } = this.state;
	    const { target } = e;
	    const { name, value } = target;

	    form[name] = value;
	    errors[name] = false;

	    this.setState({
	        form: form,
	        errors: errors
	    });
  	}

  	handleReadTeam = (team) => {
	    this.setState({
  			showDialog: true,
  			editMode: team._id,
  			form: {
  				name: team.name
  			}
	    });
  	}

  	handleClose = () => {
	    this.setState({ 
	    	showDialog: false,
	    	form: { name: '' },
	    	errors: { name: false }
    	 });
  	}

  	handleCreateTeam = () => {
  		const { form, errors } = this.state;
  		let hasErrors = false;

  		for (let i in errors) {
	      errors[i] = false;
	    }

  		if (form.name === '') {
  			errors.name = true;
  			hasErrors = true;
  		}

  		if (hasErrors) {
  			this.setState({ errors });
  		} else {
  			this.setState({ loading: true });

  			axios.post(API_URL + '/userTeams/create', { name: form.name }).then(response => {
				this.handleClose();
				this.loadTeams(response.data.data._id);
			}).catch(error => {
  				this.setState({
  		  			newTeamError: error.response.data.message,
  		  			loading: false
  		  		});
  		  		setTimeout(() => this.setState({ newTeamError: '' }), 3000);
  			});
  		}
  	}

  	handleUpdateTeam = () => {
	    const { form, errors, editMode } = this.state;
	    let hasErrors = false;

	    for (let i in errors) {
	      errors[i] = false;
	    }

	    if (form.name === '') {
  			errors.name = true;
  			hasErrors = true;
  		}

  		if (hasErrors) {
	      this.setState({ errors });
	    } else {
	      this.setState({ loading: true });
	    
	      axios.put(API_URL + '/userTeams/update/' + editMode, { name: form.name }).then(response => { 
	        this.handleClose();
			this.loadTeams(response.data.data._id);
	      }).catch(error => {
	        this.setState({
  	  			newTeamError: error.response.data.message,
  	  			loading: false
  	  		});
  	  		setTimeout(() => this.setState({ newTeamError: '' }), 3000);
	      });
	    }
  	}

  	handleDeleteTeam = () => {
  		if (window.confirm('Are you sure?')) {
  			const { editMode } = this.state;
	      	axios.delete(API_URL + '/userTeams/delete/' + editMode).then(response => { 
	        	this.handleClose();
				    this.loadTeams();
	      	}).catch(error => {
	        	this.setState({
  	  				newTeamError: error.response.data.message,
  	  				loading: false
  	  			});
  	  			setTimeout(() => this.setState({ newTeamError: '' }), 3000);
	      	});
  		}
  	}

  	onSelectTeam = e => {
  		const { value } = e.target;

  		if (value === 'add') {
  			window.mixpanel.track('Create Team');
  			this.setState({ showDialog: true, editMode: false });
  		} else {
  			this.setState({ 
				currentTeam: value,
				currentPlayer: ''
			});
			this.loadUpdates(value, false);
  		}
  	}

    handleRemovePlayerFromTeam = async (event, player) => {
      if (window.confirm('Are you sure?')) {
        const { currentTeam } = this.state;
        this.setState({ loading: true });
        await axios.post(API_URL + '/userTeams/removePlayersFromTeams', { playerIds: [player._id], userTeamId: currentTeam });
        this.loadTeams(currentTeam);
      }
    }

  	render() {
  		const {  
  			updates,
  			showDialog, 
  			currentTeam, 
  			currentPlayer,
  			form, 
  			errors, 
  			loading, 
  			newTeamError,
  			editMode,
  			teams,
  			sort
  		} = this.state;
  		const currentTeamObject = currentTeam ? teams.find(team => team._id === currentTeam) : null;
  		const allPlayers = currentTeamObject ? currentTeamObject.players : [];
  		let players = [];

  		for (let i in allPlayers) {
  			const player = allPlayers[i];
        player.health = player.playerData[0].health;
        player.Position = player.playerData[0].position;
        player.Side = player.playerData[0].side;
        player.Team = player.playerData[0].teamId;
        player.status = player.playerData[0].status;
    		players.push(player);
  		}

  		if (sort === 'player-asc') {
  			players = players.sort((a,b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
  		} else if (sort === 'player-desc') {
  			players = players.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? -1 : (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0));
  		} else if (sort === 'position-asc') {
  			players = players.sort((a,b) => a.Position.name.toLowerCase() < b.Position.name.toLowerCase() ? -1 : (a.Position.name.toLowerCase() > b.Position.name.toLowerCase() ? 1 : 0));
  		} else if (sort === 'position-desc') {
  			players = players.sort((a,b) => a.Position.name.toLowerCase() > b.Position.name.toLowerCase() ? -1 : (a.Position.name.toLowerCase() < b.Position.name.toLowerCase() ? 1 : 0));
  		} else if (sort === 'health-asc') {
  			players = players.sort((a,b) => a.health < b.health ? -1 : (a.health > b.health ? 1 : 0));
  		} else if (sort === 'health-desc') {
  			players = players.sort((a,b) => a.health > b.health ? -1 : (a.health < b.health ? 1 : 0));
  		} else if (sort === 'status-asc') {
  			players = players.sort((a,b) => a.status.toLowerCase() < b.status.toLowerCase() ? -1 : (a.status.toLowerCase() > b.status.toLowerCase() ? 1 : 0));
  		} else if (sort === 'status-desc') {
  			players = players.sort((a,b) => a.status.toLowerCase() > b.status.toLowerCase() ? -1 : (a.status.toLowerCase() < b.status.toLowerCase() ? 1 : 0));
  		}

  		const currentPlayerObject = currentPlayer ? players.find(player => player._id === currentPlayer) : null;

	  	return (
	  		<Box className="main main-players main-research">
	  			<Box className="game-box game-box-header players-header" mb={1.5}>
			        <Box className="game-box-row">
			          	<Typography component="div" className="game-box-col game-box-col-auto game-box-col-team is-simple">
			            	<Select value={currentTeam} onChange={ this.onSelectTeam } className="top-selector">
			                	{
			                  		teams.map((team, i) => {
			                    		return (
			                    			<MenuItem key={team._id} value={team._id} className="top-selector-option">
		                    					{ team.name }
		                    					<IconButton onClick={ event => this.handleReadTeam(team) }>
				          							<Icon>edit</Icon>
			          							</IconButton>
				          					</MenuItem>)
			                  		})
			                	}
			                	{
			                		teams.length < 5
			                		? <MenuItem value="add">+ Add a Team</MenuItem>
			                		: null
		                		}
			            	</Select>
			          	</Typography>
			          	<Box component="div" className="game-box-col game-box-col-quick-button to-left mob-var-h">
		                    <Link to="/research" className="button-wrapper">
		                      <Icon>add</Icon>
		                    </Link>

		                    <span className="info">
		                    	To add a player, click 
		                    	<span className="button-wrapper nomr">
		                    		<Icon>add</Icon>
	                    		</span>,
		                    	select your player at Player/Teams, 
		                    	then click 
		                    	<span className="button-wrapper">
		                    		<Icon>add</Icon>
	                    		</span>
		                    	again
	                    	</span>
	      				 </Box>
			        </Box>
		      	</Box>

			    	  <Grid container>
				    	<Grid item xs={9} className="main-table-holder">
					      <Box className="players-table no-margin-top">
						      <div className="tbl-holder tbl-holder-1" style={{ right: 0 }}>
							      <Table>
							        <TableHead>
							          <TableRow>
							            <TableCell component="th" className="sortable mob-first" onClick={ () => this.setState({ sort: sort === 'player-asc' ? 'player-desc' : 'player-asc'}) }>
							            	<div className="sortable-inner">
								            	Player
								            	{
								            		sort === 'player-asc' || sort === 'player-desc'
								            		? <Icon>{ sort === 'player-desc' ? 'expand_less' : 'expand_more' }</Icon>
								            		: null
							            		}
						            		</div>
						            	</TableCell>
							            <TableCell component="th">Team</TableCell>
							            <TableCell component="th">Side</TableCell>
							            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'position-asc' ? 'position-desc' : 'position-asc'}) }>
							            	<div className="sortable-inner">
								            	<div className="mobile-hidden">Position</div>
								            	{
								            		sort === 'position-asc' || sort === 'position-desc'
								            		? <Icon>{ sort === 'position-desc' ? 'expand_less' : 'expand_more' }</Icon>
								            		: null
							            		}
						            		</div>
						            	</TableCell>
							            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'health-asc' ? 'health-desc' : 'health-asc'}) }>
							            	<div className="sortable-inner">
								            	<div className="mobile-hidden">Health</div>
								            	{
								            		sort === 'health-asc' || sort === 'health-desc'
								            		? <Icon>{ sort === 'health-desc' ? 'expand_less' : 'expand_more' }</Icon>
								            		: null
							            		}
						            		</div>
						            	</TableCell>
							            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'status-asc' ? 'status-desc' : 'status-asc'}) }>
							            	<div className="sortable-inner">
							            		<div className="mobile-hidden">Status</div>
								            	{
								            		sort === 'status-asc' || sort === 'status-desc'
								            		? <Icon>{ sort === 'status-desc' ? 'expand_less' : 'expand_more' }</Icon>
								            		: null
							            		}
						            		</div>
						            	</TableCell>
							            <TableCell className="mob-last"></TableCell>
							          </TableRow>
							        </TableHead>
							        <TableBody>
						        	  {
						        	  	players.length < 1
						        	  	? (<TableRow>
						        	  		<TableCell className="mob-first" colSpan="7">No players found...</TableCell>
						        	  	</TableRow>)
						        	  	: null
						        	  }

							          {players.map((player, i) => {
							          	return (
								            <TableRow key={i} className={ classNames({ is_active: player._id === currentPlayer }) } onClick={ () => this.onSelectPlayer(player) }> 
								            	<TableCell className="mob-first">
								                	{player.name}
								              	</TableCell>
								              	<TableCell>
								              		<div className="mobile-hidden">{player.Team && player.Team.name ? player.Team.name : ''}</div>
								              		<div className="desktop-hidden">{player.Team && player.Team.name ? player.Team.name.split(' ').pop() : ''}</div>
							              		</TableCell>
								              	<TableCell>
								              		<div className="mobile-hidden">{player.Side ? player.Side.name : '-'}</div>
								              		<div className="desktop-hidden">{player.Side ? player.Side.name[0] : '-'}</div>
							              		</TableCell>
								              	<TableCell>{player.Position ? player.Position.name : '-'}</TableCell>
								              	<TableCell>
								              		<div className={ classNames({health_box: true, white: player.health.toString() === '4', orange: player.health.toString() === '2', green: player.health.toString() === '1'}) }></div>
								              	</TableCell>
								              	<TableCell>{player.status}</TableCell>
								              	<TableCell className="mob-last" align="right">
                                  <IconButton className="rich-button no-padding mr-5" onClick={ event => this.handleRemovePlayerFromTeam(event, player) }>
                                      <Tooltip text="Remove this player from your team" />
                                      <Icon className="mode-default">done</Icon>
                                      <Icon className="mode-hover">close</Icon>
                                  </IconButton>
								              		<IconButton className="no-padding">
    							        					<Icon>dehaze</Icon>
    							      					</IconButton>
								              	</TableCell>
								            </TableRow>)
							          })}
							        </TableBody>
							      </Table>
						      </div>
						      <div className="main-table-holder-scroller">
							      <div className="tbl-holder tbl-holder-2">
								      <Table>
								        <TableHead>
								          <TableRow>
								            <TableCell component="th" className="sortable mob-first" onClick={ () => this.setState({ sort: sort === 'player-asc' ? 'player-desc' : 'player-asc'}) }>
								            	<div className="sortable-inner">
									            	Player
									            	{
									            		sort === 'player-asc' || sort === 'player-desc'
									            		? <Icon>{ sort === 'player-desc' ? 'expand_less' : 'expand_more' }</Icon>
									            		: null
								            		}
							            		</div>
							            	</TableCell>
								            <TableCell component="th">Team</TableCell>
								            <TableCell component="th">Side</TableCell>
								            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'position-asc' ? 'position-desc' : 'position-asc'}) }>
								            	<div className="sortable-inner">
									            	<div className="mobile-hidden">Position</div>
									            	{
									            		sort === 'position-asc' || sort === 'position-desc'
									            		? <Icon>{ sort === 'position-desc' ? 'expand_less' : 'expand_more' }</Icon>
									            		: null
								            		}
							            		</div>
							            	</TableCell>
								            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'health-asc' ? 'health-desc' : 'health-asc'}) }>
								            	<div className="sortable-inner">
									            	<div className="mobile-hidden">Health</div>
									            	{
									            		sort === 'health-asc' || sort === 'health-desc'
									            		? <Icon>{ sort === 'health-desc' ? 'expand_less' : 'expand_more' }</Icon>
									            		: null
								            		}
							            		</div>
							            	</TableCell>
								            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'status-asc' ? 'status-desc' : 'status-asc'}) }>
								            	<div className="sortable-inner">
								            		<div className="mobile-hidden">Status</div>
									            	{
									            		sort === 'status-asc' || sort === 'status-desc'
									            		? <Icon>{ sort === 'status-desc' ? 'expand_less' : 'expand_more' }</Icon>
									            		: null
								            		}
							            		</div>
							            	</TableCell>
								            <TableCell className="mob-last"></TableCell>
								          </TableRow>
								        </TableHead>
								        <TableBody>
							        	  {
							        	  	players.length < 1
							        	  	? (<TableRow>
							        	  		<TableCell className="mob-first" colSpan="7">No players found...</TableCell>
							        	  	</TableRow>)
							        	  	: null
							        	  }

								          {players.map((player, i) => {
								          	return (
									            <TableRow key={i} className={ classNames({ is_active: player._id === currentPlayer }) } onClick={ () => this.onSelectPlayer(player) }>
									            	<TableCell className="mob-first">
									                	{player.name}
									              	</TableCell>
									              	<TableCell>
									              		<div className="mobile-hidden">{player.Team && player.Team.name ? player.Team.name : ''}</div>
									              		<div className="desktop-hidden">{player.Team && player.Team.name ? player.Team.name.split(' ').pop() : ''}</div>
								              		</TableCell>
									              	<TableCell>
									              		<div className="mobile-hidden">{player.Side ? player.Side.name : '-'}</div>
									              		<div className="desktop-hidden">{player.Side ? player.Side.name[0] : '-'}</div>
								              		</TableCell>
									              	<TableCell>{player.Position ? player.Position.name : '-'}</TableCell>
									              	<TableCell>
									              		<div className={ classNames({health_box: true, white: player.health.toString() === '4', orange: player.health.toString() === '2', green: player.health.toString() === '1'}) }></div>
									              	</TableCell>
									              	<TableCell>{player.status}</TableCell>
									              	<TableCell className="mob-last" align="right">
                                    <IconButton className="rich-button no-padding mr-5" onClick={ event => this.handleRemovePlayerFromTeam(event, player) }>
                                        <Tooltip text="Remove this player from your team" />
                                        <Icon className="mode-default">done</Icon>
                                        <Icon className="mode-hover">close</Icon>
                                    </IconButton>
									              		<IconButton className="no-padding" onClick={ () => {
									              			if (currentPlayer === player._id) {
									              				this.onDeselectPlayer();
									              			} else {
									              				this.setState({ 
									              					currentPlayer: player._id,
									              					updates: []
							              					 	});
									              				this.loadUpdates(false, player._id);
									              			}
									              		}}>
    								        					<Icon>dehaze</Icon>
    								      					</IconButton>
									              	</TableCell>
									            </TableRow>)
								          })}
								        </TableBody>
								      </Table>
							      </div>
						      </div>
					      </Box>
				      </Grid>

				      <Grid item xs={3} className={ classNames({ side_posts_holder: true, is_active: currentPlayer }) }>
					      	<div className="side-posts">
					      		<div className="side-posts-top">
						      		<Typography component="div">
		                				{
		                					currentPlayer && currentPlayerObject
		                					? <strong>{ currentPlayerObject.name } Updates</strong> 
		                					: null
		                				}
		              				</Typography>
		              				{
		              					currentPlayer
		              					? <IconButton onClick={ this.onDeselectPlayer }>
				        					<Icon>close</Icon>
				      					</IconButton>
				      					: null
		              				}
	              				</div>

	              				{
	              					currentPlayer && !loading && updates.length === 0
	              					? <Box mt={2}>
	              						<Typography variant="body2" component="p">No updates for this player</Typography>
              						</Box>
	              					: null
	              				}

	              				<UpdatesListing updates={updates} onLoad={this.onLoadUpdates} hideTitleBar={true} />
				            </div>
					      </Grid>
				  	</Grid>

			  	<Dialog open={showDialog} onClose={this.handleClose}>
		          <DialogTitle className="dialog-title">
		          	{ editMode ? 'Edit team' : 'Add a new team' }
		          </DialogTitle>
		          <DialogContent className="size-m">
		            <Box mb={2.5}>
		              <TextField 
		              	label="Team Name" 
		              	type="text" 
		              	name="name" 
		              	value={form.name} 
		              	error={errors.name} 
		              	onChange={this.updateFormModel} 
		              	fullWidth 
	              	  />
		            </Box>
		          </DialogContent>
		          <DialogActions>
		          	{
		          		editMode
		          		? <Button onClick={this.handleDeleteTeam} color="secondary">Delete Team</Button>
		          		: null
	          		}
		            <Button onClick={this.handleClose} color="primary">Cancel</Button>
		            {
                    	editMode 
	                    ? <Button onClick={this.handleUpdateTeam} color="primary">Update</Button>
	                    : <Button onClick={this.handleCreateTeam} color="primary">Create</Button>
                  	}
		          </DialogActions>
	          </Dialog>
	          { loading ? <LoadingWheel /> : null }
	          { newTeamError ? <ErrorSnackbar message={newTeamError} /> : null }
		  	</Box>
	  	);
  	}
}
