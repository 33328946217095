import initialState from './initialState';
import { RECEIVE_GLOBAL_VARS } from '../actions/actionTypes';

export default function globalVars(state = initialState.globalVars, action) {
  let newState;

  switch (action.type) {
  	case RECEIVE_GLOBAL_VARS:
      newState = action.globalVars;

      return newState;

    default:
      return state;
  }
}
