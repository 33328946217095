import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ModalOverlay from '../components/ModalOverlay';
import SubscriptionBox from '../components/SubscriptionBox';
import CheckoutForm from '../components/CheckoutForm';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { STRIPE_API_KEY } from '../config.js';
import * as subscriptionTypesActions from '../actions/subscriptionTypesActions';

class PaymentModal extends Component {
	componentWillMount() {
		const { registerForm } = this.props;

		if (!registerForm || !registerForm.subscriptionType) {
			this.props.history.push('/register-subscription');
		}
	}

  	render() {
  		const { subscriptionTypes, registerForm } = this.props;

  		if (!subscriptionTypes || subscriptionTypes.length < 1)
  			return null;

  		const subscriptionType = subscriptionTypes.find(subscriptionType => subscriptionType.subscriptionId === registerForm.subscriptionType);

  		if (!subscriptionType)
  			return null;

		return (
		    <Container fixed className="main main-modal">
		    	<ModalOverlay />
		    	<Card className="modal">
			        <CardContent>
			        	<Box mb={4}>
				          	<Typography variant="h6" component="h2" className="modal-title">
				            	Complete Payment
				          	</Typography>
			          	</Box>
			      		<Box>
	          				<SubscriptionBox subscriptionType={subscriptionType} selected={true} />
	    				</Box>    
		        	</CardContent>
		        	<StripeProvider apiKey={ STRIPE_API_KEY }>
		      			<Elements>
		      				<CheckoutForm {...this.props} subscriptionType={subscriptionType} registerForm={registerForm} />
				      	</Elements>
	    			</StripeProvider>
			    </Card>
		    </Container>
	  	);
  	}
}

function mapStateToProps(state, action) {
  return {
    subscriptionTypes: state.subscriptionTypes,
    registerForm: state.registerForm
  }
}
function mapDispatchToProps(dispatch) {
  return {
  	subscriptionTypesActions: bindActionCreators(subscriptionTypesActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentModal);
