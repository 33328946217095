import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import LoadingWheel from '../components/LoadingWheel';
import SuccessSnackbar from '../components/SuccessSnackbar';
import UpdatesListing from '../components/UpdatesListing';
import NotifyControl from '../components/NotifyControl';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { getToken, isAdmin, getSeason } from '../helpers/session';
import { getVimeoEmbed, getEmbedCode, resizeIframe } from '../helpers/global';
import { API_URL } from '../config.js';

class LiveScreen extends Component {
  constructor() {
    super();

    this.state = {
      updates: [],
      streamings: [],
      videos: [],
      loading: true,
      currentActiveTab: 'updates',
      successMessage: false,
      updatesPerPage: 10,
      currentUpdatesPage: 1,
      uploadsLoadedEntirely: false,
      currentVideoId: '',
      leagueType: localStorage.getItem('pfd_league_2') ? localStorage.getItem('pfd_league_2') : 'xfl',
      notificationObject: null
    };
  }

  componentDidMount() {
    const { currentUpdatesPage } = this.state;
    const authToken = getToken();

    if (!authToken) {
      this.props.history.push('/login');
    } else {
      this.loadUpdates(currentUpdatesPage, true);
      this.loadVideos();

      if (this.props.location.search === '?register=true') {
        this.setState({ successMessage: 'Success! Thanks for subscribing to Pro Football Doc. If you have any questions, use the support icon to send us a message.' });
        setTimeout(() => this.setState({ successMessage: '' }), 5000);
      }

      this.refreshIntervalId = setInterval(() => {
        this.checkUpdates();
        this.loadVideos();
      }, 5000);

      window.addEventListener('resize', this.handleIframesResize);
      window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Page: Live - /live');
      window.fbq('track', 'PageView', { title: 'Live' });
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshIntervalId);
    window.removeEventListener('resize', this.handleIframesResize);
  }

  handleIframesResize() {
    const column = document.querySelector('.vimeo_column');
    if (column) {
      const iframes = column.getElementsByTagName('iframe');
      for (let i in iframes) {
        resizeIframe(iframes[i]);
      }
    }
  }

  checkUpdates = () => {
    const { updatesPerPage } = this.state;

    axios.get(API_URL + '/post?type=docLiveUpdate&pageNumber=1&perPage=' + updatesPerPage + '&season=' + getSeason()).then(response => {
      const { updates } = this.state;
      const newUpdates = response.data.data ? response.data.data.postList : [];
      const filteredUpdates = [];

      for (let i in newUpdates) {
        const newUpdate = newUpdates[i];
        let updateExist = false;

        for (let j in updates) {
          const update = updates[j];

          if (update._id === newUpdate._id) {
            updateExist = true;
            break;
          }
        }

        if (!updateExist) {
          filteredUpdates.push(newUpdate);
        }
      }

      if (filteredUpdates.length > 0) {
        this.setState({
          updates: filteredUpdates.concat(updates)
        });
      }
    });
  }

  loadUpdates = (page, initialLoad) => {
    const { currentUpdatesPage, updatesPerPage } = this.state;

    axios.get(API_URL + '/post?type=docLiveUpdate&perPage=' + updatesPerPage + '&pageNumber=' + currentUpdatesPage + '&season=' + getSeason()).then(response => {
      const { updates } = this.state;
      const newUpdates = response.data.data && response.data.data.postList ? response.data.data.postList : [];

      this.setState({
        uploadsLoadedEntirely: newUpdates.length < updatesPerPage,
        updates: updates.concat(newUpdates),
        loading: false
      });
      
      if (initialLoad) {
        const holder = document.querySelector('.col-scroller-wrapper-updates');
        const wrapper = document.querySelector('.col-scroller-inner-updates');
        
        // for laptops
        holder.onscroll = () => {
          const { loading, currentUpdatesPage, uploadsLoadedEntirely } = this.state;

          if (!loading && !uploadsLoadedEntirely) {
            const scrollTop = holder.scrollTop;
            const outerHeight = holder.offsetHeight;
            const innerHeight = wrapper.offsetHeight;

            if (scrollTop + outerHeight > innerHeight - 150) {
              console.log('load laptop');
              this.setState({ loading: true, currentUpdatesPage: currentUpdatesPage + 1 });
              this.loadUpdates(currentUpdatesPage + 1, false);
            }
          }
        }

        // for mobiles
        window.onscroll = () => {
          const { loading, currentUpdatesPage, uploadsLoadedEntirely } = this.state;
          
          if (!loading && !uploadsLoadedEntirely) {
            const scrollTop = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
            const outerHeight = window.innerHeight;
            const innerHeight = Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );

            if (scrollTop + outerHeight > innerHeight - 150) {
              console.log('load mobile');
              this.setState({ loading: true, currentUpdatesPage: currentUpdatesPage + 1 });
              this.loadUpdates(currentUpdatesPage + 1, false);
            }
          }
        }
      }
    });
  }

  loadVideos = hideLoadingWheel => {
    axios.get(API_URL + '/video/youtube').then(response => {
      const updates = {
        videos: response.data.data ? response.data.data.recordedVideos : [],
        // streamings: response.data.data ? response.data.data.liveVideos : []
      };

      if (hideLoadingWheel) {
        updates.loading = false;
      }

      this.setState(updates);
      setTimeout(this.handleIframesResize);
    });
  }

  handleDeleteVideo = (id) => {
    if (window.confirm('Are you sure?')) {
      this.setState({ loading: true });
      axios.delete(API_URL + '/video/deleteVideo/' + id).then(r => this.loadVideos());
    }
  }

  onReload = () => {
    this.setState({ currentUpdatesPage: 1, updates: [], loading: true });
    this.loadUpdates(1, true);
  }

  onDelete = video => {
    if (window.confirm('Are you sure?')) {
      axios.delete(API_URL + '/video/deleteVideo/' + video._id).then(response => {
        this.setState({ loading: true });
        this.loadVideos(true);
      });
    }
  }

  render() {
    const { 
      videos, 
      updates,
      streamings,
      loading, 
      currentActiveTab,
      successMessage,
      currentVideoId,
      leagueType,
      notificationObject
    } = this.state;
    
    return (
      <Box>
        <div className="tab-switcher">
          <div className="tab-switcher-buttons">
            <IconButton onClick={ () => this.setState({ currentActiveTab: 'updates' }) } className={ currentActiveTab === 'updates' ? 'is-active' : '' }>
              <i className="fa fa-bars"></i>
            </IconButton>
            <IconButton onClick={ () => this.setState({ currentActiveTab: 'videos' }) } className={ currentActiveTab === 'videos' ? 'is-active' : '' }>
              <i className="fa fa-video-camera"></i>
            </IconButton>
            <IconButton onClick={ () => this.setState({ currentActiveTab: 'tweets' }) } className={ currentActiveTab === 'tweets' ? 'is-active' : '' }>
              <i className="fa fa-twitter"></i>
            </IconButton>
          </div>
        </div>
        
        <Grid container spacing={4} className="main cols-343">
          <Grid item xs={6} className={classNames({ updates_column: true, active: currentActiveTab === 'updates' })}>
            <UpdatesListing updates={updates} onLoad={this.onReload} />
          </Grid>
          
          <Grid item xs={3} className={classNames({ vimeo_column: true, active: currentActiveTab === 'videos' })}>
            <Box className="col-title nowrap is-sticky">
              <Typography component="h4" gutterBottom>
                <strong>Subscriber Videos</strong>
              </Typography>
            </Box>
            <div className="col-scroller col-scroller-videos">
              <div className="col-scroller-wrapper col-scroller-wrapper-videos">
                <div className="col-scroller-inner col-scroller-inner-videos">
                  {
                    streamings.map((video, i) => {
                      return (<Box mb={2} className="update-box" key={video.videoId}>
                        <figure dangerouslySetInnerHTML={{ __html: getEmbedCode(video.embedCode) }}></figure>
                        <Typography component="div" color="textSecondary" className="timestamp live-bar">
                          <span className="info-corner">Live Now</span>
                          { isAdmin() ? <span className="action-corner" onClick={ () => this.handleDeleteVideo(video._id) }>End Stream</span> : null }
                        </Typography>
                      </Box>)
                    })
                  }
                  {
                    videos.map((video, i) => {
                      return (<Box mb={2} className="update-box" key={video.videoId}>
                        {
                          video.title
                          ? <h4 className="figure-title">{ video.title }</h4>
                          : null
                        }

                        {
                          currentVideoId === video.videoId
                            ? <figure dangerouslySetInnerHTML={{ __html: leagueType === 'xfl' ? getEmbedCode(video.videoId) : getVimeoEmbed(video.embedCode) }}></figure>
                            : <figure className="video-mask" onClick={ () => { this.setState({ currentVideoId: video.videoId }); window.mixpanel.track('Play Video'); setTimeout(() => this.handleIframesResize()) }}>
                                <img src={ video.thumbnails.high.url } alt="Video thumbnail" />
                                <span className="play-button">
                                  <Icon>play_arrow</Icon>
                                </span>
                            </figure>
                        }
                        
                        <Typography color="textSecondary" className="timestamp">
                          { moment(video.videoDateTime).fromNow() }
                          <IconButton size="small" className="btn-notify" onClick={ () => this.setState({ notificationObject: video }) }>
                            <Icon>send</Icon>
                          </IconButton>
                          <IconButton size="small" className="btn-delete" onClick={ () => this.onDelete(video) }>
                            <Icon>delete</Icon>
                          </IconButton>
                        </Typography>
                      </Box>)
                    })
                  }
                </div>
              </div>
            </div>
          </Grid>
          
          <Grid item xs={3} className={classNames({ twitter_column: true, active: currentActiveTab === 'tweets' })}>
            <TwitterTimelineEmbed sourceType="profile" screenName="ProFootballDoc" options={{height: 600}} />
          </Grid>
        </Grid>

        { loading ? <LoadingWheel /> : null }
        { successMessage ? <SuccessSnackbar message={successMessage} /> : null }
        { notificationObject ? <NotifyControl type="video" object={ notificationObject } onClose={ () => this.setState({ notificationObject: null }) } /> : null }
      </Box>
    );
  }
}

function mapStateToProps(state, action) {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveScreen);
