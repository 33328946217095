import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getSeason, setSeason } from '../helpers/session';

class SeasonSwitcher extends Component {
	constructor() {
		super();

		this.state = { selectedSeason: getSeason() };
	}

	onChange = event => {
		setSeason(event.target.value)
		window.location.reload();
	}

	render() {
		const { selectedSeason } = this.state;

		return <Select className="season-jumper" value={selectedSeason} onChange={this.onChange}>
	        <MenuItem value={2019}>SEASON: 2019</MenuItem>
	        <MenuItem value={2020}>SEASON: 2020</MenuItem>
	        <MenuItem value={2021}>SEASON: 2021</MenuItem>
      	</Select>
	}
}

export default SeasonSwitcher;
