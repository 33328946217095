import React, { Component } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NativeSelect from '@material-ui/core/NativeSelect';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tooltip from '../components/Tooltip';
import LoadingWheel from '../components/LoadingWheel';
import ErrorSnackbar from '../components/ErrorSnackbar';
import UpdatesListing from '../components/UpdatesListing';
import { API_URL } from '../config.js';
import { getToken, isAdmin, getSeason } from '../helpers/session';

export default class ResearchScreen extends Component {
	constructor(props) {
    	super(props);

	    this.state = { 
	    	updates: [],
	    	sides: [],
	    	positions: [],
	    	search: '',
        searchState: 1,
	    	loading: false,
	    	editMode: false,
	    	showDialog: false,
	    	currentWeek: '',
	    	currentTeam: '',
	    	currentPlayer: '',
	    	sort: 'player-asc',
	    	newPlayerError: '',
	    	form: {
	    		name: '',
	    		team: '',
	    		side: '',
	    		position: '',
	    		health: '1',
	    		status: ''
	    	},
	    	errors: {
	    		name: false,
	    		team: false,
	    		side: false,
	    		position: false,
	    		health: false,
	    		status: false
	    	},
        showAddPlayerToTeamsPopup: false,
        showAddPlayerToTeamsPopupTeams: [],
        showAddPlayerToTeamsPopupTeamsSelected: [],
        showAddPlayerToTeamsPopupPlayer: null
    	};

      this.searchField = React.createRef();
  	}

  	componentDidMount() {
  		const authToken = getToken();
  		const { location, globalVars, weeks } = this.props;

  		if (!authToken) {
  			this.props.history.push('/');
  		} else {
    		if (globalVars.currentWeek) {
          	this.setState({ currentWeek: globalVars.currentWeek._id });
      		} else {
      			if (!this.state.currentWeek) {
            	this.loadPlayers(globalVars.currentWeek ? globalVars.currentWeek._id : weeks[0]._id);
            	this.setState({ currentWeek: globalVars.currentWeek ? globalVars.currentWeek._id : weeks[0]._id });
          	} else {
            	this.loadPlayers(this.state.currentWeek);
          	}
      		}

        	axios.get(API_URL + '/playerSide').then(response => this.setState({sides: response.data.data.PlayerSides}));
        	axios.get(API_URL + '/playerPosition').then(response => this.setState({positions: response.data.data.playerPositions}));
        	window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Page: Research - /research');
        	window.fbq('track', 'PageView', { title: 'Research' });
        	document.getElementsByTagName('body')[0].className = 'research';

        	if (location.search !== '') {
            const queryPieces = decodeURIComponent(location.search.substring(1)).split('&');
            const query = {
              search: queryPieces.find(piece => piece.indexOf('search=') !== -1).replace('search=', ''),
              searchId: queryPieces.find(piece => piece.indexOf('searchId=') !== -1).replace('searchId=', ''),
            };
        		this.setState({ 
              search: query.search, 
              currentPlayer: query.searchId
            });

            if (query.searchId) {
              this.loadUpdates(false, query.searchId);
            }
        	}

          this.intervalId = setInterval(() => {
            const { searchState } = this.state;

            if (this.searchField) {
              const { attributes } = this.searchField.current;
              const newState = parseInt(attributes['data-state'].value);

              if (newState > searchState) {
                const currentPlayer = attributes['data-value-id'].value;
                
                this.setState({ 
                  currentTeam: '',
                  searchState: newState,
                  search: attributes['data-value'].value,
                  currentPlayer: currentPlayer
                });

                if (currentPlayer) {
                  this.loadUpdates(false, currentPlayer);
                }
              }
            }
          }, 500);

          this.loadTeams();
		  }
  	}

  	componentWillUnmount() {
  		document.getElementsByTagName('body')[0].className = '';
      clearInterval(this.intervalId);
  	}

    loadTeams = () => {
      axios.get(API_URL + '/userTeams').then(response => {
        this.setState({ 
          showAddPlayerToTeamsPopupTeams: response.data.data.team
        });
      });
    }

  	loadUpdates = (teamId, playerId) => {
  		if (teamId) {
  			axios.get(API_URL + '/post?teamId=' + teamId + '&season=' + getSeason()).then(response => this.setState({ updates : response.data.data ? response.data.data.postList : [] }));
  		} else if (playerId) {
  			axios.get(API_URL + '/post?playerId=' + playerId + '&season=' + getSeason()).then(response => this.setState({ updates : response.data.data ? response.data.data.postList : [] }));
  		} else {
  			this.setState({ updates: [] });
  		}
  	}

  	onLoadUpdates = () => {
  		const { currentTeam, currentPlayer } = this.state;

  		if (currentPlayer)
  			this.loadUpdates(false, currentPlayer);
  		else
  			this.loadUpdates(currentTeam, false);
  	}

  	loadPlayers = (week) => {
  		this.setState({ loading: true });
      this.props.playersActions.fetchPlayers(week).then(() => this.setState({ loading: false }));
  	}

    onSelectPlayer = player => {
      const { currentPlayer } = this.state;
      
      if (currentPlayer === player.Player._id) {
        this.onDeselectPlayer();
      } else {
        window.mixpanel.track('Show Updates');
        this.setState({ 
          currentPlayer: player.Player._id,
          updates: []
        });
        this.loadUpdates(false, player.Player._id);
        window.scrollTo(0, 0);
      }
    }

    onDeselectPlayer = () => {
      const { currentTeam } = this.state;
      this.setState({ currentPlayer: '' });
      this.loadUpdates(currentTeam, false);
    }

  	updateFormModel = (e) => {
	    const { form, errors } = this.state;
	    const { target } = e;
	    const { name, value } = target;

	    form[name] = value;
	    errors[name] = false;

	    this.setState({
	        form: form,
	        errors: errors
	    });
  	}

  	handleClose = () => {
	    this.setState({ 
	    	showDialog: false,
	    	form: {
	    		name: '',
	    		team: '',
	    		side: '',
	    		position: '',
	    		health: '1',
	    		status: ''
	    	},
	    	errors: {
	    		name: false,
	    		team: false,
	    		side: false,
	    		position: false,
	    		health: false,
	    		status: false
	    	}
    	 });
  	}

  	handleCreatePlayer = () => {
  		const { form, errors, currentWeek } = this.state;
  		let hasErrors = false;

  		for (let i in errors) {
	      errors[i] = false;
	    }

  		if (form.name === '') {
  			errors.name = true;
  			hasErrors = true;
  		}

  		if (form.team === '') {
  			errors.team = true;
  			hasErrors = true;
  		}

  		if (form.side === '') {
  			errors.side = true;
  			hasErrors = true;
  		}

  		if (form.position === '') {
  			errors.position = true;
  			hasErrors = true;
  		}

  		if (form.health === '') {
  			errors.health = true;
  			hasErrors = true;
  		}

  		if (form.status === '') {
  			errors.status = true;
  			hasErrors = true;
  		}

  		if (hasErrors) {
  			this.setState({ errors });
  		} else {
  			this.setState({ loading: true });

  			axios.post(API_URL + '/playerWeeklyData/create', {
  				playerName: form.name,
  			  	teamId: form.team,
  			  	side: form.side,
  			  	position: form.position,
  			  	health: form.health,
  			  	status: form.status,
  			  	scheduleTimelineId: currentWeek
    			}).then(response => {
    				this.handleClose();
    				this.loadPlayers(currentWeek);
    			}).catch(error => {
  				this.setState({
  		  			newPlayerError: error.response.data.message,
  		  			loading: false
  		  		});
  		  		setTimeout(() => this.setState({ newPlayerError: '' }), 3000);
  			});
  		}
  	}

  	handleReadPlayer = (player) => {
	    this.setState({
  			showDialog: true,
  			editMode: player._id,
  			form: {
  				name: player.Player.name,
  				team: player.Team._id,
  				side: player.Side._id,
  				position: player.Position._id,
  				health: player.health,
  				status: player.status
  			}
	    });
  	}

    handleAddPlayerToTeams = (event, player) => {
      const { showAddPlayerToTeamsPopupTeams } = this.state;

      window.mixpanel.track('Add Player');
      this.setState({ 
        showAddPlayerToTeamsPopup: true,
        showAddPlayerToTeamsPopupTeamsSelected: showAddPlayerToTeamsPopupTeams.filter(team => { return team.players.filter(p => p._id === player.Player._id).length > 0; }).map(team => team._id),
        showAddPlayerToTeamsPopupPlayer: player.Player
      });
    }

    handleAddPlayerToTeamsPopupClose = () => {
      this.setState({ 
        showAddPlayerToTeamsPopup: false
      });
    }

    handleAddPlayerToTeamsPopupSave = async () => {
      const { showAddPlayerToTeamsPopupTeamsSelected, showAddPlayerToTeamsPopupPlayer, showAddPlayerToTeamsPopupTeams } = this.state;
      this.setState({ loading: true });
      for (let i in showAddPlayerToTeamsPopupTeams) {
        await axios.post(API_URL + '/userTeams/removePlayersFromTeams', {
          playerIds: [showAddPlayerToTeamsPopupPlayer._id], 
          userTeamId: showAddPlayerToTeamsPopupTeams[i]._id
        });
      }
      await axios.post(API_URL + '/userTeams/addPlayerToTeams', {
        userTeamIds: showAddPlayerToTeamsPopupTeamsSelected,
        playerId: showAddPlayerToTeamsPopupPlayer._id
      });
      this.loadTeams();
      this.setState({ 
        loading: false,
        showAddPlayerToTeamsPopup: false
      });
    }

  	handleUpdatePlayer = () => {
	    const { form, errors, currentWeek, editMode } = this.state;
	    let hasErrors = false;

	    for (let i in errors) {
	      errors[i] = false;
	    }

	    if (form.name === '') {
  			errors.name = true;
  			hasErrors = true;
  		}

  		if (form.team === '') {
  			errors.team = true;
  			hasErrors = true;
  		}

  		if (form.side === '') {
  			errors.side = true;
  			hasErrors = true;
  		}

  		if (form.position === '') {
  			errors.position = true;
  			hasErrors = true;
  		}

  		if (form.health === '') {
  			errors.health = true;
  			hasErrors = true;
  		}

  		if (form.status === '') {
  			errors.status = true;
  			hasErrors = true;
  		}

	    if (hasErrors) {
	      this.setState({ errors });
	    } else {
	      this.setState({ loading: true });
	    
	      axios.put(API_URL + '/playerWeeklyData/update/' + editMode, {
	        playerName: form.name,
  		  	teamId: form.team,
  		  	side: form.side,
  		  	position: form.position,
  		  	health: form.health,
  		  	status: form.status,
  		  	scheduleTimelineId: currentWeek
	      }).then(response => { 
	        this.handleClose();
			     this.loadPlayers(currentWeek);
	      }).catch(error => {
	        this.setState({
  	  			newPlayerError: error.response.data.message,
  	  			loading: false
  	  		});
  	  		setTimeout(() => this.setState({ newPlayerError: '' }), 3000);
	      });
	    }
  	}

  	onChangeWeek = (e) => {
	    const currentWeek = e.target.value; 

	    this.loadPlayers(currentWeek);
	    this.setState({ currentWeek: currentWeek, currentPlayer: '' });
  	}

  	render() {
  		const {  
  			sides, 
  			updates,
  			positions, 
  			showDialog, 
  			currentWeek,
  			currentTeam, 
  			currentPlayer,
  			form, 
  			errors, 
  			search, 
        searchState,
  			loading, 
  			newPlayerError,
  			editMode,
  			sort,
        showAddPlayerToTeamsPopup,
        showAddPlayerToTeamsPopupTeams,
        showAddPlayerToTeamsPopupTeamsSelected,
        showAddPlayerToTeamsPopupPlayer
  		} = this.state;
  		const { teams, weeks } = this.props;
  		const currentTeamObject = currentTeam ? teams.find(team => team._id === currentTeam) : null;
  		const isValidSearch = search.trim().length > 1;
  		const searchPieces = search.split(' ');
  		const allPlayers = this.props.players;
  		let players = [];

  		for (let i in allPlayers) {
  			const player = allPlayers[i];
  			let isMatched = true;

  			if (player.Player && player.Player.name) {
  				for (let j in searchPieces) {
  					if (typeof searchPieces[j] === 'string' && searchPieces[j].trim() !== '' && player.Player.name.toLowerCase().indexOf(searchPieces[j].toLowerCase()) === -1) {
  						isMatched = false;
  					}
  				}
  			} else {
  				isMatched = false;
  			}
  			
  			if (isValidSearch && !isMatched) {
      		// do noting
      	} else if (currentTeam !== '' && player.Team && player.Team._id !== currentTeam) {
  			// do noting
      	} else {
      		players.push(player);
      	}
  		}

      if (players.length > 100) {
        players = players.slice(0,100);
      }

  		// if (sort === 'player-asc') {
  		// 	players = players.sort((a,b) => a.Player.name.toLowerCase() < b.Player.name.toLowerCase() ? -1 : (a.Player.name.toLowerCase() > b.Player.name.toLowerCase() ? 1 : 0));
  		// } else if (sort === 'player-desc') {
  		// 	players = players.sort((a,b) => a.Player.name.toLowerCase() > b.Player.name.toLowerCase() ? -1 : (a.Player.name.toLowerCase() < b.Player.name.toLowerCase() ? 1 : 0));
  		// } else if (sort === 'position-asc') {
  		// 	players = players.sort((a,b) => a.Position.name.toLowerCase() < b.Position.name.toLowerCase() ? -1 : (a.Position.name.toLowerCase() > b.Position.name.toLowerCase() ? 1 : 0));
  		// } else if (sort === 'position-desc') {
  		// 	players = players.sort((a,b) => a.Position.name.toLowerCase() > b.Position.name.toLowerCase() ? -1 : (a.Position.name.toLowerCase() < b.Position.name.toLowerCase() ? 1 : 0));
  		// } else if (sort === 'health-asc') {
  		// 	players = players.sort((a,b) => a.health < b.health ? -1 : (a.health > b.health ? 1 : 0));
  		// } else if (sort === 'health-desc') {
  		// 	players = players.sort((a,b) => a.health > b.health ? -1 : (a.health < b.health ? 1 : 0));
  		// } else if (sort === 'status-asc') {
  		// 	players = players.sort((a,b) => a.status.toLowerCase() < b.status.toLowerCase() ? -1 : (a.status.toLowerCase() > b.status.toLowerCase() ? 1 : 0));
  		// } else if (sort === 'status-desc') {
  		// 	players = players.sort((a,b) => a.status.toLowerCase() > b.status.toLowerCase() ? -1 : (a.status.toLowerCase() < b.status.toLowerCase() ? 1 : 0));
  		// }

  		const currentPlayerObject = currentPlayer ? players.find(player => player.Player._id === currentPlayer) : null;

	  	return (
	  		<Box className="main main-players main-research">
	  			<Box className="game-box game-box-header players-header" mb={1.5}>
			        <Box className="game-box-row">
			          	{ 
			          		isAdmin()
			          		?  <Typography component="div" className="game-box-col game-box-col-name game-box-col-week">
				            	<NativeSelect value={currentWeek} onChange={ this.onChangeWeek }>
				                	{
				                  		weeks.map((week, i) => {
				                    		return (<option key={week._id} value={week._id}>{ week.name }</option>)
				                  		})
		                			}
				            	</NativeSelect>
				          	</Typography>
				          	: null
			          	}
			          	<Typography component="div" className="game-box-col game-box-col-name game-box-col-team">
			            	<NativeSelect value={currentTeam} onChange={ (e) => {
			            		this.setState({ 
			            			currentTeam: e.target.value,
			            			currentPlayer: ''
			            		});
			            		this.loadUpdates(e.target.value, false);
                      window.mixpanel.track('Select Team');
			            	} }>
			            		<option value="">Select a Team</option>
			                	{
			                  		teams.map((team, i) => {
			                    		return (<option key={team._id} value={team._id}>{ team.name }</option>)
			                  		})
			                	}
			            	</NativeSelect>
			          	</Typography>
			          	<Box component="div" className="game-box-col game-box-col-search to-left width-m">
							<InputBase id="main-search" ref={this.searchField} data-value="" data-state={searchState} placeholder="Search for a player..." value={search} onChange={event => { const value = event.target.value; window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Search', {query: value}); window.fbq('track', 'Search', { search_string: value }); this.setState({ search: value, currentPlayer: '' }); }} />
	      					<IconButton>
	        					<Icon>search</Icon>
	      					</IconButton>
	      				</Box>
	      				<Box component="div" className="game-box-col game-box-col-count">
							{
								currentTeam || isValidSearch
								? <strong>({ players.length })</strong>
								: null
							}
	      				</Box>
      					{
      						isAdmin()
		            	? (<Box component="div" className="game-box-col game-box-col-to-right">
				            	<div className="add-more-holder">
					            	<span className="add-more add-more-no-mt" onClick={ () => { this.setState({ showDialog: true, editMode: false, form: Object.assign(form, { team: currentTeam }) }) } }>
					                  <Icon>add</Icon>
					                </span>
				                </div>
				            </Box>)
	            		: null
      					}
			        </Box>
		      	</Box>

		      	{
		      		currentTeam || isValidSearch
			    	? <Grid container>
				    	<Grid item xs={ currentTeam || isValidSearch || currentPlayer ? 9 : 12 } className={ classNames(['main-table-holder', currentPlayer !== '' ? 'is-hidden' : '']) }>
					      <Box className="players-table no-margin-top">
						      <div className="tbl-holder tbl-holder-1">
							      <Table>
							        <TableHead>
							          <TableRow>
							            <TableCell component="th" className="sortable mob-first" onClick={ () => this.setState({ sort: sort === 'player-asc' ? 'player-desc' : 'player-asc'}) }>
							            	<div className="sortable-inner">
								            	Player
								            	{
								            		sort === 'player-asc' || sort === 'player-desc'
								            		? <Icon>{ sort === 'player-desc' ? 'expand_less' : 'expand_more' }</Icon>
								            		: null
							            		}
						            		</div>
						            	</TableCell>
							            <TableCell component="th">Team</TableCell>
							            <TableCell component="th">Side</TableCell>
							            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'position-asc' ? 'position-desc' : 'position-asc'}) }>
							            	<div className="sortable-inner">
								            	<div className="mobile-hidden">Position</div>
								            	{
								            		sort === 'position-asc' || sort === 'position-desc'
								            		? <Icon>{ sort === 'position-desc' ? 'expand_less' : 'expand_more' }</Icon>
								            		: null
							            		}
						            		</div>
						            	</TableCell>
							            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'health-asc' ? 'health-desc' : 'health-asc'}) }>
							            	<div className="sortable-inner">
								            	<div className="mobile-hidden">Health</div>
								            	{
								            		sort === 'health-asc' || sort === 'health-desc'
								            		? <Icon>{ sort === 'health-desc' ? 'expand_less' : 'expand_more' }</Icon>
								            		: null
							            		}
						            		</div>
						            	</TableCell>
							            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'status-asc' ? 'status-desc' : 'status-asc'}) }>
							            	<div className="sortable-inner">
							            		<div className="mobile-hidden">Status</div>
								            	{
								            		sort === 'status-asc' || sort === 'status-desc'
								            		? <Icon>{ sort === 'status-desc' ? 'expand_less' : 'expand_more' }</Icon>
								            		: null
							            		}
						            		</div>
						            	</TableCell>
							            <TableCell className="mob-last"></TableCell>
							          </TableRow>
							        </TableHead>
							        <TableBody>
						        	  {
						        	  	players.length < 1
						        	  	? (<TableRow>
						        	  		<TableCell className="mob-first" colSpan="7">No players found...</TableCell>
						        	  	</TableRow>)
						        	  	: null
						        	  }

							          {players.map((player, i) => {
                          const health = player.health.toString();
							          	return (
								            <TableRow key={i} className={ classNames({ is_active: player.Player && player.Player._id === currentPlayer }) } onClick={ () => this.onSelectPlayer(player) }> 
								            	<TableCell className="mob-first">
								                	{player.Player ? player.Player.name : ''}
								              	</TableCell>
								              	<TableCell>
								              		<div className="mobile-hidden">{player.Team && player.Team.name ? player.Team.name : ''}</div>
								              		<div className="desktop-hidden">{player.Team && player.Team.name ? player.Team.name.split(' ').pop() : ''}</div>
							              		</TableCell>
								              	<TableCell>
								              		<div className="mobile-hidden">{player.Side ? player.Side.name : '-'}</div>
								              		<div className="desktop-hidden">{player.Side ? player.Side.name[0] : '-'}</div>
							              		</TableCell>
								              	<TableCell>{player.Position ? player.Position.name : '-'}</TableCell>
								              	<TableCell>
								              		<div className={ classNames({health_box: true, 'orange-red': health === '6', 'green-orange': health === '5', white: health === '4', orange: health === '2', green: health === '1'}) }></div>
								              	</TableCell>
								              	<TableCell>{player.status}</TableCell>
								              	<TableCell className="mob-last" align="right">
								              		{
								              			isAdmin()
								              			? <IconButton className="no-padding mr-5" onClick={ () => this.handleReadPlayer(player) }>
								        					     <Icon>edit</Icon>
								      					     </IconButton>
								      					     : null
								              		}
                                  <IconButton className="rich-button no-padding mr-5" onClick={ event => this.handleAddPlayerToTeams(event, player) }>
                                      <Tooltip text="Remove this player from your team" />
                                      <Icon className="mode-default">done</Icon>
                                      <Icon className="mode-hover">close</Icon>
                                  </IconButton>
								              		<IconButton className="no-padding">
							        					    <Icon>dehaze</Icon>
							      					    </IconButton>
								              	</TableCell>
								            </TableRow>)
							          })}
							        </TableBody>
							      </Table>
						      </div>
						      <div className="main-table-holder-scroller">
							      <div className="tbl-holder tbl-holder-2">
								      <Table>
								        <TableHead>
								          <TableRow>
								            <TableCell component="th" className="sortable mob-first" onClick={ () => this.setState({ sort: sort === 'player-asc' ? 'player-desc' : 'player-asc'}) }>
								            	<div className="sortable-inner">
									            	Player
									            	{
									            		sort === 'player-asc' || sort === 'player-desc'
									            		? <Icon>{ sort === 'player-desc' ? 'expand_less' : 'expand_more' }</Icon>
									            		: null
								            		}
							            		</div>
							            	</TableCell>
								            <TableCell component="th">Team</TableCell>
								            <TableCell component="th">Side</TableCell>
								            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'position-asc' ? 'position-desc' : 'position-asc'}) }>
								            	<div className="sortable-inner">
									            	<div className="mobile-hidden">Position</div>
									            	{
									            		sort === 'position-asc' || sort === 'position-desc'
									            		? <Icon>{ sort === 'position-desc' ? 'expand_less' : 'expand_more' }</Icon>
									            		: null
								            		}
							            		</div>
							            	</TableCell>
								            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'health-asc' ? 'health-desc' : 'health-asc'}) }>
								            	<div className="sortable-inner">
									            	<div className="mobile-hidden">Health</div>
									            	{
									            		sort === 'health-asc' || sort === 'health-desc'
									            		? <Icon>{ sort === 'health-desc' ? 'expand_less' : 'expand_more' }</Icon>
									            		: null
								            		}
							            		</div>
							            	</TableCell>
								            <TableCell component="th" className="sortable" onClick={ () => this.setState({ sort: sort === 'status-asc' ? 'status-desc' : 'status-asc'}) }>
								            	<div className="sortable-inner">
								            		<div className="mobile-hidden">Status</div>
									            	{
									            		sort === 'status-asc' || sort === 'status-desc'
									            		? <Icon>{ sort === 'status-desc' ? 'expand_less' : 'expand_more' }</Icon>
									            		: null
								            		}
							            		</div>
							            	</TableCell>
								            <TableCell className="mob-last"></TableCell>
								          </TableRow>
								        </TableHead>
								        <TableBody>
							        	  {
							        	  	players.length < 1
							        	  	? (<TableRow>
							        	  		<TableCell className="mob-first" colSpan="7">No players found...</TableCell>
							        	  	</TableRow>)
							        	  	: null
							        	  }

								          {players.map((player, i) => {
                            const health = player.health.toString();
								          	const inTeam = showAddPlayerToTeamsPopupTeams.filter(team => team.players.filter(p => player.Player && p._id === player.Player._id).length > 0).length > 0;

                            return (
									            <TableRow key={i} className={ classNames({ is_active: player.Player && player.Player._id === currentPlayer }) } onClick={ () => this.onSelectPlayer(player) }>
									            	<TableCell className="mob-first">
									                	{player.Player ? player.Player.name : ''}
									              	</TableCell>
									              	<TableCell>
									              		<div className="mobile-hidden">{player.Team && player.Team.name ? player.Team.name : ''}</div>
									              		<div className="desktop-hidden">{player.Team && player.Team.name ? player.Team.name.split(' ').pop() : ''}</div>
								              		</TableCell>
									              	<TableCell>
									              		<div className="mobile-hidden">{player.Side ? player.Side.name : '-'}</div>
									              		<div className="desktop-hidden">{player.Side ? player.Side.name[0] : '-'}</div>
								              		</TableCell>
									              	<TableCell>{player.Position ? player.Position.name : '-'}</TableCell>
									              	<TableCell>
                                    <div className={ classNames({health_box: true, 'orange-red': health === '6', 'green-orange': health === '5', white: health === '4', orange: health === '2', green: health === '1'}) }></div>
									              	</TableCell>
									              	<TableCell>{player.status}</TableCell>
									              	<TableCell className="mob-last" align="right">
									              		{
									              			isAdmin()
									              			? <IconButton className="no-padding mr-5" onClick={ () => this.handleReadPlayer(player) }>
    									        					<Icon>edit</Icon>
    									      					</IconButton>
    									      					: null
									              		}
                                    <IconButton className={classNames([inTeam ? '' : 'gray', 'rich-button', 'no-padding', 'mr-5'])} onClick={ event => this.handleAddPlayerToTeams(event, player) }>
                                      <Tooltip text="Save or remove this player from your teams" />
                                      <Icon className="mode-default">{ inTeam ? 'done' : 'add' }</Icon>
                                      <Icon className="mode-hover">close</Icon>
                                    </IconButton>
									              		<IconButton className="no-padding" onClick={ () => {
									              			if (currentPlayer === player.Player._id) {
									              				this.onDeselectPlayer();
									              			} else {
									              				this.setState({ 
									              					currentPlayer: player.Player._id,
									              					updates: []
							              					 	});
									              				this.loadUpdates(false, player.Player._id);
									              			}
									              		}}>
    								        					<Icon>dehaze</Icon>
    								      					</IconButton>
									              	</TableCell>
									            </TableRow>)
								          })}
								        </TableBody>
								      </Table>
							      </div>
						      </div>
					      </Box>
				      </Grid>

				      {
				      	currentTeam || isValidSearch || currentPlayer
				      	? (<Grid item xs={3} className={ classNames({ side_posts_holder: true, is_active: currentPlayer }) }>
					      	<div className="side-posts">
					      		<div className="side-posts-top">
						      		<Typography component="div">
		                				{
		                					currentPlayer
		                					? <strong>{ currentPlayerObject ? currentPlayerObject.Player.name: '' } Updates</strong> 
		                					: <strong>{ currentTeamObject ? currentTeamObject.name : 'No' } Updates</strong>
		                				}
		              				</Typography>
		              				{
		              					currentPlayer
		              					? <IconButton onClick={ this.onDeselectPlayer }>
				        					<Icon>close</Icon>
				      					</IconButton>
				      					: null
		              				}
	              				</div>

	              				{
	              					currentPlayer && !loading && updates.length === 0
	              					? <Box mt={2}>
	              						<Typography variant="body2" component="p">No updates for this player</Typography>
              						</Box>
	              					: null
	              				}

	              				<UpdatesListing updates={updates} onLoad={this.onLoadUpdates} hideTitleBar={true} />
				            </div>
					      </Grid>)
				      	: null
				      }
				  	</Grid>
				  	: null
			  	}

          <Dialog open={showAddPlayerToTeamsPopup} onClose={this.handleAddPlayerToTeamsPopupClose}>
            <DialogTitle className="dialog-title">
              Add <span style={{ textDecoration: 'underline' }}>{ showAddPlayerToTeamsPopupPlayer ? showAddPlayerToTeamsPopupPlayer.name : '' }</span> to your teams
            </DialogTitle>
            <DialogContent className="size-m">
              <div className="checkbox-list">
              {
                showAddPlayerToTeamsPopupTeams.map(week => {
                  const currentPosition = showAddPlayerToTeamsPopupTeamsSelected.indexOf(week._id);
                  const checked = currentPosition !== -1;
                  
                  return <label key={week._id}>
                          <Checkbox 
                            checked={checked} 
                            color="primary" 
                            onChange={() => {
                              if (checked)
                                showAddPlayerToTeamsPopupTeamsSelected.splice(currentPosition, 1);
                              else 
                                showAddPlayerToTeamsPopupTeamsSelected.push(week._id);
                              
                              this.setState({ showAddPlayerToTeamsPopupTeamsSelected });
                            }} 
                            value={ week._id }
                          />
                          <Typography variant="body2" component="span">{ week.name }</Typography>
                        </label>
                })
              }
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleAddPlayerToTeamsPopupClose} color="primary">Cancel</Button>
              <Button onClick={this.handleAddPlayerToTeamsPopupSave} color="primary">Save</Button>
            </DialogActions>
          </Dialog>

			  	<Dialog open={showDialog} onClose={this.handleClose}>
		          <DialogTitle className="dialog-title">
		          	{ editMode ? 'Edit player' : 'Add a new player' }
		          </DialogTitle>
		          <DialogContent className="size-m">
		            <Box mb={2.5}>
		              <TextField 
		              	label="Player Name" 
		              	type="text" 
		              	name="name" 
		              	value={form.name} 
		              	error={errors.name} 
		              	onChange={this.updateFormModel} 
		              	fullWidth 
	              	  />
		            </Box>
		            <Box mb={2.5}>
		              <NativeSelect value={form.team} error={errors.team} name="team" fullWidth onChange={this.updateFormModel} >
		              		<option value="">Team</option>
		                	{
		                  		teams.map((team, i) => {
		                    		return (<option key={team._id} value={team._id}>{team.name}</option>)
		                  		})
                			}
		            	</NativeSelect>
		            </Box>
		            <Box mb={2.5}>
		              <NativeSelect value={form.side} error={errors.side} name="side" fullWidth onChange={this.updateFormModel} >
		                	<option value="">Side</option>
		                	{
		                  		sides.map((side, i) => {
		                    		return (<option key={side._id} value={side._id}>{side.name}</option>)
		                  		})
                			}
		            	</NativeSelect>
		            </Box>
		            <Box mb={3}>
		              <NativeSelect value={form.position} error={errors.position} name="position" fullWidth onChange={this.updateFormModel} >
		              		<option value="">Position</option>
		                	{
		                  		positions.map((position, i) => {
		                    		return (<option key={position._id} value={position._id}>{position.name}</option>)
		                  		})
                			}
		            	</NativeSelect>
		            </Box>
		            <Box mb={0.5}>
          				<InputLabel>Health</InputLabel>
          				<Select
            					fullWidth
            					value={form.health}
            					onChange={this.updateFormModel}
            					error={errors.health} 
            					name="health"
          				>
  				          <MenuItem value="1">
  				          	<div className="health_box green"></div>
  				          </MenuItem>
  				          <MenuItem value="5">
                      <div className="health_box green-orange"></div>
                    </MenuItem>
                    <MenuItem value="2">
  				          	<div className="health_box orange"></div>
  				          </MenuItem>
                    <MenuItem value="6">
                      <div className="health_box orange-red"></div>
                    </MenuItem>
  				          <MenuItem value="3">
  				          	<div className="health_box red"></div>
  				          </MenuItem>
  				          <MenuItem value="4">
  				          	<div className="health_box no_border white"></div>
  				          </MenuItem>
      				  	</Select>
		            </Box>
		            <Box mb={2.5}>
                  <InputLabel>Status</InputLabel>
                  <Select
                      fullWidth
                      value={form.status}
                      onChange={this.updateFormModel}
                      error={errors.status} 
                      name="status"
                  >
                    <MenuItem value="ACT">ACT</MenuItem>
                    <MenuItem value="QUES">QUES</MenuItem>
                    <MenuItem value="OUT">OUT</MenuItem>
                    <MenuItem value="IR">IR</MenuItem>
                    <MenuItem value="PUP">PUP</MenuItem>
                    <MenuItem value="NFI">NFI</MenuItem>
                  </Select>
		            </Box>
		          </DialogContent>
		          <DialogActions>
		            <Button onClick={this.handleClose} color="primary">Cancel</Button>
		            {
                    	editMode 
	                    ? <Button onClick={this.handleUpdatePlayer} color="primary">Update</Button>
	                    : <Button onClick={this.handleCreatePlayer} color="primary">Create</Button>
                  	}
		          </DialogActions>
	          </Dialog>
	          { loading ? <LoadingWheel /> : null }
	          { newPlayerError ? <ErrorSnackbar message={newPlayerError} /> : null }
		  	</Box>
	  	);
  	}
}
