import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import logo from '../images/logo.png';
import { getToken } from '../helpers/session';

class LandingScreen extends Component {
	componentDidMount() {
		window.mixpanel.track(window.getSelectedLeaguePrefix() + 'Page: Landing - /');
		window.fbq('track', 'PageView', { title: 'Landing' });
	    
	    if (getToken()) {
	      	this.props.history.push('/live');
	    }
	}

	render() {
		const isLoggedIn = getToken() !== null;

	  	return (
	    	<Container fixed className="main">
	    		<Box className="landing-panel">
	    			<figure>
	    				<img src={ logo } alt="PFD logo" />
    				</figure>
    				{
    					isLoggedIn
    					? null
    					: (<Box>
			    			<Link to="/login" className="no-underline">
				                <Button variant="contained" color="primary">Login</Button>
			              	</Link>
			              	<Link to="/register" className="no-underline">
				                <Button variant="contained" color="primary">Sign up</Button>
			              	</Link>
		              	</Box>)
    				}
	    		</Box>
	    	</Container>
	  	);
  	}
}

export default LandingScreen;
