import React, { Component } from 'react';
import axios from 'axios';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import Intercom from 'react-intercom';
import { Route, Switch, HashRouter, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import LandingScreen from './screens/LandingScreen';
import LoginScreen from './screens/LoginScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import RegisterScreen from './screens/RegisterScreen';
import RegisterSubscriptionScreen from './screens/RegisterSubscriptionScreen';
import RegisterPaymentScreen from './screens/RegisterPaymentScreen';
import LiveScreen from './screens/LiveScreen';
import TeamsScreen from './screens/TeamsScreen';
import EarlyLineAlertsScreen from './screens/EarlyLineAlertsScreen';
import FantasyValuesScreen from './screens/FantasyValuesScreen';
import UsersScreen from './screens/UsersScreen';
import AccountScreen from './screens/AccountScreen';
import Error404Screen from './screens/Error404Screen';

import GameDayWrapper from './wrappers/GameDayWrapper';
import ResearchWrapper from './wrappers/ResearchWrapper';
import QuickHitsWrapper from './wrappers/QuickHitsWrapper';

import Header from './components/Header';
import GlobalLoader from './components/GlobalLoader';

import { setToken, getToken, forgetToken, setRefreshToken, getRefreshToken, forgetRefreshToken, getTempToken, setSettings, setAdmin } from './helpers/session';
import rootReducer from './reducers/rootReducer';
import { APP_VERSION, API_URL } from './config.js';

import './styles/app.sass';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1876D2'
    }
  },
  typography: {
    fontFamily: [
      'Open Sans'
    ]
  }
});

const middlewares = [
  thunk,
];

const store = createStore(
  rootReducer,
  applyMiddleware(...middlewares)
)

axios.interceptors.request.use(config => {
  // const selectedLeague = localStorage.getItem('pfd_selected_league_2') ? localStorage.getItem('pfd_selected_league_2') : 'nfl';
  const selectedLeague = 'nfl';

  config.headers['x-access-token'] = getToken() ? getToken() : getTempToken();
  config.headers['apikey'] = '3f51e17cd56feaa03b7a9505381219f3d64d4634';
  config.headers['league_type'] = selectedLeague;

  return config;
});

export default class App extends Component {
  constructor() {
    super();

    this.state = { 
      profile: null,
      currentState:  1
    };
  }

  componentDidMount() {
    if (window.location.href.indexOf('oauth_token') !== -1) {
      let { search } = window.location;
      search = search.substring(1).split('&');
      const searchParams = {};
      for (let i in search) {
        const pieces = search[i].split('=');
        searchParams[pieces[0]] = pieces[1] ? pieces[1] : '';
      }

      if (searchParams.oauth_token && searchParams.oauth_verifier) {
        setTimeout(() => {
          axios.post(API_URL + '/auth/authenticate/twitter?oauth_token=' + searchParams.oauth_token + '&oauth_verifier=' + searchParams.oauth_verifier).then(response => {
            if (response.status !== 200) {
              alert(response.statusText);
            } else {
              const { data } = response;
              
              if (data.status && data.status === 'success' && data.token) {
                window.mixpanel.track('Login - Twitter');
                setToken(data.token);
                setRefreshToken(data.refreshToken);
                setAdmin(data.data.user.roleName === 'Admin');
                window.location.href = '/#/live';
              } else if (data.message) {
                // alert(data.message);
              } else {
                // alert('Twitter Auth Failed');
              }
            }
          });
        }, 1000);
      }
    } else {
      this.onRefreshSession();

      axios.get(API_URL + '/user/me').then(response => {}).catch(error => {
        const refreshToken = getRefreshToken();
        if (error.response.status === '401' && refreshToken) {
          axios.post(API_URL + '/user/refreshToken', {}, { headers: { 'x-refresh-token': refreshToken } }).then(response => {
            if (response.data && response.data.token && response.data.refreshToken) {
              setToken(response.data.token);
              setRefreshToken(response.data.refreshToken);
              window.location.reload();
            }
          });
        }
      });

      axios.interceptors.response.use(response => response, error => {
        const { hash } = window.location;
        const { status } = error.response;

        if (status === 401 && hash.indexOf('#/register') === -1 && hash.indexOf('#/login') === -1 && hash.indexOf('#/forgot') === -1 && hash.indexOf('#/404') === -1) {
          let refreshToken = getRefreshToken();
          refreshToken = null;

          if (refreshToken && refreshToken !== 'null') {
            axios.post(API_URL + '/user/refreshToken', {}, { headers: { 'x-refresh-token': refreshToken } }).then(response => {
              if (response.data && response.data.token && response.data.refreshToken) {
                setToken(response.data.token);
                setRefreshToken(response.data.refreshToken);
                window.location.hash = '#/live';
              }
            });
          } else {
            forgetToken();
            forgetRefreshToken();
            window.location.hash = '#/login';
          }
        }

        return Promise.reject(error);
      });
    }

    this.onRefreshSession();
  }

  onRefreshSession = () => {
    const { currentState } = this.state;

    axios.get(API_URL + '/user/me').then(({ data }) => {
      this.setState({ 
        profile: data.data,
        currentState: currentState * 2
      });
      window.mixpanel.identify(data.data.email);
    });
  }

  onTopNoticeClose() {
    document.querySelector('body').className = document.querySelector('body').className.replace('show-top-notice');
    setSettings('topNoticeBarClosed', true);
  }

  render() {
    const { profile, currentState } = this.state;

    const user = {
      logged_in: profile ? true : false,
      user_id: profile ? profile._id : new Date().getTime(),
      email: profile ? profile.email : '',
      name: profile ? profile.name : ''
    };

    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Paper className="page">
            <HashRouter> 
              <Header onRefreshSession={this.onRefreshSession} />
              <GlobalLoader currentState={currentState} />
              <Box className="top-notice">
                <Container fixed> 
                  <Typography variant="body2" component="div" className="top-notice-body">
                    <strong>Understanding the Injury Index</strong>
                    This is our proprietary grading system that incorporates the health of each team against the norm in a given week. It does not grade the quality or effectiveness of a roster and it does not take in account scheme/coaching/matchup. It is simply an assessment of team health. The index is broken down into run/pass offense/defense to provide better analysis for fantasy/DFS/gambling plays.
                    <span className="top-notice-close" onClick={ this.onTopNoticeClose }>
                      <Icon>close</Icon>
                    </span>
                  </Typography>
                </Container>
              </Box>
              <Container fixed className="main-frame"> 
                <Switch>
                  <Route path='/' exact component={LandingScreen} />
                  <Route path="/login" exact render={props => <LoginScreen {...props} onRefreshSession={this.onRefreshSession} />} />
                  <Route path='/forgot' exact component={ForgotPasswordScreen} />
                  <Route path="/register" exact render={props => <RegisterScreen {...props} onRefreshSession={this.onRefreshSession} />} />
                  <Route path="/register-subscription" exact component={RegisterSubscriptionScreen} />
                  <Route path="/register-payment" exact component={RegisterPaymentScreen} />
                  
                  <Route path='/live' exact component={LiveScreen} />
                  <Route path='/game' exact component={GameDayWrapper} />
                  <Route path="/research" exact component={ResearchWrapper} />
                  <Route path="/account" exact component={AccountScreen} />
                  <Route path="/teams" exact component={TeamsScreen} />
                  <Route path="/quick-hits" exact component={QuickHitsWrapper} />
                  <Route path="/early-line-alerts" exact component={EarlyLineAlertsScreen} />
                  <Route path="/fantasy-values" exact component={FantasyValuesScreen} />
                  <Route path="/users" exact component={UsersScreen} />
                  
                  <Route path='/404' exact component={Error404Screen} />

                  <Redirect to='/404' />
                </Switch>
              </Container>
              <Intercom appID="mwbcvf4w" { ...user } />
            </HashRouter> 
            <div className="version-number">{ APP_VERSION }</div>
          </Paper>
        </MuiThemeProvider>
      </Provider>
    );
  }
}
